import React from 'react';
import styles from './Button.module.css';

const STYLES = ['btn--default', 'btn--large'];

const ICONS = [
  {
    type: 'search',
    html: { __html: '<i class="fas fa-search"></i>' }
  }
];

const Button = ({ text, type, onClick, buttonStyle, icon, widthValue, rightMargin, disabled}) => {
  const styleClass = STYLES.includes(buttonStyle)
    ? styles[buttonStyle]
    : styles[STYLES[0]];

  const addIcon = icon => {
    for (let i = 0; i < ICONS.length; i++) {
      if (ICONS[i].type === icon) {
        return ICONS[i].html;
      }
    }
    return;
  };

  if(widthValue){
    return (
      <button style={{width:`${widthValue}`, marginRight: `${rightMargin}`}} onClick={onClick} type={type} className={`btn ${styleClass}`}>
        <span dangerouslySetInnerHTML={addIcon(icon)}></span>
        &nbsp;
        {text}
      </button>
    );
  }

  if(disabled){
    return (
      <button disabled={disabled} style={{width:`${widthValue}`, marginRight: `${rightMargin}`}} onClick={onClick} type={type} className={`btn ${styleClass}`}>
      <span dangerouslySetInnerHTML={addIcon(icon)}></span>
      &nbsp;
      {text}
    </button>
    )
  }

  return (
    <button style={{marginRight: `${rightMargin}`}} onClick={onClick} type={type} className={`btn ${styleClass}`}>
      <span dangerouslySetInnerHTML={addIcon(icon)}></span>
      &nbsp;
      {text}
    </button>
  );
};

export default Button;

import React, { Fragment } from 'react';
import {Link} from 'react-router-dom';
import AuthService from "../../services/authService"
import Button from '../common/Button';
import styles from './Auth.module.css';
import { Redirect } from 'react-router-dom';

class CamperLogin extends React.Component{
    onChangeInput(e) {
        this.setState({
          [e.target.name]: e.target.value
        });
    }

    handleLogin(e){
        e.preventDefault();
        this.setState({hasError:false})
        const res = AuthService.loginCamper(this.state.email, this.state.password)
        .then(response=>{
            console.log(response);
            const serverResponse = response.data;
            localStorage.setItem('jwt', serverResponse.data.token);
            localStorage.setItem('userType', 'camper');
            this.props.history.push("/camper/dashboard");
            window.location.reload();
        },error=>{
            const errResponse = error.response.data;
            console.log(errResponse);
            this.setState({errorMessage: errResponse.message});
            this.setState({hasError: true});
        });
    }
    constructor(props){
        super(props);

        this.handleLogin = this.handleLogin.bind(this);
        this.onChangeInput = this.onChangeInput.bind(this);

        this.state = {
            email: "",
            password: "",
            hasError:false,
            errorMessage:""
        }
    }
    render(){
        return(
            <Fragment>
                <header className={styles.hero}></header>

                <div className={styles['form-text']}>
                    <h1>LOG IN</h1>
                </div>
                <div>
                    {this.state.hasError ?
                    <p className="alert alert-danger">We're sorry, something went wrong. Please ensure that all fields
                    are filled out correctly. If you're still encountering issues,
                    please email us at support@summertymes.com.</p>
                    : ""}
                </div>
                <div className={styles['form-container']}>
                    <form onSubmit={e => this.handleLogin(e)}>
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    className="input"
                                    required
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.onChangeInput}
                                />
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label htmlFor="password">Password</label>
                                <input
                                    type="password"
                                    id="password"
                                    className="input"
                                    required
                                    name="password"
                                    value={this.state.password}
                                    onChange={this.onChangeInput}
                                />
                            </div>

                            <div className="container">
                                <div
                                    className="mx-auto text-center p-3"
                                    style={{ width: '400px' }}
                                >
                                    <Button
                                    text="Login"
                                    type="submit"
                                    onClick={() => {}}
                                    buttonStyle="btn--default"
                                    icon="none"
                                    ></Button>
                                    <div className={styles['forgot-password']}>
                                    <p>Don't have an account yet? <Link to="/camper/register">Register Here</Link></p>
                                    </div>
                                    <div className={styles['forgot-password']}>
                                    <p>Forgot your password? <Link to="/camper/forgotpassword">Click here to reset it</Link></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Fragment>
        )
    }
}

export default CamperLogin;
import React, {Fragment, Component} from 'react'; 
import Button from '../common/Button';
import Cleave from 'cleave.js/react';
import AuthService from '../../services/authService';
import DataService from '../../services/dataService';
import HostService from '../../services/hostService';

class HostAccount extends Component{
    constructor(props){
        super(props);

        this.onChangeInput = this.onChangeInput.bind(this);
        this.onUpdateDetails = this.onUpdateDetails.bind(this);
        this.onUpdatePassword = this.onUpdatePassword.bind(this);

        this.state = {
            firstName:"",
            lastName:"",
            phone:"",
            email:"",
            oldPassword:"",
            password:"",
            confirmPassword:"",
            hasError:false,
            errorMessage:"",
            updateSaved:false,
            successMessage:""
        }
    }

    componentDidMount(){
        DataService.getCurrentUser().then((result)=>{
            console.log(result);
            const user = result.data.userData;
            this.setState({
                firstName: user.firstName, 
                lastName: user.lastName, 
                email: user.email,
                phone: user.phone ? user.phone : ""
            })
        })
    }

    onChangeInput(e){
        this.setState({[e.target.name]: e.target.value});
    }

    onUpdateDetails(){
        this.setState({
            hasError:false,
            errorMessage:""
        });

        if(!this.verifyDetails()){
            this.setState({
                hasError:true,
                errorMessage: "Please fill in all required(*) fields"
            });
        }else{
            const user = {
                firstName: this.state.firstName, 
                lastName: this.state.lastName,
                phone: this.state.phone !==""? this.state.phone : null, 
                email: this.state.email
            }
            HostService.updateHost(user).then((result)=>{
                //update token
                AuthService.regenHost(result.data.email)
                .then(result=>{
                    const serverResponse = result.data;
                    localStorage.setItem('jwt', serverResponse.data.token);
                    localStorage.setItem('userType', 'host');
                    this.setState({
                        updateSaved:true,
                        successMessage: "Account details updated!"
                    });
                })
            });
        }
    }

    onUpdatePassword(){
        this.setState({
            hasError:false,
            errorMessage:"",
            updateSaved:false,
            successMessage:""
        });

        if(this.state.oldPassword!="" && this.state.oldPassword!=null && this.state.password!="" && this.state.password!=null && this.state.confirmPassword!="" && this.state.confirmPassword!=null){
            //check if old password is right
            AuthService.checkPassword(this.state.oldPassword).then((result)=>{
                console.log(result);
                if(!result.data.isValid){
                    this.setState({
                        hasError:true,
                        errorMessage: "Incorrect password provided for current password. Check password and try again."
                    });
                }else{
                    if(this.state.password===this.state.confirmPassword){
                        AuthService.updatePassword(this.state.password).then((result)=>{
                            console.log(result);
                            this.setState({
                                updateSaved:true,
                                successMessage: "Password updated!"
                            });
                        });
                    }else{
                        this.setState({
                            hasError:true,
                            errorMessage: "New password and confirmed password must match."
                        });
                    }
                }
            })
        }else{
            this.setState({
                hasError:true,
                errorMessage: "All password fields required when updating password."
            });
        }
    }

    verifyDetails(){
        let ignore = [];
        ignore = ["phone","oldPassword","password","confirmPassword","hasError","errorMessage","updateSaved","successMessage"];

        for (const property in this.state) {
            if(!ignore.includes(property)){
                if(this.state[property]==""||this.state[property]==null){
                    console.log("offender");
                    console.log(property);
                    return false;
                }
            }
        }
        return true;
    }

    verifyPasswordFields

    render(){
        return (
            <Fragment>
                <div>
                    {this.state.hasError ?
                    <p className="alert alert-danger" style={{marginTop:30}}>{this.state.errorMessage}</p>
                    : ""}
                </div>
                <div>
                    {this.state.updateSaved ?
                    <p className="alert alert-success" style={{marginTop:30}}>{this.state.successMessage}</p>
                    : ""}
                </div>
                <div className="container" style={{marginTop:30}}>
                    <h4 style={{textDecoration:"underline",marginBottom:"10px"}}>Account Details</h4>
                    <hr/>
                    <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <label htmlFor="firstName">First Name*</label>
                                <input
                                    type="text"
                                    id="firstName"
                                    className="input"
                                    required
                                    name="firstName"
                                    value={this.state.firstName}
                                    onChange={this.onChangeInput}
                                />
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label htmlFor="lastName">Last Name*</label>
                                <input
                                    type="text"
                                    id="lastName"
                                    className="input"
                                    required
                                    name="lastName"
                                    value={this.state.lastName}
                                    onChange={this.onChangeInput}
                                />
                            </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <label htmlFor="phone">Phone</label>
                            <Cleave
                            options={{ phone: true, phoneRegionCode: 'US', delimiter: '-' }}
                            value={this.state.phone}
                            onChange={this.onChangeInput}
                            className="input"
                            required
                            id="phone"
                            name="phone"
                            />
                        </div>
                        <div className="col-sm-12 col-md-6">
                                <label htmlFor="email">Email*</label>
                                <input
                                    type="text"
                                    id="email"
                                    className="input"
                                    required
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.onChangeInput}
                                />
                        </div>
                    </div>
                    <div className="row" style={{marginBottom:15}}>
                        <div className="mx-auto text-center p-3">
                            <Button
                                text="Update Details"
                                type="submit"
                                onClick={this.onUpdateDetails}
                                buttonStyle="btn--default"
                                icon="none"
                            ></Button>
                        </div>
                    </div>
                    {/*<h4 style={{textDecoration:"underline",marginBottom:"10px"}}>Connect Stripe Account</h4>
                    <hr/>
                    <div className="row">
                        <div className="col-sm-12">

                        </div>
                    </div>*/}
                    <h4 style={{textDecoration:"underline",marginBottom:"10px"}}>Change Password</h4>
                    <hr/>
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <label htmlFor="oldpassword">Current Password</label>
                            <input
                                type="password"
                                id="oldpassword"
                                className="input"
                                required
                                name="oldPassword"
                                value={this.state.oldPassword}
                                onChange={this.onChangeInput}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <label htmlFor="password">New Password</label>
                            <input
                                type="password"
                                id="password"
                                className="input"
                                required
                                name="password"
                                value={this.state.password}
                                onChange={this.onChangeInput}
                            />
                        </div>
                        <div className="col-sm-12 col-md-6">
                                <label htmlFor="confirmpassword">Confirm New Password</label>
                                <input
                                    type="password"
                                    id="confirmpassword"
                                    className="input"
                                    required
                                    name="confirmPassword"
                                    value={this.state.confirmPassword}
                                    onChange={this.onChangeInput}
                                />
                        </div>
                    </div>
                    <div className="row" style={{marginBottom:15}}>
                        <div className="mx-auto text-center p-3">
                            <Button
                                text="Update Password"
                                type="submit"
                                onClick={this.onUpdatePassword}
                                buttonStyle="btn--default"
                                icon="none"
                            ></Button>
                        </div>
                    </div>
                </div>
                <div>
                    {this.state.hasError ?
                    <p className="alert alert-danger">{this.state.errorMessage}</p>
                    : ""}
                </div>
                <div>
                    {this.state.updateSaved ?
                    <p className="alert alert-success" style={{marginTop:30}}>{this.state.successMessage}</p>
                    : ""}
                </div>
            </Fragment>
        );
    }
}

export default HostAccount;
import React from 'react';
import styles from './CampHost.module.css';

const PaymentCard = ({ image, alt, text }) => {
  return (
    <div className={styles['my-camp-card-container']} style={{textAlign:"center",paddingTop:10,background:"#FFF",borderRadius:10,height:100}}>
      <i className="fa-3x fas fa-credit-card"></i>
      <p className={styles['my-camp-card-text']}>{text}</p>
    </div>
  );
};

export default PaymentCard;
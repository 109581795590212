import React, {Fragment} from 'react';
import styles from './CampHost.module.css';
import DataService from '../../services/dataService';
import AdminService from '../../services/adminService';
import ContentLoader from "react-content-loader";
import {logout} from '../../utils/tokenUtils';
import { Modal, Row, Col } from 'react-bootstrap';
import Button from '../common/Button';
import Cleave from 'cleave.js/react';
import swimming from '../common/images/swimming.jpg';
import Carousel from 'react-bootstrap/Carousel';

class CampHostAdminReview extends React.Component{
    constructor(props){
        super(props);

        //this.handleSubmitOrg = this.handleSubmitOrg.bind(this);
        this.handleApprove = this.handleApprove.bind(this);
        this.handleDeny = this.handleDeny.bind(this);
        this.onChangeInput = this.onChangeInput.bind(this);

        this.state={
            loading:true,
            orgId:"",
            bio:"",
            name:"",
            address:"",
            city:"",
            state:"",
            zip:"",
            contactName:"",
            contactNumber:"",
            thumbnail:"",
            phone:"", 
            website:"", 
            denyReason:""
        };
    }
    onChangeInput(e) {
        this.setState({
          [e.target.name]: e.target.value
        });
        console.log(e.target.value);
    }

    handleApprove(e){
        e.preventDefault();
        const orgId = this.state.orgId;
        const response = {
            OrganizationID: orgId, 
            Approved: true
        }
        AdminService.respondToOrgApprovalRequest(response).then(data=>{
            window.alert("Response sent! You can close this window");
            console.log(data);
        });
    }

    handleDeny(e){
        e.preventDefault();
        e.preventDefault();
        const orgId = this.state.orgId;
        const response = {
            OrganizationID: orgId, 
            Approved: false,
            TextResponse: this.state.denyReason
        }
        AdminService.respondToOrgApprovalRequest(response).then(data=>{
            //window.alert("Response sent! You can close this window");
            console.log(data);
        });
    }

    /*handleSubmitOrg(e){
        e.preventDefault();

        if(!this.verifyModalForm()){
            this.setState({
                hasModalError:true,
                modalErrorMessaage:"Please fill in all required(*) fields"
            });
        }else{
            const formData = new FormData();
            if(this.state.thumbnail){
              formData.append('thumbnail', this.state.thumbnail[0]);
            }
            
            formData.append('name', this.state.name);
            formData.append('address', this.state.address);
            formData.append('city', this.state.city);
            formData.append('state', this.state.state);
            formData.append('zip', this.state.zip);
            formData.append('phone', this.state.phone);
            formData.append('website', this.state.website);
            formData.append('bio', this.state.bio);
            formData.append('contactName', this.state.contactName);
            formData.append('contactNumber', this.state.contactNumber);

            HostService.addOrg(formData).then((response)=>{
                    this.setState({
                        name:"",
                        address:"",
                        city:"",
                        state:"",
                        zip:"",
                        phone:"",
                        website:"",
                        bio:"",
                        contactName:"",
                        contactNumber:"",
                        thumbnail:null,
                        hasModalError:false,
                        modalErrorMessaage:""
                    });
                    this.toggleModal();
                    HostService.getOrgs().then((result)=>{
                        console.log(result.data);
                        this.setState({orgs:result.data});
                    },error=>{
                        if(error.response.data.message=="Failed to authenticate token."){
                            logout();
                        }
                        console.log(error.response);
                    });
                },(error)=>{
    
            });
        }
    }*/

    componentDidMount(){
        //Load org data
        const { match: { params } } = this.props;
        this.setState({orgId:params.id});
        DataService.getOrg(params.id).then(orgs=>{
            const org = orgs.data[0];
            const fullAddress = `${org.Address} ${org.City}, ${org.State} ${org.Zip}`
            this.setState({
                id: org.ID,
                campOwnerId: org.CampOwnerId,
                name: org.Name,
                bio: org.Bio,
                phone: org.Phone,
                contactName: org.ContactName,
                contactNumber: org.ContactNumber,
                address: fullAddress,
                city: org.City,
                state: org.State,
                zip: org.Zip,
                thumbnail: org.Thumbnail,
                website: org.Website
            },()=>{this.setState({loading:false});console.log(org)});
        });
    }

    render(){
        if(this.state.loading){
            return(
                <Fragment>
                    <div className="row d-flex justify-content-center" style={{marginTop:20}}>
                        <div className="col-sm-8 col-md-6">
                            <ContentLoader style={{width:'100%'}}>
                                <rect x="0" y="0" rx="5" ry="5" width="100" height="100" />
                                <rect x="120" y="17" rx="4" ry="4" width="700" height="16"  />
                                <rect x="120" y="45" rx="3" ry="3" width="650" height="13" />
                                <rect x="120" y="70" rx="3" ry="3" width="550" height="13" />
                            </ContentLoader>
                        </div>
                    </div>
                </Fragment>
            )
        }
      return (
          <Fragment>
            <div className={styles['header']}>
                <div className="row">
                    <div className='col-sm-5 col-md-5'>
                        {/*<h3 style={{cursor:"pointer"}} className={styles['form-text']} onClick={this.onBack}>
                            <i className="fas fa-chevron-circle-left"></i> Back
                        </h3>*/}
                    </div>
                    <div className='col-sm-2 col-md-2'>
                        <h1 className={styles['form-text']}>ORGANIZATION REVIEW</h1>
                    </div>
                    <div className='col-sm-4 col-md-4' onClick={this.toggleModal}>
                        <h1 style={{cursor:'pointer'}} className={styles['form-text']}> </h1>
                    </div>                      
                </div>
            </div>

            <div className="container">
                    <div className="row" style={{marginTop:100}}>
                        <div className="col-sm-12 col-md-6">
                            <Carousel>
                                <Carousel.Item>
                                    <img className='d-block w-100' src={this.state.thumbnail ? this.state.thumbnail : swimming} alt='First slide' />
                                </Carousel.Item>
                            </Carousel>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className={styles['org-profile-primary-info']}>
                                <div className={styles.orgname}>
                                    <span className={styles['camp-profile-header-text']}>
                                    NAME:
                                    </span>
                                </div>
                                <div className={styles.orgnameinfo}>
                                    <b>{this.state.name}</b>
                                </div>
                                {/*<div className={styles.camphost}>
                                    <span className={styles['camp-profile-header-text']}>
                                    Bio:
                                    </span>
                                </div>
                                <div className={styles.camphostinfo}>
                                    {this.state.bio}
                                </div>*/}
                                <div className={styles.address}>
                                    <span className={styles['camp-profile-header-text']}>
                                    ADDRESS:
                                    </span>
                                </div>
                                <div className={styles.addressinfo}>
                                    <b><span>{this.state.address}</span></b>
                                </div>
                                <div className={styles.contact}>
                                    <span className={styles['camp-profile-header-text']}>
                                    CONTACT:
                                    </span>
                                </div>
                                <div className={styles.contactinfo}>
                                    <span style={{textDecoration:"underline"}}>Organization Phone:</span> <b>{this.state.phone}</b><br/>
                                    <span style={{textDecoration:"underline"}}>Website:</span> <b>{this.state.website}</b><br/>
                                    <span style={{textDecoration:"underline"}}>Contact Point Name:</span> <b>{this.state.contactName}</b><br/>
                                    <span style={{textDecoration:"underline"}}>Contact Point Phone:</span> <b>{this.state.contactNumber}</b><br/>
                                </div>
                                <div className={styles.button} style={{textAlign:"center"}}>
                                    <Button
                                    text="Approve"
                                    type="submit"
                                    onClick={this.handleApprove}
                                    buttonStyle="btn--default"
                                    icon="none"
                                    widthValue="100%"
                                    ></Button>
                                    <br/><br/><br/><br/>
                                    <textarea
                                    id="denyReason"
                                    className="input"
                                    name="denyReason"
                                    value={this.state.denyReason}
                                    onChange={this.onChangeInput}
                                    />
                                    <Button
                                    text="Deny"
                                    type="submit"
                                    onClick={this.handleDeny}
                                    buttonStyle="btn--default"
                                    icon="none"
                                    widthValue="100%"
                                    ></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{marginTop:40}}>
                        <div className="col-sm-12">
                            <h2>Organization Bio</h2>
                            <hr/>
                            {this.state.bio}
                        </div>
                    </div>
                </div>
          </Fragment>
      );
    }
}

export default CampHostAdminReview;
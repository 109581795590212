import React from 'react';
import styles from './CampHost.module.css';

const CampHostCampCard = ({ image, alt, text }) => {
  return (
    <div className={styles['my-camp-card-container']}>
      <img src={image} alt={alt} className={styles['my-camp-card-image']} />
      <p className={styles['my-camp-card-text']}>{text}</p>
    </div>
  );
};

export default CampHostCampCard;
import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';

const API_URL =
process.env.NODE_ENV === 'production'
  ? 'https://summertymes-api.herokuapp.com'
  : 'http://localhost:3000';

const config = {
    headers: {
        'Content-Type': 'application/json'
    }
};

class EcomService{
    async getSubscription() {
        if (localStorage.token) {
            setAuthToken(localStorage.token);
        }

        const response = await axios.get(`${API_URL}/api/host/subscription`);
        //console.log(response);
        return response;
    }

    async createSubscription(priceId){
        const response = await axios.post(`${API_URL}/api/ecom/subscription`, {
            priceId
          }, config);
        console.log(response);
        return response;
    }
}

export default new EcomService();
import React, {Fragment, Component} from 'react';

class AddCamper extends Component{
    render(){
        return(
            <div>Add Camper</div>
        )
    }
}

export default AddCamper;
import React, {Component, Fragment} from 'react';
import styles from '../search/Search.module.css';
import climbing from '../search/images/climbing.jpg';
import outdoor from '../search/images/outdoor.jpg';
import swimming from '../search/images/swimming.jpg';
import Carousel from 'react-bootstrap/Carousel';
import Link from 'react-dom';
import DataService from '../../services/dataService';
import HostService from '../../services/hostService';
import ContentLoader from "react-content-loader";
import Button from "../common/Button";
import RelatedCampsList from "../camps/RelatedCampsList";


class HostOrgProfile extends Component{
    constructor(props){
        super(props);

        this.handleViewCamps = this.handleViewCamps.bind(this);
        this.handleEdit = this.handleEdit.bind(this);

        this.state={
            loading:true,
            bio:"",
            name:"",
            address:"",
            city:"",
            state:"",
            zip:"",
            website:"",
            contactName:"",
            contactNumber:"",
            thumbnail:"",
            phone:"",
            camps:[]
        };
    }

    componentDidMount(){
        const { match: { params } } = this.props;
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        DataService.getOrg(params.id).then(orgs=>{
            const org = orgs.data[0];
            console.log(org);
            const fullAddress = `${org.Address} ${org.City}, ${org.State} ${org.Zip}`
            this.setState({
                id: org.ID,
                name: org.Name,
                bio: org.Bio,
                phone: org.Phone,
                contactName: org.ContactName,
                contactNumber: org.ContactNumber,
                address: fullAddress,
                city: org.City,
                state: org.State,
                zip: org.Zip,
                thumbnail: org.Thumbnail,
                website: org.Website
            },()=>{
                HostService.getCamps(this.state.id).then(camps=>{
                    this.setState({
                        camps:camps.data
                    },()=>{this.setState({loading:false});console.log(org)});
                },error=>{
                    console.log(error.response);
                })
            });
        });
        //console.log("tet");
    }

    handleViewCamps(e){
        e.preventDefault();
        this.props.history.push(`/host/org/${this.state.id}/camps`);
    }

    handleEdit(e){
        e.preventDefault();
        this.props.history.push(`/host/editorg/${this.state.id}`);
    }

    render(){
        if(this.state.loading){
            return(
                <Fragment>
                    <div className="row d-flex justify-content-center" style={{marginTop:20}}>
                        <div className="col-sm-8 col-md-6">
                            <ContentLoader style={{width:'100%'}}>
                                <rect x="0" y="0" rx="5" ry="5" width="100" height="100" />
                                <rect x="120" y="17" rx="4" ry="4" width="700" height="16"  />
                                <rect x="120" y="45" rx="3" ry="3" width="650" height="13" />
                                <rect x="120" y="70" rx="3" ry="3" width="550" height="13" />
                            </ContentLoader>
                        </div>
                    </div>
                </Fragment>
            )
        }
        return(
            <Fragment>
                <div className="container">
                    <div className="row" style={{marginTop:20}}>
                        <div className="col-sm-12 col-md-6">
                            <Carousel>
                                <Carousel.Item>
                                    <img className='d-block w-100' src={this.state.thumbnail ? this.state.thumbnail : swimming} alt='First slide' />
                                </Carousel.Item>
                            </Carousel>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className={styles['org-profile-primary-info']}>
                                <div className={styles.orgname}>
                                    <span className={styles['camp-profile-header-text']}>
                                    NAME:
                                    </span>
                                </div>
                                <div className={styles.orgnameinfo}>
                                    {this.state.name}
                                </div>
                                {/*<div className={styles.camphost}>
                                    <span className={styles['camp-profile-header-text']}>
                                    Bio:
                                    </span>
                                </div>
                                <div className={styles.camphostinfo}>
                                    {this.state.bio}
                                </div>*/}
                                <div className={styles.address}>
                                    <span className={styles['camp-profile-header-text']}>
                                    ADDRESS:
                                    </span>
                                </div>
                                <div className={styles.addressinfo}>
                                    <span>{this.state.address}</span>
                                </div>
                                <div className={styles.contact}>
                                    <span className={styles['camp-profile-header-text']}>
                                    CONTACT:
                                    </span>
                                </div>
                                <div className={styles.contactinfo}>
                                    <span style={{textDecoration:"underline"}}>Organization Phone:</span> {this.state.phone}<br/>
                                    <span style={{textDecoration:"underline"}}>Website:</span> {this.state.website}<br/>
                                    <span style={{textDecoration:"underline"}}>Contact Point Name:</span> {this.state.contactName}<br/>
                                    <span style={{textDecoration:"underline"}}>Contact Point Phone:</span> {this.state.contactNumber}<br/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{marginTop:40}}>
                        <div className="col-sm-12">
                            <h2>Organization Bio</h2>
                            <hr/>
                            {this.state.bio}
                        </div>
                    </div>
                    <div className="row" style={{marginTop:20}}>
                        <div className="col-sm-12">
                            <h3>Camps offered by this organization:</h3>
                            <br/>
                            <RelatedCampsList camps={this.state.camps}/>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default HostOrgProfile;
import React, { Fragment } from 'react';
import styles from './Landing.module.css';
import { Link } from 'react-router-dom';
import Button from './Button';

const PrivacyPolicy = () => {
  return (
    <Fragment>
      <header className={styles.hero} style={{minHeight:400}}></header>

      <div className={styles['form-container']}>
<h1 className={styles['header-text']}>Summertymes Privacy Policy</h1>
        
	<br/><br/>
	
	<h2> WE CARE ABOUT YOUR PRIVACY</h2>

          <br/><br/>
        	<p>
	          	Summertymes respects and cares about your privacy and are committed to protecting your personal data.
	        </p>

	<br/>
		<p>
			This Privacy Policy will help you understand how we process and protect your personal data when you use our Platform, or when you otherwise interact with us. We tell you about your privacy rights and how the law protects you.
		</p>
	<br/>
		<p>
			This Privacy Policy is helpfully provided in a layered format so you can easily navigate to the specific areas set out below. Please use the Glossary to understand the meaning of some of the capitalized terms used in this Privacy Policy.
		</p>
	<br/>
		<p>
			If you join Summertymes and create a user account, you are a <b>“Registered User”</b>. If you are using the Services as a paying customer, you are a <b>“Member”</b>.
		</p>

	<br/><br/>
			<h2>PURPOSE OF THIS PRIVACY POLICY</h2>
	<br/><br/>
		<p>
			This Privacy Policy aims to give you information on how we process and protect your personal data, as a result of your interest in our Platform and/or Services. This is so that you are fully aware of how and why we are using your data. 
		</p>

	<br/>
		<p>
			Please read the following carefully to understand how our practices regarding your personal information and how we will treat it. If you do not agree with this Privacy Policy, please do not use our site.
		</p>
	<br/>
		<p>
			It is important that you read this Privacy Policy together with any other privacy notices or other policies we may provide. This Privacy Policy supplements the other notices and is not intended to override them.
		</p>
	<br/><br/>
		
		<h2>WHO WE ARE & CONTACT DETAILS</h2>
	
	<br/><br/>
		<p>
			Summertymes is an online platform facilitating the connection between Camp/Activity Owners/Hosts (activity providers) and Customers who search, find and sign up for activities offered by the Camp/activity owners/hosts. When we talk about Summertymes “we”, ‘’our’’ or ‘’us’’ in this policy, we are referring to Summertymes, LLC., the company which provides the Platform and/or Services.
		</p>
	<br/>
		<p>
			For the purposes of the applicable data protection legislation, Summertymes is the Data Controller and is responsible for your personal data. This means that we determine the purposes and ways of the processing of your personal data.
		</p>
	<br/>
		<p>
			If you have any general enquiries related to this Privacy Policy please email us here: privacy@summertymes.com. This email address is monitored on a regular basis and we aim to respond to your query as soon as possible.
		</p>
	<br/><br/>
	
		<h2>CHANGES TO THIS PRIVACY POLICY</h2>
	<br/><br/>
		<p>
			We may modify this Privacy Policy at any time, however, if we make any material changes to it we will revise the date at the top of the policy and may provide you with notice either via a message on our Platform or via sending you an email so that you have the opportunity to review the changes before they become effective.
		</p>
	<br/><br/>
		<h2>YOUR DUTY TO INFORM US OF CHANGES</h2>
	<br/><br/>
		<p>
			<b>Members: </b>
		
			If you object to any of the changes, you have the opportunity to delete your account and any associated information. Once deleted your details will be non-recoverable. If you would like help with this, please email privacy@summertymes.com.
		</p>
	<br/> 
		<p>
			It is important that the personal data we hold about you is accurate and current. Please keep us informed should your personal data change during your relationship with us. You can do this at any time by logging into your account.
		</p>
	<br/>
		<p>	
			<b>Registered Users: </b>
	
			Please contact us at privacy@summertymes.com if you i) object to any changes; or ii) need to inform us of any personal data changes during your relationship with us.
		</p>
	<br/><br/>
		
		<h2>THE DATA WE COLLECT ABOUT YOU</h2>
	<br/><br/>
		<p>
			Personal data, or personal information, means any information about you from which you can be identified. It does not include data where the identity has been removed (anonymous data).
		</p>
	<br/>
		<p>
			<b>Data Collected By Us</b>
	<br/>
	
			We use different methods to collect data from and about you, including through:
		</p>
	<br/>
		
			<ul>
				<p>
				<b>Direct interactions. </b>
					We collect information from you when you voluntarily submit information directly to us or via our Platform. For Members this refers to personal data you provide when, for example; you create an account, complete a camp listing, or by corresponding with us via post, phone, or email. For Registered Users, this refers to personal data you provide when you create an account on our Platform. For both Members and Registered Users, this will include Marketing and Communications Data.
				</p>
			</ul>
		<br/>
			<ul>
				<p>
				<b>Automated technologies or interactions.</b> 
					As you interact with our Platform, we may automatically collect Technical and Usage Data about your device, browsing actions and patterns. For example, we collect information which may include your IP address, the browser type, your location, language preferences device information and access times. We collect this personal data by using cookies, pixel tags and other similar tracking technologies. The sole purpose of passively collecting your information is to improve your experience when using our Platform and/or Services.
				</p>
			</ul>
		<br/>
			<ul>
				<p>
				<b>Cookies.</b> 
					We use cookies and similar tracking technologies to track the activity on our Platform and may also receive Technical Data about you if you visit other websites employing our cookies. Cookies are files with a small about of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your devices. You can instruct your browser to refuse all cookies. However, if you do not accept cookies, you may not be able to use some of our Service. Please see our cookie policy for further details.
				</p>
			</ul>
		<br/>
			<ul>
				<p>
				<b>Data Collected by Third Parties</b>
					The Platform may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy notices. When you leave our website, we encourage you to read the privacy policy of every website you visit.<br/>
					We may receive personal data about you from third parties and public sources, for example, the provider that conducts background checks. In this instance we will comply with all necessary data protection obligations.<br/>
					If you create or login to your account through social media (i.e. Facebook) we will have access to certain personal data from that site, such as your name and account information, in accordance with authorization procedures determined by such social media site.<br/>
					As part of your Membership, we may give you the ability to post on your social media account (i.e. Facebook) that you are undertaking a particular activity, once you have received your confirmation email from us.<br/>
					As part of your Camp Host Membership, we give you the ability to provide personal data in order to gain external references that can be added to your profile as part of your verification process. This involves you giving us the personal data of these external referees for this purpose. You hereby confirm that you have their consent to do this.<br/>
					As part of your membership for offering camp/activity services, you have the ability to provide personal data to a third party provider who will carry out checks for any criminal convictions and offences. The third party will then provide us with that information.<br/>
					As part of your Membership you can i) leave a review or give feedback on another Member; or ii) you can receive a review or feedback from another Member. This will involve the processing of your personal data. We would encourage you not to include any personal when writing a review or giving feedback.<br/>
					We do not actively process any Special Categories of Personal Data about you (this includes for example; details about your race or ethnicity, religious or philosophical beliefs, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data).
				</p>
			</ul>
	<br/><br/>

	<h2>WHAT HAPPENS IF YOU DON’T PROVIDE US WITH YOUR PERSONAL DATA</h2>
	
	<br/><br/>
	
		<p>
		If you fail to provide personal data when required, we may not be able to i) perform the contract we have (or are trying to enter into) with you; and/or ii) otherwise communicate with you; and/or iii) provide you with our Services.
		</p>
	
	<br/><br/>
			
	<h2>HOW WE PROCESS YOUR PERSONAL DATA</h2>
	
	<br/><br/>
	
		<p>
			We have set out below, in a table format, a description of all the ways we process your personal data, and the legal bases we rely on to do so. Please email privacy@summertymes.com if you need details about the specific legal basis we are relying on to process your personal data.		
		</p>
	<br/>
	
	<table>
		
		<tr>		
			<td>
				Purpose/Activity
			</td>	
			
			<td>
				Type of data
			</td>	
			
			<td>
				Lawful basis for processing
			</td> 
		</tr>
		
		<tr>
			<td>
				Visiting the website
			</td>
		
			<td>
				a.	Technical Data
				<br/>
				b.	Usage Data	
			</td>
		
			<td>
				Consent
			</td>
		</tr>
		
		<tr>
			<td>		
				Creating an Account as a Registered User.	
			</td>
			
			<td>
				a.	Identity Data
				<br/>
				b.	Marketing and Communications Data
				<br/>
				c.	Usage Data	
			</td>
			
			<td>
				Consent
			</td>
		</tr>

		<tr>
			<td>
				To register you as a Member.	
			</td>
			
			<td>
				a.	Financial Data
				<br/>
				b.	Identity Data
				<br/>
				c.	Marketing and Communications Data	
			</td>

			<td>		
				Consent
			</td>
		</tr>

		<tr>
			<td>
				To create a profile.	
			</td>
		
			<td>
				a.	Identity Data
				<br/>
				b.	Image Data
				<br/>
				c.	Marketing and Communications Data
				<br/>
				d.	Owner Listing Data
				<br/>
				e.	Profile Data
				<br/>
				f.	Welcome Guide Data	
			</td>

			<td>
				Consent
			</td>
		</tr>

		<tr>
			
			<td>
				For Camp Hosts to complete the verification process. 	
			</td>
	
			<td>
				
				a.	Identity Data
				<br/>
				b.	Marketing and Communications Data
				<br/>
				c.	Verification Data	
			</td>

			<td>
				Consent
			</td>
		</tr>

		<tr>
			<td>
					To facilitate Members communicating via the Platform, including us sending you an email alert.	Any data you choose to share and receive via the messaging system on the Platform	Consent
To enable you to renew your membership or for us to auto-renew your membership.	
			</td>

			<td>
				a.	Financial Data
				<br/>
				b.	Identity Data
				<br/>
				c.	Marketing and Communications Data	
			</td>

			<td>
				Consent
			</td>

		</tr>

		<tr>
			<td>
				To manage our relationship with you, from our Membership Services department. For example general enquiries such as how the service works, complaints and payment queries.	Any data you choose to share with us or request us to look at	Consent
Administer and protect the Platform (including troubleshooting, fraud prevention, spam prevention, security incident prevention and correction, preventing other such harmful activity, data analysis, testing, system maintenance, support, reporting and hosting of data); and/or send you service and support messages such as updates, patches and security alerts.	
			</td>

			<td>

				a.	Financial Data
				<br/>
				b.	Identity Data
				<br/>
				c.	Technical Data	
			</td>

			<td>
				Consent
			</td>
		</tr>
		
		<tr>
			<td>
					To send you promotional messages, marketing, advertising and other such information we think may be of interest to you
To operate the Platform (including notifying you of any changes) and to optimize your user experience.	
			</td>

			<td>
				a.	Financial Data
				<br/>
				b.	Identity Data
				<br/>
				c.	Technical Data	
			</td>

			<td>
				Consent
			</td>
		</tr>
		
		<tr>
			<td>
					To deliver relevant website content and third party advertisements.	
			</td>
			
			<td>
				a.	Marketing and Communications Data	
			</td>

			<td>
				Consent
			</td>
		</tr>

		<tr>
			<td>
				To maintain and retain records in connection with legal claims and regulatory investigations; and for governance & compliance purposes.	
			</td>

			<td>
				a.	Financial Data
				<br/>
				b.	Identity Data	
			</td>

			<td>
				Necessary for the compliance with a legal obligation to which we are subject.
			</td>
		</tr>
				
				
	</table>

	<br/><br/>		
	
	<h2>HOW THIRD PARTIES PROCESS YOUR PERSONAL DATA</h2>
	
	<br/><br/>
	
		<p>
			In order to provide you with the Platform and/or Services, we may need to give your personal data to third parties who will process your data on our behalf, as set out in the table below.
		</p>
	<br/>
		<p>
			For example, in order to process your payment for the Services we will need to give your Identity Data, Technical Data, Usage Data and/or Marketing and Communications Data to Stripe Inc. our payment processing provider.
		</p>
	<br/>
		<p>
			We require all third parties to respect the security of your personal data and to process it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.
		</p>
	<br/>	
		<p>
			To provide you with the best experience of our Services, we use third parties who automatically process your Usage Data on our behalf. Where possible, we will make sure this data is anonymized.
		</p>
	<br/>
		<p>
			You can request for our full list of third parties who process your data by sending an email to privacy@summertymes.com. For your information please see the list including but not limited to our third party suppliers that process your personal data.
		</p>

	<br/>

	<table>
		<tr>
			<td>Purpose/Activity</td>	
			<td>Type of data</td>
			<td>Third Party data is given to</td>	
			<td> Lawful basis for processing</td>	
		</tr>
		<tr>
			<td>To enable us to process payment for the Services.</td>	
			<td>a.	Financial Data<br/> b.	Identity Data </td>
			<td>Stripe, Inc.<br/>185 Berry Street, Suite 550<br/>San Francisco, CA 94107</td>
			<td>Consent</td>
		</tr>
		<tr>
			<td>To provide cloud storage solutions.</td>	
			<td>All data held on Summertymes’ systems and databases.</td>	
			<td>Heroku<br/>415 Mission Street<br/>Suite 300<br/>San Francisco, CA 94105</td>			
			<td>Consent</td>
		</tr>
	</table>


	<br/><br/>

	<h2>RIGHTS IN RELATION TO YOUR PERSONAL DATA</h2>

	<br/><br/>

		<p>
			You have rights under data protection laws in relation to your personal data, in particular under the General Data Protection Regulation (GDPR) you have the following rights:
		</p>

	<br/><br/>
	      
		<ul>
			<p>
				1.	To request access to your personal data. This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it. Please email privacy@summertymes.com. 
			</p>
		</ul>

		<ul>
			<p>
				2.	To request confirmation as to whether or not your personal data is being processed.
			</p>
		</ul>

		<ul>
			<p>
				3.	To request the correction of your personal data that you consider to be inaccurate. This enables you to have any incomplete or inaccurate data we hold about you corrected. However, we may need to verify your identity and the accuracy of the new data you provide to us.
			</p>
		</ul>

		<ul>
			<p>
				4.	To request erasure of your personal data. This enables you to ask us to delete or remove personal data, for example: i) where there is no good reason for us continuing to process it; ii) where you have successfully exercised your right to object to processing (see below); iii) where we may have processed your information unlawfully; iv) where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons. These reasons will be notified to you at the time of your request.
			</p>
		</ul>

		<ul>
			<p>
				5.	To object to processing of your personal data. This enables you to object to the processing of your personal data if you feel it impacts on your fundamental rights and freedoms. For example, this can be where we are processing your personal data for direct marketing purposes. In some cases, we may have compelling legitimate grounds to process your information which can override your right to object.
			</p>
		</ul>

		<ul>
			<p>
				6.	To request restriction of processing your personal data. This gives you the option to ask us to suspend the processing of your personal data in the following scenarios: i) if you want us to establish the data’s accuracy; ii) where our use of the data is unlawful but you do not want us to erase it; iii) where you need us to hold the data, even if we no longer require it e.g. to establish or defend legal claims; or iv) you have objected to our use of your data but we need to verify whether we have overriding legitimate ground to use it.
			</p>
		</ul>

		<ul>
			<p>
				7.	To request transfer of your personal data. If you request us to do so, we will provide to you, or a third party of your choice, your personal data in a commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use, or where we have used the information to perform a contract with you.
			</p>
		</ul>

		<ul>
			<p>
				8.	To withdraw consent to the processing of your data. If you request us to do so, we will no longer process your data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we will not be able to provide the Services for you. Also, we will advise you of this at the time you withdraw your consent.
			</p>
		</ul>

		<ul>
			<p>
				9.	To not have a decision made about you based solely on automated processing.
			</p>
		</ul>
	<br/><br/>
		<p>
			If you wish to exercise any of the rights set out above, in the first instance, please email us at privacy@summertymes.com. 
		</p>
	<br/>
		<p>
			We will respond to you within 30 days of receipt of your request. Occasionally it may take us longer than 30 days if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.
		</p>
	
	<br/><br/>
	
		<h2>MARKETING  BY US</h2>
	
	<br/><br/>
	
		<p>
			We may use your personal data to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services and offers may be relevant for you (we call this marketing).
		</p>
		<p>
			You will receive marketing communications from us if i) you have requested information from us; or ii) if you provided us with your details when you entered a competition; or iii) registered for a promotion; and, in each case, you have not opted out of receiving that marketing.
		</p>
	
	<br/><br/>
	
		<h2>THIRD PARTY MARKETING</h2>
	
	<br/><br/>

		<p>	
			We do not share your personal data with third parties, however, we do provide Members with access to the ‘Hand-picked offers for you’ area of the dashboard. These offers are from third parties who we think may be able to provide you with products or services that may be of interest to you.
		</p>

	<br/><br/>
	
		<h2>UNSUBSCRIBE FROM MARKETING AND COMMUNICATIONS</h2> 
	
	<br/><br/>
		<p>
			You can ask us to stop sending you electronic messages (e.g. emails) at any time by (i) clicking the unsubscribe links on any electronic message sent to you or (ii) contact us using the details in the Contact us section of the Platform with your request to unsubscribe from our database.
		</p>
	<br/>
		<p>
			Just to let you know, should you choose to unsubscribe, not only will you no longer receive any marketing emails, you will also no longer receive any emails from us including, for example, password reset emails, daily alerts emails, emails notifying you of a message from another member, and any emails regarding your account i.e. your auto renewal notifications.
		</p>
	<br/>	
		<p>
			If you would like to continue only receiving emails relating to your account (i.e. not marketing emails) please contact us at privacy@summertymes.com
		</p>
	
	<br/><br/>
		
		<h2>DATA SECURITY</h2>
	
	<br/><br/>
	
		<p>	
			We have put in place appropriate security measures to prevent your personal data from being accidentally lost, altered or disclosed, used or accessed in an unauthorized way. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know it. They will only process your personal data on our instructions and they are subject to a duty of confidentiality. However, the transmission of data via the internet is not completely secure and we cannot guarantee that unauthorised parties will not be able to defeat those measures.
		</p>
	
	<br/>
		<p>
			We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.
		</p>
	
	<br/><br/>
	
		<h2>DATA RETENTION</h2>
	
	<br/><br/>
	
		<p>
			We will only retain your personal data for as long as necessary to fulfil the purposes for which we collected, including for the purposes of satisfying any legal, accounting, or internal reporting requirements.
		</p>
	
	<br/>
		<p>	
			To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorized use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.
		</p>
	<br/>
		<p>
			In some circumstances we may anonymize your personal data (so that it can no longer be associated with you) for compliance, governance, legal and/or regulatory purposes in which case we may use this information indefinitely without further notice to you.
		</p>
	
	<br/><br/>

		<h2>WHAT INFORMATION WE MAY NEED FROM YOU</h2>

	<br/><br/>
	
		<p>
			We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise Your Legal Rights). This is a security measure to ensure that your personal data is not disclosed to any person who does not have the right to receive it. We may also contact you to ask you for further information, in relation to your request, to speed up our response.
		</p>
	
	<br/><br/>
				
		<h2>PROCESSING YOUR DATA FOR OTHER PURPOSES</h2>
	
	<br/><br/>
	
		<p>
			In the future we may decide to sell, transfer or merge parts or our business or our assets. Alternatively we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this Privacy Policy.
		</p>
	<br/>
		<p>
			We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason, and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please email us at privacy@summertymes.com.
		</p>
	<br/>
		<p>
			Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.
		</p>
	
	<br/><br/>
	
		<h2>CHILDREN</h2>
		
	<br/><br/>

		<p>
			We collect information from you about your children when you voluntarily submit information directly to us or via our Platform. For Members this refers to personal data you provide when you register your child or children to certain camps and/or activities. This information is required in order to fulfill the services requested. If you fail to provide personal data when required, we may not be able to i) perform the contract we have (or are trying to enter into) with you; and/or ii) otherwise communicate with you; and/or iii) provide you with our Services.
		</p>
	
	<br/><br/>
				
		<h2>GLOSSARY</h2>
	
	<br/><br/>
		
		<ul>	
			<p>
				<b>Financial Data </b> includes bank account and payment card details you provide, including expiry date. It also includes details about your payment history and/or services or products you have purchased from us.
			</p>
		</ul>
	
		<ul>	
			<p>
				<b>Identity Data </b> includes full name, title, marital status, date of birth and/or gender. It also includes billing address, home address, postal code, telephone numbers, email address, username, password.
			</p>
		</ul>
	
		<ul>	
			<p>
				<b>Image Data </b> includes photographs and videos of you and/or your camp and/or activities that you provide.
			</p>
		</ul>
	
		<ul>	
			<p>
				<b>Marketing and Communications Data </b> includes information you provide about yourself such as information you provide about your (i) camps or activities. We encourage you to add photos as well, to optimize your listing.
			</p>
		</ul>
	
		<ul>
			<p>
				<b>Camp Owner Listing Data </b> includes information you provide about your camps and/or activities. We encourage you to add photos as well, to optimize your listing.
			</p>
		</ul>
	
		<ul>	
			<p>
				<b>Platform </b> means the online platform accessed via the website or mobile application.
			</p>
		</ul>
	
		<ul>
			<p>
				<b>Services </b> means the benefits of having a paid membership such as having facility to arrange a sit, 24/7 membership services, and support.
			</p>
		</ul>
	
		<ul>	
			<p>
				<b>Camper Profile Data </b> includes information you provide about yourself such as name, age, etc. 
			</p>
		</ul>
	
		<ul>	
			<p>
				<b>Technical Data </b> includes internet protocol (IP) address, your login data, device type, device version, browser type and version, browser plug-ins types, time zone setting and location, traffic data, search criteria, weblogs and other communications data, operating system and platform and other technology on the devices you use to access this website.
			</p>
		</ul>
	
		<ul>	
			<p>
				<b>Usage Data </b> includes information about how you use our Platform and/or Services such as the particular pages you interact with, how often you visit, how long you’re on the Platform, surveys, the service you’re looking at and the emails you interact with.
			</p>
		</ul>

		<ul>	
			<p>
				<b>Verification Data </b> includes a copy of your ID, proof of address and results of criminal record checks.
			</p>
		</ul>



				
      </div>
    </Fragment>
  );
};

export default PrivacyPolicy;

import React, {Fragment, Component} from 'react'; 
import Button from '../common/Button';
import States from '../common/States';
import Cleave from 'cleave.js/react';
import AuthService from '../../services/authService';
import DataService from '../../services/dataService';
import HostService from '../../services/hostService';
import Spinner from "../common/Spinner";
import ContentLoader from "react-content-loader";

class EditOrg extends Component{
    constructor(props){
        super(props);

        this.onChangeInput = this.onChangeInput.bind(this);
        this.onUpdateDetails = this.onUpdateDetails.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onChangeDropdownInput = this.onChangeDropdownInput.bind(this);
        this.onUpdateImage = this.onUpdateImage.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);

        this.state = {
            name:"",
            waiting:false,
            imageSaving:false,
            bio:"",
            address:"",
            city:"",
            state:"",
            zip:"",
            phone:"",
            website:"",
            contactName:"",
            contactPhone:"",
            thumbnail:"",
            oldThumbnail:"",
            hasError:false,
            errorMessage:"",
            updateSaved:false,
            successMessage:"",
            pageLoading:true,
            hasThumbnailError:false,
            thumbnailErrorMessage:""
        }
    }

    componentDidMount(){
        const { match: { params } } = this.props;
        DataService.getOrg(params.id).then((result)=>{
            console.log(result);
            const org = result.data[0];
            this.setState({
                name:org.Name,
                bio:org.Bio,
                address:org.Address,
                city:org.City,
                state:org.State,
                zip:org.Zip,
                phone:org.Phone,
                website:org.Website,
                contactName:org.ContactName ? org.ContactName : "",
                contactPhone:org.ContactNumber ? org.ContactNumber : "",
                oldThumbnail:org.Thumbnail
            },()=>{
                this.setState({pageLoading:false});
            })
        })
    }

    onDelete(type){
        if(window.confirm('Are you sure you wish to delete this org?')){
            const { match: { params } } = this.props;
            const orgId = params.id;
            this.setState({
                waiting:true
            },()=>{
                //delete all associate camps
                HostService.getCamps(orgId).then(async (result)=>{
                    const camps = result.data;
                    for (let i = 0; i < camps.length; i++) {
                        await HostService.deleteCamp(camps[i].ID)
                    }

                    //delete org
                    HostService.deleteOrg(orgId).then(result=>{
                        this.props.history.push({pathname:`/host/orgs`});
                    },error=>{
                        console.log(error.response);
                    });
                },error=>{
                    console.log(error.response);
                });
            })
        }
    }

    onChangeInput(e){
        this.setState({[e.target.name]: e.target.value});
    }

    onChangeDropdownInput(e){
        this.setState({state: e.target.value});
        console.log(e.target.value);
    }

    handleFileUpload = (e) => {
        //console.log(e.target.files);
        this.setState({[e.target.name]: e.target.files});
    }

    onUpdateImage(){
        this.setState({
            hasThumbnailError:false,
            thumbnailErrorMessage:""
        });

        
        if(this.state.thumbnail){
            const { match: { params } } = this.props;
            const id = params.id;
            const formData = new FormData();
            formData.append('thumbnail', this.state.thumbnail[0]);
            this.setState({
                imageSaving:true
            })
            HostService.updateOrgImage(formData, id).then((response)=>{
                window.location.reload(false);
            });
        }else{
            this.setState({
                hasThumbnailError:true,
                thumbnailErrorMessage:"Must upload an image in order to update thumbnail"
            });
        }
    }

    onUpdateDetails(){
        this.setState({
            hasError:false,
            errorMessage:""
        });

        if(!this.verifyDetails()){
            this.setState({
                hasError:true,
                errorMessage: "Please fill in all required(*) fields"
            });
        }else{
            const { match: { params } } = this.props;
            const id = params.id;
            const org = {
                id:id,
                name:this.state.name,
                bio:this.state.bio,
                address:this.state.address,
                city:this.state.city,
                state:this.state.state,
                zip:this.state.zip,
                phone:this.state.phone,
                website:this.state.website,
                contactName:this.state.contactName,
                contactPhone:this.state.contactPhone,
            }
            HostService.updateOrg(org).then((result)=>{
                this.setState({
                    updateSaved:true,
                    successMessage: "Organization details updated!"
                });
                window.location.href = "/host/dashboard?orgupdate=true";
            },error=>{
                console.log(error.response);
            });
        }
    }

    verifyDetails(){
        let ignore = [];
        ignore = ["pageLoading","waiting","imageSaving","bio","website","thumbnail","contactName",
                  "contactPhone","hasError","errorMessage","updateSaved","successMessage",
                  "hasThumbnailError","thumbnailErrorMessage"];

        for (const property in this.state) {
            if(!ignore.includes(property)){
                if(this.state[property]==""||this.state[property]==null){
                    console.log("offender");
                    console.log(property);
                    return false;
                }
            }
        }
        return true;
    }

    render(){
        if(this.state.imageSaving){
            return(
                <Spinner/>
            )
        }
        if(this.state.pageLoading){
            return(
                <Fragment>
                    <div className="row d-flex justify-content-center" style={{marginTop:20}}>
                        <div className="col-sm-8 col-md-6">
                            <ContentLoader style={{width:'100%'}}>
                                <rect x="0" y="0" rx="5" ry="5" width="100" height="100" />
                                <rect x="120" y="17" rx="4" ry="4" width="700" height="16"  />
                                <rect x="120" y="45" rx="3" ry="3" width="650" height="13" />
                                <rect x="120" y="70" rx="3" ry="3" width="550" height="13" />
                            </ContentLoader>
                        </div>
                    </div>
                </Fragment>
            )
        }
        return (
            <Fragment>
                {this.state.waiting ? (
                    <Spinner/>
                ):""}
                <div className="container" style={{marginTop:30}}>
                    <div className="row">
                        <div className="col-sm-6">
                            <h4 style={{textDecoration:"underline",marginBottom:"10px"}}>Organization Details</h4> 
                        </div>
                        <div className="col-sm-6" style={{textAlign:"right"}}>
                            <input style={{width:180}} className="btn btn-danger" type="button" value="Delete Organization" onClick={this.onDelete}></input>
                            
                        </div>                           
                    </div>
                    <hr/>
                    <div className="row">
                            <div className="col-sm-12">
                                <label htmlFor="name">Name*</label>
                                <input
                                    type="text"
                                    id="name"
                                    className="input"
                                    required
                                    name="name"
                                    value={this.state.name}
                                    onChange={this.onChangeInput}
                                />
                            </div>

                            <div className="col-sm-12">
                                <label htmlFor="bio">Organization Bio</label>
                                <textarea
                                id="bio"
                                className="input"
                                required
                                name="bio"
                                value={this.state.bio}
                                onChange={this.onChangeInput}
                                />
                            </div>
                    </div>
                    <br/>
                    <h4 style={{textDecoration:"underline",marginBottom:"10px"}}>Organization Location</h4>
                    <hr/>
                    <div className="row">
                        <div className="col-sm-12">
                                <label htmlFor="firstName">Address*</label>
                                <input
                                    type="text"
                                    id="address"
                                    className="input"
                                    required
                                    name="address"
                                    value={this.state.address}
                                    onChange={this.onChangeInput}
                                />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <label htmlFor="city">City*</label>
                            <input
                                type="text"
                                id="city"
                                className="input"
                                required
                                name="city"
                                value={this.state.city}
                                onChange={this.onChangeInput}
                            />
                        </div>
                        <div className="col-sm-3">
                            <label htmlFor="state">State*</label>
                            <States stateValue={this.state.state} changeFunction={this.onChangeDropdownInput} />
                        </div>
                        <div className="col-sm-3">
                            <label htmlFor="zip">Zip*</label>
                            <input
                                type="text"
                                id="zip"
                                className="input"
                                required
                                name="zip"
                                value={this.state.zip}
                                onChange={this.onChangeInput}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <label htmlFor="phone">Phone*</label>
                            <Cleave
                            options={{ phone: true, phoneRegionCode: 'US', delimiter: '-' }}
                            value={this.state.phone}
                            onChange={this.onChangeInput}
                            className="input"
                            required
                            id="phone"
                            name="phone"
                            />
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="website">Website</label>
                            <input
                                type="text"
                                id="website"
                                className="input"
                                required
                                name="website"
                                value={this.state.website}
                                onChange={this.onChangeInput}
                            />
                        </div>
                    </div>
                    <br/>
                    <h4 style={{textDecoration:"underline",marginBottom:"10px"}}>Contact Person</h4>
                    <hr/>
                    <div className="row">
                        <div className="col-sm-6">
                            <label htmlFor="contactName">Name</label>
                            <input
                                type="text"
                                id="contactName"
                                className="input"
                                required
                                name="contactName"
                                value={this.state.contactName}
                                onChange={this.onChangeInput}
                            />
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="contactPhone">Phone</label>
                            <Cleave
                                options={{ phone: true, phoneRegionCode: 'US', delimiter: '-' }}
                                value={this.state.contactPhone}
                                onChange={this.onChangeInput}
                                className="input"
                                required
                                id="contactPhone"
                                name="contactPhone"
                            />
                        </div>
                    </div>
                    <br/>
                    <div className="row" style={{marginBottom:15}}>
                        <div className="mx-auto text-center p-3">
                            <Button
                                disabled={this.state.imageSaving}
                                text="Update Details"
                                type="submit"
                                onClick={this.onUpdateDetails}
                                buttonStyle="btn--default"
                                icon="none"
                            ></Button>
                        </div>
                    </div>
                    <div>
                    {this.state.hasError ?
                    <Fragment><p className="alert alert-danger" style={{marginTop:30}}>{this.state.errorMessage}</p><br/></Fragment>
                    : ""}
                    </div>
                    <div>
                        {this.state.updateSaved ?
                        <Fragment><p className="alert alert-success" style={{marginTop:30}}>{this.state.successMessage}</p><br/></Fragment>
                        : ""}
                    </div>
                    <h4 style={{textDecoration:"underline",marginBottom:"10px"}}>Thumbnail Image</h4>
                    <hr/>
                    <div className="row">
                        <div className="col-sm-6">
                            Current Image:
                            {this.state.oldThumbnail != null ?
                            <Fragment><br/><img style={{width:200,height:150,borderRadius:10}} src={this.state.oldThumbnail}/></Fragment>
                            : <span> No Image Uploaded</span>}
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        {!this.state.imageSaving ? 
                        <div className="col-sm-6">
                            <label htmlFor="thumbnail">Choose New Thumbnail:</label><br/>
                            <input name="thumbnail" label='upload file' type='file' onChange={this.handleFileUpload} />
                        </div>
                        : <div className="col-sm-6"><span style={{fontWeight:"bold"}}>Saving New Thumbnail.....</span></div>}
                    </div>
                    <div className="row" style={{marginBottom:15}}>
                        <div className="mx-auto text-center p-3">
                            <Button
                                disabled={this.state.imageSaving}
                                text="Update Thumbnail"
                                type="submit"
                                onClick={this.onUpdateImage}
                                buttonStyle="btn--default"
                                icon="none"
                            ></Button>
                        </div>
                    </div>
                    <div>
                        {this.state.hasThumbnailError ?
                        <p style={{marginBottom:10}} className="alert alert-danger">{this.state.thumbnailErrorMessage}</p>
                        : ""}
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default EditOrg;
import React from 'react';
import * as states from '../../utils/states';
import styles from '../hosts/CampHost.module.css';

const loadStates = () => {
    let options = [];
    Object.entries(states.default).forEach(([key, value]) => {
      options.push(<option key={key} value={key}>{value}</option>);
    });
    return options;
};

const States = (props) =>{
    return(
        <select
        id="state"
        disabled={props.disabled}
        value={props.stateValue}
        onChange={props.changeFunction}
        className={styles['dropdown']}
        style={{fontSize:24}}
      >
        <option value=""></option>
        {loadStates()}
      </select>
    );
};

export default States;
import React, { Fragment } from 'react';
import AuthService from "../../services/authService"
import {Link} from 'react-router-dom';
import Button from '../common/Button';
import styles from './Auth.module.css';
import { Redirect } from 'react-router-dom';
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.us';

class CamperRegister extends React.Component{
    onChangeInput(e) {
        this.setState({
          [e.target.name]: e.target.value
        });
    }

    handleRegister(e){
        e.preventDefault();
        if(this.state.password!==this.state.passwordConfirmation){
            this.setState({passwordError:true})
        }else{
            this.setState({passwordError:false})
            const res = AuthService.registerCamper(this.state.firstName, this.state.lastName, this.state.email, this.state.password, this.state.phone)
            .then(response=>{
                console.log(response);
                const serverResponse = response.data;
                localStorage.setItem('jwt', serverResponse.data.token);
                localStorage.setItem('userType', 'camper');
                this.props.history.push("/camper/dashboard");
                window.location.reload();
            },error=>{
                const errResponse = error.response.data;
                console.log(errResponse);
                this.setState({errorMessage: errResponse.message});
                this.setState({hasError: true});
            });
        }
    }
    constructor(props){
        super(props);

        this.handleRegister = this.handleRegister.bind(this);
        this.onChangeInput = this.onChangeInput.bind(this);

        this.state = {
            firstName: "",
            lastName: "",
            phone: "",
            email: "",
            password: "",
            passwordConfirmation:"", 
            passwordError:false,
            hasError:false,
            errorMessage:""
        }
    }
    render(){
        return(
            <Fragment>
                <header className={styles.hero}></header>

                <div className={styles['form-text']}>
                    <h1>CREATE ACCOUNT</h1>
                </div>
                <div>
                    {this.state.passwordError ?
                    <p className="alert alert-danger">Passwords must match</p>
                    : ""}
                    {this.state.hasError ?
                    <p className="alert alert-danger">{this.state.errorMessage}</p>
                    : ""}
                </div>
                <div className={styles['form-container']}>
                    <form class="register-form" onSubmit={e => this.handleRegister(e)}>
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <label htmlFor="firstName">First Name</label>
                                <input
                                    type="text"
                                    id="firstName"
                                    className="input"
                                    required
                                    name="firstName"
                                    value={this.state.firstName}
                                    onChange={this.onChangeInput}
                                />
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label htmlFor="lastName">Last Name</label>
                                <input
                                    type="text"
                                    id="lastName"
                                    className="input"
                                    required
                                    name="lastName"
                                    value={this.state.lastName}
                                    onChange={this.onChangeInput}
                                />
                            </div>
                        </div>
                        <div className="row" style={{marginTop:20}}>
                            <div className="col-sm-12 col-md-6">
                                <label htmlFor="phone">Phone</label>
                                <Cleave
                                    options={{ phone: true, phoneRegionCode: 'US', delimiter: '-' }}
                                    value={this.state.phone}
                                    onChange={this.onChangeInput}
                                    className="input"
                                    required
                                    id="phone"
                                    name="phone"
                                />
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    className="input"
                                    required
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.onChangeInput}
                                />
                            </div>
                        </div>
                        <div className="row" style={{marginTop:20}}>
                            <div className="col-sm-12 col-md-6">
                                <label htmlFor="password">Password</label>
                                <input
                                    type="password"
                                    id="password"
                                    className="input"
                                    required
                                    name="password"
                                    value={this.state.password}
                                    onChange={this.onChangeInput}
                                />
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label htmlFor="passwordConfirmation">
                                    Password Confirmation
                                </label>
                                <input
                                    type="password"
                                    id="passwordConfirmation"
                                    className="input"
                                    required
                                    name="passwordConfirmation"
                                    value={this.state.passwordConfirmation}
                                    onChange={this.onChangeInput}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="mx-auto text-center p-3">
                                <Button
                                    text="Create Account"
                                    type="submit"
                                    onClick={() => {}}
                                    buttonStyle="btn--default"
                                    icon="none"
                                ></Button>
                            </div>
                        </div>
                    </form>
                    <div className={styles['forgot-password']} style={{textAlign:"center"}}>
                        <p>Already Registered? <Link to="/camper/login">Log In Here</Link></p>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default CamperRegister;
import axios from 'axios';

const API_URL =
process.env.NODE_ENV === 'production'
  ? 'https://summertymes-api.herokuapp.com'
  : 'http://localhost:3000';

const config = {
    headers: {
        'Content-Type': 'application/json'
    }
};

class DataService{
    async getCategories() {
        const response = await axios.get(`${API_URL}/data/categories`);
        return response;
    }

    async getCamp(id){
        const response = await axios.get(`${API_URL}/api/camps/${id}`);
        return response;
    }

    async getOrg(id){
        const response = await axios.get(`${API_URL}/api/orgs/${id}`);
        return response;
    }

    async getCampImages(id){
        const response = await axios.get(`${API_URL}/api/camps/${id}/images`);
        return response;
    }

    async getCampCategories(id){
        const response = await axios.get(`${API_URL}/api/camps/${id}/categories`);
        return response;
    }

    async getCurrentUser(){
        const response = await axios.get(`${API_URL}/me`);
        return response;
    }

    async resetPassword(email){
        const response = await axios.post(`${API_URL}/api/host/requestpasswordreset`,{email},config);
        return response;
    }
}

export default new DataService();
import React from 'react';
import { Link } from 'react-router-dom';
import PaymentCard from './PaymentCard';
import swimming from '../search/images/swimming.jpg';

const PaymentList = (props) =>{
    return props.cards.map(card => {
        return (
          <Link to={`/camper/cards/${card.ID}`} key={card.ID}>
            <PaymentCard
              text={card.Number}
              key={card.ID}
            />
          </Link>
        );
      });
}

export default PaymentList;
import React,{Component, Fragment} from 'react';
import styles from './Search.module.css';
import Button from '../common/Button';
import outdoor from './images/outdoor.jpg';
import CampHostCampCard from '../hosts/CampHostCampCard';
import CampHostOrgCard from '../hosts/CampHostOrgCard';
import { Container, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import SearchService from '../../services/searchService';
import DataService from '../../services/dataService';
import { Multiselect } from 'multiselect-react-dropdown';

class Search extends Component{
    constructor(props){
        super(props);

        this.multiselectRef = React.createRef();
        this.onSearchQueryChange = this.onSearchQueryChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.searchReset = this.searchReset.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.onFiltersChange = this.onFiltersChange.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onCategoryClick = this.onCategoryClick.bind(this);
        //this.onRemove = this.onRemove.bind(this);

        this.state={
            searchResults:[],
            checkedCategories:new Map(),
            campCategories:[],
            categories:[],
            selectedValue:[],
            query:{
                type:"camp",
                term:"",
                filters:{
                    zip:"",
                    age:"",
                    month:"",
                    cost:""
                },
                categories:[]
            }
        };
    }

    onCategoryClick(id){
        console.log(id);
        this.setState(prevState => ({
            query: {                   // object that we want to update
                ...prevState.query,    // keep all other key-value pairs
                categories: [id]       // update the value of specific key
            }
        }),()=>{
            console.log(this.state);
            this.props.history.push({pathname: '/searchresults', state: {query: this.state.query}});
        });
        //this.props.history.push({pathname: '/searchresults', state: {query: this.state.query}});
    }

    onSelect(){
        const sels = this.multiselectRef.current.getSelectedItems();
        this.setState({campCategories:[]},()=>{
            const tempcat = this.state.campCategories;
            for (let i = 0; i < sels.length; i++) {
                tempcat.push(sels[i].ID);
            }
            this.setState({campCategories:tempcat});
            this.setState(prevState => ({
                query: {                   // object that we want to update
                    ...prevState.query,    // keep all other key-value pairs
                    categories: tempcat       // update the value of specific key
                }
            }),()=>{console.log(this.state)});
        });
    }

    onSearchQueryChange(e){
        const val = e.target.value;
        //this.setState({[query.term]:e.target.value});
        this.setState(prevState => ({
            query: {                   // object that we want to update
                ...prevState.query,    // keep all other key-value pairs
                term: val       // update the value of specific key
            }
        }));
    }

    onFiltersChange(e){
        //this.setState({[query.filters[e.target.name]]:e.target.value});
        const name = e.target.name;
        if(name=="age"||name=="cost"){
            const number = (e.target.validity.valid) ? e.target.value : this.state.query.filters[name];
            this.setState(prevState => ({
                query: {
                    ...prevState.query,           // copy all other key-value pairs of food object
                    filters: {                     // specific object of food object
                        ...prevState.query.filters,   // copy all pizza key-value pairs
                        [name]: number          // update value of specific key
                    }
                }
            }),()=>{console.log(this.state)});
        }else{
            const val = e.target.value;
            this.setState(prevState => ({
                query: {
                    ...prevState.query,           // copy all other key-value pairs of food object
                    filters: {                     // specific object of food object
                        ...prevState.query.filters,   // copy all pizza key-value pairs
                        [name]: val          // update value of specific key
                    }
                }
            }),()=>{console.log(this.state)});
        }
    }

    onSubmit(){
        /*SearchService.search(this.state.query).then((results)=>{
            const searchResults = results.data;
            this.setState({searchResults:searchResults});
        });*/
        this.props.history.push({pathname: '/searchresults', state: {query: this.state.query}});
    }

    handleCategoryChange(e){
        const item = e.target.name;
        const isChecked = e.target.checked;
        this.setState(prevState => ({ checkedCategories: prevState.checkedCategories.set(item, isChecked) }),()=>{
            this.setState({campCategories:[]},()=>{
                const tempcat = this.state.campCategories;
                this.state.checkedCategories.forEach((value, key, map)=>{
                    if(value==true){
                        for (let i=0; i < this.state.categories.length; i++) {
                            if (this.state.categories[i].Text === key) {
                                tempcat.push(this.state.categories[i].ID);
                            }
                        }
                    }
                })
                this.setState({campCategories:tempcat});
                this.setState(prevState => ({
                    query: {                   // object that we want to update
                        ...prevState.query,    // keep all other key-value pairs
                        categories: tempcat       // update the value of specific key
                    }
                }),()=>{console.log(this.state)});
            });
        });
    }

    renderResults(){
        return this.state.searchResults.map(res=>(
            <div className="col-sm-12 col-md-4 col-lg-3" key={res.ID}>
            <Link to={`/camps/${res.ID}`}>
              <CampHostCampCard
                key={res.ID}
                image={res.Thumbnail}
                alt="thumbnail"
                text={res.Name}
              />
            </Link>
          </div>  
        ));
    }

    searchReset(e){
        e.preventDefault();

        this.setState({
            searchResults:[],
            checkedCategories:new Map(),
            campCategories:[],
            query:{
                type:"camp",
                term:"",
                filters:{
                    zip:"",
                    age:"",
                    month:"",
                    cost:""
                },
                categories:[]
            }
        },()=>{console.log(this.state)});

        this.multiselectRef.current.resetSelectedValues();

        /*this.setState(prevState => ({
            query: {                   // object that we want to update
                ...prevState.query,    // keep all other key-value pairs
                categories: []       // update the value of specific key
            }
        }),()=>{console.log(this.state)});*/
    }

    componentDidMount(){
        DataService.getCategories().then((res)=>{
            this.setState({categories:res.data});
            console.log(this.state.categories);
        });
    }

    render(){
        return(
            <Fragment>
            <header className={styles.hero}></header>
            <div className={styles['header-text']}>
              <h1>Search</h1>
            </div>
            <div className="row mt-3 justify-content-center" style={{marginTop:10}}>
                <Container>
                    <div className="row mt-3 justify-content-center">
                        <div className="col-sm-9 col-md-7 col-lg-7">
                            <input
                            type="search"
                            placeholder="Keyword"
                            className="input"
                            value={this.state.query.term}
                            onChange={this.onSearchQueryChange}
                            name="searchQuery"
                            />
                        </div>
                        <div className="col-sm-3 col-md-2 col-lg-2">
                            <input
                            type="text"
                            className="input"
                            value={this.state.query.filters.zip}
                            onChange={this.onFiltersChange}
                            style={{marginBottom:10}}
                            name="zip"
                            placeholder="Zip Code"
                            />
                        </div>
                    </div>
                </Container>
            </div>
            <div className="row mt-3 justify-content-center">
                <p style={{textAlign:"center",fontSize:"1.5em"}}>
                    Filters
                </p>
            </div>
            <div className="row mt-3 justify-content-center">
                <div className="col-sm-4 col-md-4 col-lg-3">
                    {/*<div>
                        {
                            this.state.categories.map(item => (
                                <Fragment key={item.ID}>
                                <label>
                                    <Checkbox name={item.Text} id={item.ID} checked={this.state.checkedCategories.get(item.Text)} onChange={this.handleCategoryChange} />
                                    {" "+item.Text}
                                </label>
                                <br/>
                                </Fragment>
                            ))
                        }
                    </div>*/}
                    <div style={{marginBottom:10}}>
                    <Multiselect
                        options={this.state.categories} // Options to display in the dropdown
                        placeholder="Select Categories"
                        ref={this.multiselectRef}
                        selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                        onSelect={this.onSelect} // Function will trigger on select event
                        onRemove={this.onSelect} // Function will trigger on remove event
                        displayValue="Text" // Property name to display in the dropdown options
                    />
                    </div>
                </div>
                <div className="col-sm-4 col-md-4 col-lg-3">
                    <input
                        type="text"
                        pattern="[0-9]*"
                        className="input"
                        value={this.state.query.filters.age}
                        onChange={this.onFiltersChange}
                        name="age"
                        min="1"
                        placeholder="Age"
                        style={{marginBottom:10}}
                    />
                    <input
                        type="text"
                        pattern="[0-9]*"
                        className="input"
                        value={this.state.query.filters.cost}
                        onChange={this.onFiltersChange}
                        name="cost"
                        min="1"
                        placeholder="($)Maximum Cost"
                        style={{marginBottom:10}}
                    />
                    <select name="month" onChange={this.onFiltersChange} value={this.state.query.filters.month} className="input">
                        <option va="0">Month</option>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </select>
                </div>
            </div>

            <div className="row mt-3 d-flex justify-content-center">
                <div className="mr-2">
                    <Button
                    text="Search"
                    type="submit"
                    onClick={this.onSubmit}
                    buttonStyle="btn--default"
                    icon="search"
                    ></Button>
                </div>
                <div className="ml-2">
                    <Button
                    text="Reset"
                    type="button"
                    onClick={this.searchReset}
                    buttonStyle="btn--default"
                    icon="search"
                    ></Button>
                </div>
            </div>

            <div className="d-flex">
                <ul className="list-inline mx-auto justify-content-center" style={{listStyleType:"none",width:950,marginTop:15,textAlign:"center"}}> 
                        {
                            this.state.categories.map(item => (
                                <li className="list-inline-item" style={{paddingRight:0}} key={item.ID} onClick={()=>this.onCategoryClick(item.ID)}>
                                    <div className={styles['camp-category-card-container']}>
                                        <img src={`/searchimages/${item.Text}Thumbnail.png`} className={styles['camp-category-card-image']} />
                                        <p className={styles['camp-category-card-text']}>{item.Text}</p>
                                    </div>
                                </li>
                            ))
                        }
                </ul>
            </div>
      
            {/*<div className={styles['header-text']}>
              <h1>Search Results</h1>
            </div>
            <div className={styles['gray-container']}>
              <Container>
                <br />
                <Row>
                    {this.state.searchResults.length==0 ? (
                        <h2 style={{ textAlign: 'center' }}>No Results Found</h2>
                    ):this.renderResults()}
                        
                </Row>
                <br />
              </Container>
                    </div>*/}
          </Fragment>
        );
    }
}

export default Search;


import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { isLogin } from '../../utils/tokenUtils';

const PrivateRoute = ({component: Component,...rest}) => {
  return (
    <Route
      {...rest}
      render={props =>
        !isLogin() ? (
          <Redirect to='/host/login' />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PrivateRoute;

/*const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);*/

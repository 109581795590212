import React, { Fragment } from 'react';
import {Link} from 'react-router-dom';
import HostService from "../../services/hostService"
import styles from '../common//Landing.module.css';
import Button from '../common/Button';
import queryString from 'query-string';

class CampHostPaywall extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            hasError:false,
            errorMessage:""
        }
    }

    componentDidMount(){
        //Enable subscription
        let params = queryString.parse(this.props.location.search);
        let sessionId = params.session_id;
        //console.log(params.session_id);
        HostService.enableSubscription(sessionId).then((result)=>{
            console.log(result);
        },error=>{
          console.log(error.response);
        });

    }

    render(){
        return (
            <Fragment>
            <header className={styles.hero} style={{minHeight:400}}></header>
        
            <div className={styles['form-container']}>
                    <h1 className={styles['header-text']}>You're Subscribed!</h1>
                    <p style={{ textAlign: 'center', fontSize: '20px'}}>
                        Thanks for subscribing. You can now access all the host features of Summertymes. Use 
                        the button below to continue to your dashboard. 
                    </p>
            
                    <div className="row">
                    <div className="mx-auto text-center p-3">
                        <Link to="/host/dashboard">
                            <Button
                                text="Continue to Dashboard"
                                type="submit"
                                onClick={() => {}}
                                buttonStyle="btn--large"
                                icon="none"
                            ></Button>
                        </Link>
                    </div>
                    </div>
            </div>
            </Fragment>
        );
    }

};

export default CampHostPaywall;

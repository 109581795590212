import React, { Fragment } from 'react';
import styles from './Landing.module.css';

const FAQ = () => {
  return (
    <Fragment>
      <header className={styles.hero} style={{minHeight:400}}></header>
      <div className={styles['form-container']}>
        <h1 className={styles['header-text']}>F.A.Q.</h1>

          <br/>

          <h3>As a Camp Host, why should I sign up for Summertymes?</h3>          
          <p>Running a camp or other activities is an ongoing challenging process. One of the most difficult aspects is the worry if the estimated number of campers will sign up, or if certain offerings need to be cancelled. Summertymes aims to bring a bit more certainty to you by spreading the word and allowing more campers to sing up for your camps. This allows you to plan ahead- should you reduce the number of offerings, or maybe you should expand?</p>
          <p>The key benefit for you is access to a vast marketing network that spreads the word for you. Being part of Summertymes will multiple the number of campers you will attract. </p>

          <br/>
            
          <h3>As a Camp Host, how do I create a profile?</h3>          
          <p>Easy! Create your Summertymes account first. Once created, log in to get to your dashboard. From here, you can create your Camp Profile - an overview of what it is all about, contact information and more.</p>
          <p>Once the Profile is approved, you are able to create one or more offerings for your camp, which includes the dates, times, locations, number of open spots and cost.</p>
          <p>The Profile and Offerings are then added for Campers to find and sign up for.</p>

          <br/>
          
          <h3>As a Camper, why should I sign up for Summertymes?</h3>
          <p>I am sure you know how difficult it is to find and manage camps for your children. Many amazing camps do not show up on search engines, but rather through word-of-mouth from other parents, followed by spreadsheets and google docs shared amongst parents to manage the already busy live. We certainly went through this. </p>
          <p>Summertymes was created to make it easier to find, sign up and manage camps and activities. By having a single application, we are hoping to make your life just a bit easier, while giving your campers the time they deserve – to learn and grow.</p>

          <br/>
          
          <h3>How much does it cost?</h3>
          <p>Please see our pricing page for details.</p>

          <br/>
          
          <h3>How do you ensure safety?</h3>
          <p>Each Camp Host who creates an organization on Summertymes automatically goes through a background check to ensure the safety of the campers. In addition, constant monitoring of the profiles and a review process from real campers ensures ongoing safety. </p>

      </div>
    </Fragment>
  );
};

export default FAQ;

import axios from 'axios';

const API_URL =
process.env.NODE_ENV === 'production'
  ? 'https://summertymes-api.herokuapp.com'
  : 'http://localhost:3000';

const config = {
    headers: {
        'Content-Type': 'application/json'
    }
};

class AuthService{
    async login(email, password) {
        const response = await axios
            .post(`${API_URL}/auth/login`, {
                email,
                password
            }, config);
        return response;
    }

    async regenHost(email){
        const response = await axios
        .post(`${API_URL}/auth/host/regen`, {
            email
        }, config);
        return response;
    }

    async loginCamper(email, password) {
        const response = await axios
            .post(`${API_URL}/camper/auth/login`, {
                email,
                password
            }, config);
        return response;
    }

    async checkPassword(password){
        const response = await axios.post(`${API_URL}/api/user/passcheck`, {password}, config);
        return response;
    }

    async updatePassword(password){
        const response = await axios.put(`${API_URL}/api/user/password`, {password}, config);
        return response;
    }

    async register(firstName, lastName, email, password) {
        const response = await axios.post(`${API_URL}/auth/register`, {
          firstName,
          lastName,
          email,
          password,
          userType:'host'
        }, config);
        return response;
    }

    async registerCamper(firstName, lastName, email, password, phone){
        const response = await axios.post(`${API_URL}/camper/auth/register`, {
            firstName,
            lastName,
            email,
            phone,
            password,
            userType:'camper'
          }, config);
          return response;
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('token'));;
    }
}

export default new AuthService();
import React, {Component, Fragment} from 'react';
import styles from './CampHost.module.css';
import PaymentList from './PaymentList'
import CamperService from '../../services/camperService';
import {Link} from 'react-router-dom';
import {logout} from '../../utils/tokenUtils';
import Button from '../common/Button';
import { Modal, Row, Col } from 'react-bootstrap';
import DatePicker from "react-datepicker";

class ManageCards extends Component{
    constructor(props){
        super(props);
        this.onBack = this.onBack.bind(this);
        this.drawModalForm = this.drawModalForm.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onChangeInput = this.onChangeInput.bind(this);

        this.state = {
            cards:[],
            showModal:false,
            firstName:"",
            lastName:"",
            cardNumber:"",
            expDate:"",
            expYear:"",
            cvc:"",
            hasModalError:false,
            modalErrorMessaage:""
        };
    }

    onBack(){
        this.props.history.goBack();
    }

    verifyModalForm(){
        let ignore = ["modalErrorMessaage","hasModalError","cards","showModal"];
        for (const property in this.state) {
            if(!ignore.includes(property)){
                //console.log(`${property}: ${this.state[property]}`);
                if(this.state[property]==""||this.state[property]==null){
                    console.log("offender");
                    console.log(property);
                    return false;
                }
            }
        }
        return true;
    }

    handleSubmit(e){
        e.preventDefault();

        this.setState({
            hasModalError:false,
            modalErrorMessaage:""
        });

        if(!this.verifyModalForm()){
            this.setState({
                hasModalError:true,
                modalErrorMessaage:"Please fill in all fields"
            });
        }else{
            /*CamperService.addCamperKid(this.state.firstName, this.state.lastName, this.state.gender, this.state.birthday, this.state.medical, this.state.shirtSize).then((response)=>{
                this.setState({
                    firstName:"",
                    lastName:"",
                    birthday:"",
                    medical:"",
                    shirtSize:"small",
                    gender:"male",
                    hasModalError:false,
                    modalErrorMessaage:""
                });
                this.toggleModal();
                CamperService.getCampers().then((result)=>{
                    console.log(result.data);
                    this.setState({campers:result.data});
                },error=>{
                    if(error.response.data.message=="Failed to authenticate token."){
                        logout();
                    }
                    console.log(error.response);
                });
                },(error)=>{
                    console.log(error.response);
            });*/
        }
    }

    onChangeInput(e) {
        this.setState({
          [e.target.name]: e.target.value
        });
        console.log(e.target.value);
    }

    handleBirthdayChange(date){
        const fullDate = new Date(date);
        const year = fullDate.getFullYear();
        const month = (fullDate.getMonth()+1) < 10 ? `0${fullDate.getMonth()+1}` : fullDate.getMonth()+1;
        const day = (fullDate.getDate()+1) < 10 ? `0${fullDate.getDate()+1}` : fullDate.getDate()+1;
        const formattedDate = `${year}-${month}-${day}`;
        this.setState({birthday:new Date(formattedDate)});
    }

    toggleModal(){
        if(this.state.showModal===false){
            this.setState({showModal:true});
        }else{
            this.setState({showModal:false});
        }
        console.log(this.state.showModal);
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value }, ()=>{console.log(this.state)});
    }

    drawModalForm(){
        return(
          <Modal
            show={this.state.showModal}
            onHide={this.toggleModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                New Card
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <Row>
                  <Col className="col-sm-12 col-md-6">
                    <label htmlFor="firstName">First Name</label>
                    <input
                      type="text"
                      id="firstName"
                      className="input"
                      required
                      name="firstName"
                      value={this.state.firstName}
                      onChange={this.onChangeInput}
                    />
                  </Col>
                  <Col className="col-sm-12 col-md-6">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                      type="text"
                      id="lastName"
                      className="input"
                      required
                      name="lastName"
                      value={this.state.lastName}
                      onChange={this.onChangeInput}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="col-sm-12">
                    <label htmlFor="cardNumber">Card Number</label>
                    <input
                      type="text"
                      id="cardNumber"
                      className="input"
                      required
                      name="cardNumber"
                      value={this.state.cardNumber}
                      onChange={this.onChangeInput}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className='col-sm-12 col-md-4'>
                    <label htmlFor="expDate">Exp Day</label>
                    <input
                      type="text"
                      id="expDate"
                      className="input"
                      required
                      name="expDate"
                      value={this.state.expDate}
                      onChange={this.onChangeInput}
                    />
                  </Col>
                  <Col className='col-sm-12 col-md-4'>
                    <label htmlFor="expYear">Exp Year</label>
                    <input
                      type="text"
                      id="expYear"
                      className="input"
                      required
                      name="expYear"
                      value={this.state.expYear}
                      onChange={this.onChangeInput}
                    />
                  </Col>
                  <Col className='col-sm-12 col-md-4'>
                    <label htmlFor="cvc">CVC</label>
                    <input
                      type="text"
                      id="cvc"
                      className="input"
                      required
                      name="cvc"
                      value={this.state.cvc}
                      onChange={this.onChangeInput}
                    />
                  </Col>
                </Row>
              </div>
              <div>
                    {this.state.hasModalError ?
                    <p className="alert alert-danger">{this.state.modalErrorMessaage}</p>
                    : ""}
                </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                text="Add Card"
                type="submit"
                onClick={(e) => { this.handleSubmit(e) }}
                buttonStyle="btn--default"
                icon="none"
              ></Button>
            </Modal.Footer>
          </Modal>
        )
    };

    componentDidMount(){
        /*CamperService.getCampers().then((result)=>{
            this.setState({campers:result.data});
            console.log(result.data);
        },error=>{
            if(error.response.data.message=="Failed to authenticate token."){
                logout();
            }
            console.log(error.response);
        });*/
        this.setState({
            cards:[
                {
                    Number:"8475",
                    Id: 1
                },
                {
                    Number:"9987",
                    Id: 2
                },
                {
                    Number:"2245",
                    Id:3
                }
            ]
        })
    }

    render(){
        return(
            <Fragment>
                {this.drawModalForm()}
                <div className={styles['header']}>
                    <div className="row">
                        <div className='col-sm-5 col-md-5'>
                            <h3 style={{cursor:"pointer"}} className={styles['form-text']} onClick={this.onBack}>
                                <i className="fas fa-chevron-circle-left"></i> Back
                            </h3>
                        </div>
                        <div className='col-sm-2 col-md-2'>
                            <h1 className={styles['form-text']}>MY CARDS</h1>
                        </div>
                        <div className='col-sm-4 col-md-4' onClick={this.toggleModal}>
                            <h1 style={{cursor:'pointer'}} className={styles['form-text']}>+ New Card</h1>
                        </div>                      
                    </div>
                </div>

                <div className={styles['gray-container']}>
                    {this.state.cards && this.state.cards.length !== 0 ? (
                    <div className={styles['mycamps']} style={{paddingBottom:35}}>
                        <PaymentList cards={this.state.cards}/>
                    </div>
                    ) : (
                        <h2 className={styles['nocamps']}>No Cards Added Yet</h2>
                    )}
                </div>
            </Fragment>
        )
    }
}

export default ManageCards;
import React from 'react';
import { Link } from 'react-router-dom';
//import CampHostOrgCard from './CampHostOrgCard';
import styles from './CampHost.module.css';
import Button from "../common/Button";
import swimming from '../search/images/swimming.jpg';

const RelatedCampsList = (props) =>{
    /*return props.camps.map(camp => {
        return (
            <Link to={`/camps/${camp.ID}`} key={camp.ID}>
                <div className={styles['my-camp-card-container']}>
                    <img src={camp.Thumbnail} alt="Camp Image" className={styles['my-camp-card-image']} />
                    <p className={styles['my-camp-card-text']}>{camp.Name}</p>
                </div>
            </Link>
        );
      });*/
        return props.camps.map(res=>(
            <div className="row d-flex justify-content-center" key={res.ID} style={{marginBottom:20}}>
                <div className="col-sm-3 col-md-2 col-lg-2" style={{marginRight:10}}>
                    <img width="200" height="150" src={res.Thumbnail} style={{marginBottom:10}}/>
                    <Link to={`/camps/${res.ID}`}>
                        <Button
                            text="LEARN MORE"
                            type="button"
                            buttonStyle="btn--default"
                            widthValue="200px"
                        ></Button>
                    </Link>
                </div>
                <div className="col-sm-8 col-md-6 col-lg-6">
                    <div className={styles['camp-profile-primary-info']}>
                        <div className={styles.campname}>
                            <span className={styles['camp-profile-header-text']}>
                            CAMP NAME:
                            </span>
                        </div>
                        <div className={styles.campnameinfo}>
                            {res.Name}
                        </div>
                        <div className={styles.camphost}>
                            <span className={styles['camp-profile-header-text']}>
                            LOCATION:
                            </span>
                        </div>
                        <div className={styles.camphostinfo}>
                            <span>{res.City}</span>,<span>{res.State}</span>
                        </div>
                        <div className={styles.address}>
                            <span className={styles['camp-profile-header-text']}>
                            DATES:
                            </span>
                        </div>
                        <div className={styles.addressinfo}>
                        {res.StartDate == null ? 
                            <span>Year Round</span>
                            : <span>Camp runs {this.state.monthNames[new Date(res.StartDate).getMonth()]} {new Date(res.StartDate).getDate()}  - {this.state.monthNames[new Date(res.EndDate).getMonth()]} {new Date(res.EndDate).getDate()} </span>}                                    
                        </div>
                        <div className={styles.dates}>
                            <span className={styles['camp-profile-header-text']}>
                            Summary:
                            </span>
                        </div>
                        <div className={styles.datesinfo}>
                            Summary Text Summary Text Summary Text Summ
                        </div>
                    </div>
                </div>
            </div>  
        ));
}

export default RelatedCampsList;
import React, {Component, Fragment} from 'react'
import { StripeProvider, Elements } from 'react-stripe-elements'
import CheckoutForm from './CheckoutForm';
import styles from './Camp.module.css';
import CamperService from '../../services/camperService';
import {logout} from '../../utils/tokenUtils';

class PurchaseCamp extends Component{
    constructor(props){
        super(props);

        this.onBack = this.onBack.bind(this); 

        this.state={
            price:100,
            campName:"{{CampName}}",
            campers:[
                /*{
                    Id:1,
                    FirstName:"Tim"
                },
                {
                    Id:2,
                    FirstName:"Mike"
                }*/
            ]
        };
    }

    onBack(){
        this.props.history.goBack();
    }

    componentDidMount(){
        CamperService.getCampers().then((result)=>{
            this.setState({campers:result.data});
            console.log(result.data);
        },error=>{
            if(error.response.data.message=="Failed to authenticate token."){
                logout();
            }
            console.log(error.response);
        });
    }

    render(){
        return(
            <Fragment>
                <div className="container">
                    <div className={styles['header']}>
                        <div className="row">
                            <div className='col-sm-3'>
                                <h3 style={{cursor:'pointer'}} className={styles['form-text']} onClick={this.onBack}>
                                    <i className="fas fa-chevron-circle-left"></i> Back
                                </h3>
                            </div>               
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-sm-12'>
                            <StripeProvider apiKey="pk_test_CnC6w4AezqYLiT9w8KSk04YY00tWgmWVEi">
                                <Elements>
                                    <CheckoutForm campers={this.state.campers} price={this.state.price} history={this.props.history} campName={this.state.campName} />
                                </Elements>
                            </StripeProvider>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default PurchaseCamp;
export const isLogin = () => {
    if (localStorage.jwt) {
        return true;
    }
    return false;
}

export const logout = () => {
    localStorage.removeItem("jwt");
    localStorage.removeItem("userType");
    window.location.reload();
}
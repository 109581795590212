import React, {Component, Fragment} from 'react';
import Button from '../common/Button';
import DataService from '../../services/dataService';

class HostForgotPassword extends Component{
    constructor(props){
        super(props);

        this.onChangeInput = this.onChangeInput.bind(this);
        this.onResetPassword = this.onResetPassword.bind(this);

        this.state = {
            email:"",
            linkSent:false,
            hasError:false,
            errorMessage:""
        };
    }

    onChangeInput(e){
        this.setState({[e.target.name]: e.target.value});
    }

    onResetPassword(){
        this.setState({
            hasError:false,
            errorMessage:""
        });
        
        if(this.state.email!="" && this.state.email!=null){
            DataService.resetPassword(this.state.email).then((response)=>{
                this.setState({
                    linkSent:true
                })
            },error=>{
                console.log(error.response);
            });
        }else{
            this.setState({
                errorMessage:"Email is required",
                hasError:true
            })
        }
    }

    render(){
        if(this.state.linkSent){
            return(
                <div className="container" style={{marginTop:30}}>

                    <div className="row justify-content-center">
                        <div className="col-sm-6" style={{textAlign:"center"}}>
                            <p style={{fontSize:14,fontWeight:"bold"}}>Request submitted! If the email address you submitted is on file, you'll receive an email with a link to reset your password.</p>
                        </div>
                    </div>
                </div>
            )
        }
        return(
            <div className="container" style={{marginTop:30}}>
                <div>
                {this.state.hasError ?
                <Fragment><p className="alert alert-danger" style={{marginTop:30}}>{this.state.errorMessage}</p><br/></Fragment>
                : ""}
                </div>
                <div className="row justify-content-center">
                    <div className="col-sm-6" style={{textAlign:"center"}}>
                        <p style={{fontSize:14,fontWeight:"bold"}}>Enter your email below to receive a password reset link:</p>
                        <input
                            type="text"
                            id="email"
                            className="input"
                            required
                            name="email"
                            placeholder="Email"
                            value={this.state.email}
                            onChange={this.onChangeInput}
                        />
                    </div>
                </div>
                <div className="row" style={{marginBottom:15}}>
                    <div className="mx-auto text-center p-3">
                        <Button
                            disabled={this.state.imageSaving}
                            text="Reset Password"
                            type="submit"
                            onClick={this.onResetPassword}
                            buttonStyle="btn--default"
                            icon="none"
                        ></Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default HostForgotPassword;
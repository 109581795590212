import axios from 'axios';

const API_URL =
process.env.NODE_ENV === 'production'
  ? 'https://summertymes-api.herokuapp.com'
  : 'http://localhost:3000';

const config = {
    headers: {
        'Content-Type': 'application/json'
    }
};

class AdminService{
    async respondToOrgApprovalRequest(orgApprovalResponse) {
        const response = await axios.post(`${API_URL}/api/admin/org/approvalrequestresponse`, {orgApprovalResponse}, config);
        return response;
    }
}

export default new AdminService();
import React, { Fragment } from 'react';
import {Link} from 'react-router-dom';
import EcomService from "../../services/ecomService"
import styles from '../common//Landing.module.css';
import Button from '../common/Button';

class CampHostPaywall extends React.Component{

    handleSubscribe(e){
        e.preventDefault();
        const priceId = "price_1M7SAOEeQhBERpFpuCJgUk4W";
        //const priceId = "price_1MBs6WEeQhBERpFpZUjB2xX2";
        const res = EcomService.createSubscription(priceId)
        .then(response=>{
            console.log(response);
            const serverResponse = response.data;
            window.location.href = serverResponse.url;
        },error=>{
            const errResponse = error.response;
            console.log(errResponse);
            //this.setState({errorMessage: errResponse.message});
            //this.setState({hasError: true});
        });
    }

    constructor(props){
        super(props);

        this.handleSubscribe = this.handleSubscribe.bind(this);
        //this.onChangeInput = this.onChangeInput.bind(this);

        this.state = {
            hasError:false,
            errorMessage:""
        }
    }

    render(){
        return (
            <Fragment>
            <header className={styles.hero} style={{minHeight:400}}></header>
        
            <div className={styles['form-container']}>
                <form onSubmit={e => this.handleSubscribe(e)}>
                    <h1 className={styles['header-text']}>Subscribe Today</h1>
                    <p style={{ textAlign: 'center', fontSize: '20px'}}>
                    Summertymes requires a subscription for access. 
                    </p>
            
                    <div className="row">
                    <div className="mx-auto text-center p-3">
                        <Button
                            text="Purchase Subscription"
                            type="submit"
                            onClick={() => {}}
                            buttonStyle="btn--large"
                            icon="none"
                        ></Button>
                    </div>
                    </div>
                </form>
            </div>
            </Fragment>
        );
    }

};

export default CampHostPaywall;

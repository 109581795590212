import axios from 'axios';

const API_URL =
process.env.NODE_ENV === 'production'
  ? 'https://summertymes-api.herokuapp.com'
  : 'http://localhost:3000';

const config = {
    headers: {
        'Content-Type': 'application/json'
    }
};

class SearchService{
    async search(query) {
        console.log(query);
        const response = await axios.post(`${API_URL}/api/search`,query);
        return response;
    }
}

export default new SearchService();
import React, {Fragment} from 'react';
import styles from './CampHost.module.css';
import HostService from '../../services/hostService';
import DataService from '../../services/dataService';
import CampList from './CampList';
import States from '../common/States';
import {logout} from '../../utils/tokenUtils';
import { Modal, Row, Col } from 'react-bootstrap';
import Button from '../common/Button';
import { Multiselect } from 'multiselect-react-dropdown';
import Cleave from 'cleave.js/react';
import DatePicker from "react-datepicker";
import Spinner from "../common/Spinner";
 
import "react-datepicker/dist/react-datepicker.css";
import hostService from '../../services/hostService';
//import swimming from '../common/images/swimming.jpg';

class Orgs extends React.Component{
    constructor(props){
        super(props);

        this.toggleModal = this.toggleModal.bind(this);
        this.onChangeInput = this.onChangeInput.bind(this);
        this.onChangeDropdownInput = this.onChangeDropdownInput.bind(this);
        this.handleSubmitCamp = this.handleSubmitCamp.bind(this);
        this.handleFetchOrgInfo = this.handleFetchOrgInfo.bind(this);
        this.drawModalForm = this.drawModalForm.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.onChangeCheck = this.onChangeCheck.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.multiselectRef = React.createRef();
        this.onSelect = this.onSelect.bind(this);
        this.onBack = this.onBack.bind(this);

        this.state = {
            locationDisabled:false,
            dateDisabled:false,
            camps:[],
            categories:[],
            campCategories:[],
            checkedCategories:new Map(),
            showModal:false,
            saving:false,
            hasError:false,
            errorMessage:"",
            hasModalError:false,
            modalErrorMessage:"",
            name:"",
            address:"",
            city:"",
            state:"",
            zip:"",
            phone:"",
            email:"",
            startDate:"",
            endDate:"",
            minAge:"",
            maxAge:"",
            spotsAvailable:"",
            allowsWaitlist:false,
            thumbnail:null,
            campImage1:null,
            campImage2:null,
            campImage3:null
        }
    }
    verifyModalForm(){
        let ignore = [];
        ignore = ["modalErrorMessaage","locationDisabled","categories","checkedCategories","campCategories","saving","email","camps","showModal","hasError","errorMessage","hasModalError","modalErrorMessage","campImage1","campImage2","campImage3","isYearRound","allowsWaitlist","dateDisabled"];
        if(this.state.campCategories.includes(26)){
            //ignore address/city/state/zip if virtual
            ignore = ["modalErrorMessaage","dateDisabled","locationDisabled","categories","checkedCategories","campCategories","saving","email","camps","showModal","hasError","errorMessage","hasModalError","modalErrorMessage","campImage1","campImage2","campImage3","isYearRound","allowsWaitlist","address","city","state","zip"];
        }
        if(this.state.campCategories.includes(22)){
            ignore = ["modalErrorMessaage","dateDisabled","locationDisabled","categories","checkedCategories","campCategories","saving","email","camps","showModal","hasError","errorMessage","hasModalError","modalErrorMessage","campImage1","campImage2","campImage3","isYearRound","allowsWaitlist","startDate","endDate"];
        }
        if(this.state.campCategories.includes(22)&&this.state.campCategories.includes(26)){
            ignore = ["modalErrorMessaage","dateDisabled","locationDisabled","categories","checkedCategories","campCategories","saving","email","camps","showModal","hasError","errorMessage","hasModalError","modalErrorMessage","campImage1","campImage2","campImage3","isYearRound","allowsWaitlist","address","city","state","zip","startDate","endDate"];
        }
        for (const property in this.state) {
            if(!ignore.includes(property)){
                //console.log(`${property}: ${this.state[property]}`);
                if(this.state[property]==""||this.state[property]==null){
                    console.log("offender");
                    console.log(property);
                    return false;
                }
            }
        }
        if(this.state.campCategories.length==0){
            return false;
        }
        return true;
    }
    onChangeInput(e) {
        /*if(e.target.name=="cost"){
            e.target.setAttribute("value",e.target.value); 
        }
        const name = e.target.name;
        if(name=="age"||name=="cost"){
            const number = (e.target.validity.valid) ? e.target.value : this.state[name];
            this.setState({
                [e.target.name]: number
            });
        }else{
            this.setState({
                [e.target.name]: e.target.value
            });
        }*/
        this.setState({
            [e.target.name]: e.target.value
        },()=>{console.log(this.state)});
    }
    onChangeCheck(e){
        this.setState({[e.target.name]: e.target.checked},()=>{console.log(this.state)});
    }
    onSelect(){
        const sels = this.multiselectRef.current.getSelectedItems();
        this.setState({campCategories:[]},()=>{
            const tempcat = this.state.campCategories;
            for (let i = 0; i < sels.length; i++) {
                tempcat.push(sels[i].ID);
            }
            if(tempcat.includes(26)){
                this.setState({
                    locationDisabled:true,
                    state:"",
                    city:"",
                    address:"",
                    zip:""
                })
            }else if(tempcat.includes(22)){
                this.setState({
                    dateDisabled:true,
                    startDate:"",
                    endDate:""
                })
            }
            else{
                this.setState({
                    locationDisabled:false,
                    dateDisabled:false
                })
            }
            this.setState({campCategories:tempcat});
        });
    }
    handleCategoryChange(e) {
        const item = e.target.name;
        const isChecked = e.target.checked;
        this.setState(prevState => ({ checkedCategories: prevState.checkedCategories.set(item, isChecked) }),()=>{
            this.setState({campCategories:[]},()=>{
                const tempcat = this.state.campCategories;
                this.state.checkedCategories.forEach((value, key, map)=>{
                    if(value==true){
                        for (let i=0; i < this.state.categories.length; i++) {
                            if (this.state.categories[i].Text === key) {
                                tempcat.push(this.state.categories[i].ID);
                            }
                        }
                    }
                })
                this.setState({campCategories:tempcat});
            });
        });
    }
    handleStartDateChange(date){
        const fullDate = new Date(date);
        const year = fullDate.getFullYear();
        const month = (fullDate.getMonth()+1) < 10 ? `0${fullDate.getMonth()+1}` : fullDate.getMonth()+1;
        const day = (fullDate.getDate()+1) < 10 ? `0${fullDate.getDate()+1}` : fullDate.getDate()+1;
        const formattedDate = `${year}-${month}-${day}`;
        this.setState({startDate:new Date(formattedDate)});
    }
    handleEndDateChange(date){
        const fullDate = new Date(date);
        const year = fullDate.getFullYear();
        const month = (fullDate.getMonth()+1) < 10 ? `0${fullDate.getMonth()+1}` : fullDate.getMonth()+1;
        const day = (fullDate.getDate()+1) < 10 ? `0${fullDate.getDate()+1}` : fullDate.getDate()+1;
        const formattedDate = `${year}-${month}-${day}`;
        this.setState({endDate:new Date(formattedDate)});
    }
    onChangeDropdownInput(e){
        this.setState({state: e.target.value});
        console.log(e.target.value);
    }
    handleFileUpload = (e) => {
        console.log(e.target.files);
        this.setState({[e.target.name]: e.target.files});
    }
    /*handleMultiFileUpload = (e) => {
        this.setState({ campImages: [...this.state.files, ...e.target.files] });
    }*/
    handleFetchOrgInfo(e){
        e.preventDefault();
        const { match: { params } } = this.props;
        const id = params.id;
        HostService.getOrg(id).then((response)=>{
            let org = response.data;
            if(this.state.campCategories.includes(26)){
                //virtual camp, just pull phone
                this.setState({
                    phone:org.Phone
                });
            }else{
                this.setState({
                    address:org.Address,
                    city:org.City,
                    state:org.State,
                    zip:org.Zip,
                    phone:org.Phone
                });
            }
        },error=>{
            console.log(error.response);
        });
    }
    handleSubmitCamp(e){
        e.preventDefault();
        //Object.keys(this.state).map(i => {console.log(this.state[i])});
        //clear errors
        this.setState({
            hasModalError:false,
            modalErrorMessaage:""
        });

        if(!this.verifyModalForm()){
            this.setState({
                hasModalError:true,
                modalErrorMessaage:"Please fill in all required(*) fields, Select at least one Camp Category"
            });
        }else if(parseInt(this.state.minAge) > parseInt(this.state.maxAge)){     
            this.setState({
                hasModalError:true,
                modalErrorMessaage:"Minimum age cannot be greater than maximum age"
            });
        }
        else{
            //check if exists before saving
            HostService.checkForDuplicateCamp(this.state.name.toLowerCase(), this.state.phone).then((result)=>{

                if(result.data.length>0){
                    this.setState({
                        hasModalError:true,
                        modalErrorMessaage:"A camp with this information already exists."
                    });
                }else{
                    this.toggleModal();
                    this.setState({
                        hasModalError:false,
                        saving:true,
                        modalErrorMessaage:"",
                    });
        
                    const formData = new FormData();
                    formData.append('thumbnail', this.state.thumbnail!=null ? this.state.thumbnail[0] : null);
                    formData.append('campImage1', this.state.campImage1!=null ? this.state.campImage1[0] : null);
                    formData.append('campImage2', this.state.campImage2!=null ? this.state.campImage2[0] : null);
                    formData.append('campImage3', this.state.campImage3!=null ? this.state.campImage3[0] : null);
                    formData.append('name', this.state.name);
        
                    if(this.state.campCategories.includes(26)){
                        formData.append('address', "Virtual");
                    }else{
                        formData.append('address', this.state.address);
                    }
                    
                    formData.append('city', this.state.city);
                    formData.append('state', this.state.state);
                    formData.append('zip', this.state.zip);
                    formData.append('phone', this.state.phone);
                    formData.append('email', this.state.email);
                    if(this.state.campCategories.includes(22)){
                        formData.append('startDate', null);
                        formData.append('endDate', null);
                    }else{
                        formData.append('startDate', this.state.startDate);
                        formData.append('endDate', this.state.endDate);
                    }
                    formData.append('minAge', this.state.minAge);
                    formData.append('maxAge', this.state.maxAge);
                    formData.append('cost', this.state.cost);
                    formData.append('spotsAvailable', this.state.spotsAvailable);
                    formData.append('allowsWaitlist', this.state.allowsWaitlist);
        
                    const currentOrgId = window.location.href.split('/')[5];
                    HostService.addCamp(formData,currentOrgId).then((response)=>{
                            //add any camp images
                            const campImages = response.data.campImages;
                            const campId = response.data.id;
                            for(let img in campImages){
                                console.log(campImages[img]+" -"+campId);
                                const imageURL = campImages[img];
                                (async()=>{
                                    await HostService.addCampImage(imageURL,campId,currentOrgId).then((res)=>{
        
                                    },error=>{
                                        console.log(error);
                                    })
                                })();
                            }
                            //add categories
                            for(let catId in this.state.campCategories){
                                (async()=>{
                                    await HostService.addCampCategory(this.state.campCategories[catId],campId,currentOrgId).then((res)=>{
        
                                    },error=>{
                                        console.log(error);
                                    })
                                })();
                            }
                            HostService.getCamps(window.location.href.split('/')[5]).then((result)=>{
                                console.log(result.data);
                                this.setState({camps:result.data});
                                this.setState({
                                    saving:false,
                                    //categories:[],
                                    campCategories:[],
                                    checkedCategories:new Map(),
                                    name:"",
                                    address:"",
                                    city:"",
                                    state:"",
                                    zip:"",
                                    phone:"",
                                    email:"",
                                    startDate:"",
                                    endDate:"",
                                    minAge:"",
                                    maxAge:"",
                                    cost:"",
                                    spotsAvailable:"",
                                    allowsWaitlist:false,
                                    thumbnail:null,
                                    campImage1:null,
                                    campImage2:null,
                                    campImage3:null
                                });
                            },error=>{
                                if(error.response.data.message=="Failed to authenticate token."){
                                    logout();
                                }
                                console.log(error.response);
                            });
                        },(error)=>{
            
                    });
                }
            },error=>{
                console.log(error.response);
            });
        }
    }
    drawModalForm(){
        return(
          <Modal
            show={this.state.showModal}
            onHide={this.toggleModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                New Camp
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {this.state.hasModalError ?
                    <p className="alert alert-danger">{this.state.modalErrorMessaage}</p>
                    : ""}
                </div>
              <div className="container">
                <Row>
                  <Col className="col-sm-12 col-md-12">
                    <label htmlFor="name">Camp Name*</label>
                    <input
                      type="text"
                      id="name"
                      className="input"
                      required
                      name="name"
                      value={this.state.name}
                      onChange={this.onChangeInput}
                    />
                  </Col>
                </Row>
                <hr/>
                <Row>
                    <Col className="col-sm-12 col-md-12">
                        <div>
                            <h4 style={{textDecoration:"underline",marginBottom:"10px"}}>Categories*</h4>
                            <Multiselect
                            options={this.state.categories} // Options to display in the dropdown
                            placeholder="Select Categories"
                            ref={this.multiselectRef}
                            selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                            onSelect={this.onSelect} // Function will trigger on select event
                            onRemove={this.onSelect} // Function will trigger on remove event
                            displayValue="Text" // Property name to display in the dropdown options
                            />
                        </div>
                    </Col>
                </Row>
                <hr/>
                <h4 style={{textDecoration:"underline",marginBottom:"10px"}}>Camp Location</h4>
                <span style={this.state.locationDisabled ? {display:"block",paddingBottom:10,fontWeight:"bold"} : {display:"none"}}>*Address, City, State, & Zip disabled for virtual camps*</span>
                <Row>
                  <Col className="col-sm-12 col-md-12">
                    <label htmlFor="address">Address*</label>
                    <input
                      type="text"
                      disabled = {(this.state.locationDisabled)? "disabled" : ""}
                      id="address"
                      className="input"
                      required
                      name="address"
                      value={this.state.address}
                      onChange={this.onChangeInput}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="col-sm-6 col-md-6">
                    <label htmlFor="city">City*</label>
                    <input
                      type="text"
                      disabled = {(this.state.locationDisabled)? "disabled" : ""}
                      id="city"
                      className="input"
                      required
                      name="city"
                      value={this.state.city}
                      onChange={this.onChangeInput}
                    />
                  </Col>
                  <Col className="col-sm-3 col-md-3">
                    <label htmlFor="city">State*</label>
                    <States stateValue={this.state.state} 
                        disabled = {(this.state.locationDisabled)? "disabled" : ""} 
                        changeFunction={this.onChangeDropdownInput} 
                    />
                  </Col>
                  <Col className="col-sm-3 col-md-3">
                    <label htmlFor="zip">Zip*</label>
                    <input
                      type="text"
                      disabled = {(this.state.locationDisabled)? "disabled" : ""}
                      id="zip"
                      className="input"
                      required
                      name="zip"
                      value={this.state.zip}
                      onChange={this.onChangeInput}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className='col-sm-6 col-md-6'>
                    <label htmlFor="phone">Phone*</label>
                    <Cleave
                      options={{ phone: true, phoneRegionCode: 'US', delimiter: '-' }}
                      value={this.state.phone}
                      onChange={this.onChangeInput}
                      className="input"
                      required
                      id="phone"
                      name="phone"
                    />
                  </Col>
                  <Col className='col-sm-6 col-md-6'>
                    <label htmlFor="email">Email</label>
                    <input
                      type="text"
                      id="email"
                      className="input"
                      required
                      name="email"
                      value={this.state.email}
                      onChange={this.onChangeInput}
                    />
                  </Col>
                </Row>
                <Button
                text="Use Org Info"
                type="submit"
                onClick={(e) => { this.handleFetchOrgInfo(e) }}
                buttonStyle="btn--default"
                icon="none"
              ></Button>
                <hr/>
                <h4 style={{textDecoration:"underline",marginBottom:"10px"}}>Camp Details</h4>
                <span style={this.state.dateDisabled ? {display:"block",paddingBottom:10,fontWeight:"bold"} : {display:"none"}}>*Start and End Dates disabled for year-round camps*</span>
                <Row>
                  <Col className='col-sm-6 col-md-6'>
                    <label htmlFor="startDate">Start Date*</label>
                    <DatePicker
                        disabled = {(this.state.dateDisabled)? "disabled" : ""} 
                        selected={this.state.startDate}
                        onChange={this.handleStartDateChange}
                        name="startDate"
                        className="input"
                        dateFormat="yyyy-MM-dd"
                    />
                  </Col>
                  <Col className='col-sm-6 col-md-6'>
                    <label htmlFor="endDate">End Date*</label><br/>
                    <DatePicker
                        disabled = {(this.state.dateDisabled)? "disabled" : ""} 
                        selected={this.state.endDate}
                        onChange={this.handleEndDateChange}
                        className="input"
                        dateFormat="yyyy-MM-dd"
                        name="endDate"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="col-sm-6 col-md-6">
                    <label htmlFor="cost">($)Camp Cost*</label>
                    <input
                      type="number"
                      step="1"
                      min="1"
                      id="cost"
                      className="input"
                      required
                      name="cost"
                      value={this.state.cost}
                      onChange={this.onChangeInput}
                    />
                  </Col>
                  <Col className='col-sm-6 col-md-6'>
                        <label htmlFor="spotsAvailable">Spots Available*</label>
                        <input
                        type="number"
                        step="1"
                        min="1"
                        id="spotsAvailable"
                        className="input"
                        required
                        name="spotsAvailable"
                        value={this.state.spotsAvailable}
                        onChange={this.onChangeInput}
                        />
                    </Col>
                </Row>
                <Row>
                  <Col className='col-sm-6 col-md-6'>
                    <label htmlFor="minAge">Mininium Age*</label>
                    <input
                      type="number"
                      min="1"
                      step="1"
                      id="minAge"
                      className="input"
                      required
                      name="minAge"
                      value={this.state.minAge}
                      onChange={this.onChangeInput}
                    />
                  </Col>
                  <Col className='col-sm-6 col-md-6'>
                    <label htmlFor="maxAge">Max Age*</label>
                    <input
                      type="number"
                      step="1"
                      min="1"
                      id="maxAge"
                      className="input"
                      required
                      name="maxAge"
                      value={this.state.maxAge}
                      onChange={this.onChangeInput}
                    />
                  </Col>
                </Row>
                <Row>
                    <Col className='col-sm-3 col-md-3'>
                        <label htmlFor="allowsWaitlist">Allows Waitlist</label>
                        <input
                        type="checkbox"
                        id="allowsWaitlist"
                        className="input"
                        required
                        name="allowsWaitlist"
                        checked={this.state.allowsWaitlist}
                        onChange={this.onChangeCheck}
                        style={{width:30}}
                        />
                    </Col>
                </Row>
                <hr/>
                <h4 style={{textDecoration:"underline",marginBottom:"10px"}}>Camp Schedule</h4>
                <Row>
                    <Col className="col-sm-3">
                        Sunday
                    </Col>
                    <Col className="col-sm-3">
                        Sunday
                    </Col>
                    <Col className="col-sm-3">
                        Sunday
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col className="col-sm-10 col-md-10">
                        <h4 style={{textDecoration:"underline",marginBottom:"10px"}}>Images</h4>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-sm-10 col-md-10">
                        <label htmlFor="thumbnail">Camp Thumbnail Image:*</label><br/>
                        <input name="thumbnail" label='upload file' type='file' onChange={this.handleFileUpload} />
                    </Col>
                </Row>
                <Row>
                    <Col className="col-sm-10 col-md-10">
                        <label htmlFor="campImage1">Camp Image:</label><br/>
                        <input name="campImage1" label='upload file' type='file' onChange={this.handleFileUpload} />
                    </Col>
                </Row>
                <Row>
                    <Col className="col-sm-10 col-md-10">
                        <label htmlFor="campImage2">Camp Image:</label><br/>
                        <input name="campImage2" label='upload file' type='file' onChange={this.handleFileUpload} />
                    </Col>
                </Row>
                <Row>
                    <Col className="col-sm-10 col-md-10">
                        <label htmlFor="campImage3">Camp Image:</label><br/>
                        <input name="campImage3" label='upload file' type='file' onChange={this.handleFileUpload} />
                    </Col>
                </Row>
              </div>
                <div>
                    {this.state.hasModalError ?
                    <p className="alert alert-danger">{this.state.modalErrorMessaage}</p>
                    : ""}
                </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                text="Add Camp"
                type="submit"
                onClick={(e) => { this.handleSubmitCamp(e) }}
                buttonStyle="btn--default"
                icon="none"
              ></Button>
            </Modal.Footer>
          </Modal>
        )
    };
    onBack(){
        this.props.history.goBack();
    }
    componentDidMount(){
        HostService.getCamps(window.location.href.split('/')[5]).then((result)=>{
            this.setState({camps:result.data});
            console.log(result.data);
        },error=>{
            if(error.response.data.message=="Failed to authenticate token."){
                logout();
            }
            console.log(error.response);
        });
        DataService.getCategories().then((result)=>{
            console.log(result.data);
            this.setState({categories:result.data});
        })
    }
    toggleModal(){
        if(this.state.showModal===false){
            this.setState({showModal:true});
        }else{
            this.setState({showModal:false});
        }
        console.log(this.state.showModal);
    }
    render(){
        return (
            <Fragment>
                {this.state.saving ? (
                    <Spinner/>
                ):""}
                {this.drawModalForm()}
                <div className={styles['header']}>
                    <div className="row">
                        <div className='col-sm-5 col-md-5'>
                            <h3 style={{cursor:'pointer'}} className={styles['form-text']} onClick={this.onBack}>
                                <i className="fas fa-chevron-circle-left"></i> Back
                            </h3>
                        </div>
                        <div className='col-sm-2 col-md-2'>
                            <h1 className={styles['form-text']}>MY CAMPS</h1>
                        </div>
                        <div className='col-sm-4 col-md-4' onClick={this.toggleModal}>
                            <h1 style={{cursor:'pointer'}} className={styles['form-text']}>+ New Camp</h1>
                        </div>                      
                    </div>
                </div>

            <div className={styles['gray-container']}>
                {this.state.camps && this.state.camps.length !== 0 ? (
                <div className={styles['mycamps']}>
                    <CampList camps={this.state.camps}/>
                </div>
                ) : (
                    <h2 className={styles['nocamps']}>No Camps Added Yet</h2>
                )}
            </div>
            </Fragment>
        );
    }
}

export default Orgs;
import React from 'react';
import { Link } from 'react-router-dom';
import CampHostOrgCard from './CampHostOrgCard';
import CampHostOrgPendingCard from './CampHostOrgPendingCard';
import swimming from '../search/images/swimming.jpg';

const OrgList = (props) =>{
    return props.orgs.map(org => {
        if(org.IsApproved == false){
          return(
            <CampHostOrgPendingCard
              image={org.Thumbnail ? org.Thumbnail : swimming}
              alt="org thumbnail"
              text={org.Name}
              key={org.ID}
            />
          )
        }
        return (
          <Link to={`/host/org/${org.ID}`} key={org.ID}>
            <CampHostOrgCard
              image={org.Thumbnail ? org.Thumbnail : swimming}
              alt="org thumbnail"
              text={org.Name}
              key={org.ID}
            />
          </Link>
        );
      });
}

export default OrgList;
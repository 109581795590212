import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import {isLogin, logout} from '../../utils/tokenUtils'
import styles from './Navbar.module.css';
import setAuthToken from '../../utils/setAuthToken';

import logo from './images/summertymeslogowhite.png';

if (localStorage.jwt) {
    //console.log(`token: ${localStorage.token}`);
    setAuthToken(localStorage.jwt);
}

const Navbar = () => {

    const getUserType = () =>{
        if(localStorage.userType){
            return localStorage.userType;
        }
        return "guest";
    }

    const authLinksCampHostLeftMenu = (
        <ul className={styles['left-menu']}>
            {getUserType()=="host" ?
                <li>
                    <Link to="/host/dashboard" className={styles['navigation-link']} href="#">
                        My Dashboard
                    </Link>
                </li>
            :
                <li>
                    <Link to="/camper/dashboard" className={styles['navigation-link']} href="#">
                        My Dashboard
                    </Link>
                </li>
            }
            <li>
            <Link to="/search" className={styles['navigation-link']} href="#">
                Search
            </Link>
            </li>
        </ul>
    );
    
    const authLinksCampHostRightMenu = (
        <ul className={styles['right-menu']}>
            {getUserType()=="host" ?
            <li>
                <Link to="/host/account" className={styles['navigation-link']} href="#">
                    Account Settings
                </Link>
            </li>
            :
            <li>
                <Link to="/camper/account" className={styles['navigation-link']} href="#">
                    Account Settings
                </Link>
            </li>
            }
            <li>
                <a onClick={logout} className={styles['navigation-link']} href="#">
                    <i className="fas fa-sign-out-alt"></i> Logout
                </a>
            </li>
        </ul>
    );

    const guestStatic = (
        <ul className={styles['left-menu']}>
            <li>
                <Link to="/pricing" className={styles['navigation-link']} href="#">
                    Pricing
                </Link>
            </li>
            <li>
                <Link to="/how-it-works" className={styles['navigation-link']} href="#">
                    How it Works
                </Link>
            </li>
            <li>
                <Link to="/faq" className={styles['navigation-link']} href="#">
                    FAQ
                </Link>
            </li>
        </ul>
    )
    
    const guestLinks = (
        <Fragment>
            <ul className={styles['right-menu']}>
            {/* <li>
            <Link className={styles['navigation-link']} to="/about">
                About
            </Link>
            </li> */}
            <li>
            <Link className={styles['navigation-link']} to="/search">
                Search Camps
            </Link>
            </li>
            <li className={styles.dropdown}>
            <span className={styles['navigation-link']}>Login</span>
            <div className={styles['dropdown-content']}>
                <span
                className={styles['dropdown-content-link']}>
                <Link to="/camper/login">CAMPER</Link>
                </span>
                <span className={styles['dropdown-content-link']}>
                <Link to="/host/login">CAMP HOST</Link>
                </span>
            </div>
            </li>
            <li className={styles.dropdown}>
            <span className={styles['navigation-link']}>Register</span>
            <div className={styles['dropdown-content']}>
                <span
                className={styles['dropdown-content-link']}>
                <Link to="/camper/register">CAMPER</Link>
                </span>
                <span className={styles['dropdown-content-link']}>
                <Link to="/host/register">CAMP HOST</Link>
                </span>
            </div>
            </li>
        </ul>
        </Fragment>
    );
    
    const guestDropdown = (
        <div className={styles.dropdown}>
            <div className={styles.hamburger}>&#9776;</div>
            <div className={styles['dropdown-content']}>
            <span className={styles['dropdown-content-link']}>
                <Link to="/search">SEARCH CAMPS</Link>
            </span>
            <span
                className={styles['dropdown-content-link']}>
                <Link to="/camper/login">CAMPER LOG IN</Link>
            </span>
            <span
                className={styles['dropdown-content-link']}>
                <Link to="/camper/register">CAMPER REGISTER</Link>
            </span>
            <span className={styles['dropdown-content-link']}>
                <Link to="/host/login">CAMP HOST LOG IN</Link>
            </span>
            <span className={styles['dropdown-content-link']}>
                <Link to="/host/register">CAMP HOST REGISTER</Link>
            </span>
            </div>
        </div>
    );

    const authLinksDropdown = (
        <div className={styles.dropdown}>
            <div className={styles.hamburger}>&#9776;</div>
            <div className={styles['dropdown-content']}>
            {getUserType()=="host" ?
                <span className={styles['dropdown-content-link']}>
                    <Link to="/host/dasboard" className={styles['navigation-link']} href="#">
                    My Dashboard
                    </Link>
                </span>
            :
                <span className={styles['dropdown-content-link']}>
                    <Link to="/camper/dashboard" className={styles['navigation-link']} href="#">
                    My Dashboard
                    </Link>
                </span>
            }
            <span className={styles['dropdown-content-link']}>
                <Link className={styles['navigation-link']} to="/search">SEARCH CAMPS</Link>
            </span>
            {getUserType()=="camper" ?
                <span className={styles['dropdown-content-link']}>
                    <Link
                    to="/camper/account"
                    className={styles['navigation-link']}
                    href="#"
                    >
                    Account Settings
                    </Link>
                </span>
            :
                <span className={styles['dropdown-content-link']}>
                    <Link
                    to="/host/account"
                    className={styles['navigation-link']}
                    href="#"
                    >
                    Account Settings
                    </Link>
                </span>
            }
            <span className={styles['dropdown-content-link']}>
                <a onClick={logout} className={styles['navigation-link']} href="#">
                <i className="fas fa-sign-out-alt"></i> Logout
                </a>
            </span>
            </div>
        </div>
    );

    return (
        <Fragment>
            <div className={styles['navbar']}>
            <nav className={styles['main-nav']}>
                <div className={styles['grid-item-one']}>
                    <Fragment>
                    {isLogin() ? authLinksCampHostLeftMenu : guestStatic}
                    </Fragment>
                </div>
                <div className={styles['grid-item-two']}>
                <Link to="/">
                    <img src={logo} alt="logo" className={styles.logo} />
                </Link>
                </div>
                <div className={styles['grid-item-three']}>
                    <Fragment>
                    {isLogin() ? authLinksDropdown : guestDropdown}
                    </Fragment>
                    <Fragment>
                    {isLogin() ? authLinksCampHostRightMenu : guestLinks}
                    </Fragment>
                </div>
            </nav>
            </div>
            <div className={styles['phantom-navbar']}></div>
        </Fragment>
    );
}

export default Navbar;
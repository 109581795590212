import React from 'react';
import { Link } from 'react-router-dom';
import CampHostCampCard from './CampHostCampCard';
//*link to edit*///host/org/${camp.CampOrganizationId}

const CampList = (props) =>{
    return props.camps.map(camp => {
        return (
          <Link to={`/host/camp/${camp.ID}`} key={camp.ID}>
            <CampHostCampCard
              image={camp.Thumbnail}
              alt="camp thumbnail"
              text={camp.Name}
              key={camp.ID}
            />
          </Link>
        );
      });
}

export default CampList;
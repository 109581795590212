import React from 'react';
import styles from './CampHost.module.css';

const CampHostOrgPendingCard = ({ image, alt, text }) => {
  return (
    <div style={{position:"relative"}} className={styles['my-camp-card-container']}>
      <img style={{opacity:".5"}} src={image} alt={alt} className={styles['my-camp-card-image']} />
      <p className={styles['my-camp-card-text']}>{text}</p>
      <div style={{position:"absolute", width:"100%", overflow:"hidden", backgroundColor:"white", textAlign:"center", left:"0", top:"120px"}}>
        <p style={{fontWeight:"bold", color:"red"}} className={styles['my-camp-card-text']}>PENDING REVIEW</p>
      </div>
    </div>
  );
};

export default CampHostOrgPendingCard;
import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import Button from '../common/Button';

class Dashboard extends Component{
    constructor(props){
        super(props);

    }

    render(){
        return(
            <div className="container">
                <div className="row" style={{marginTop:20,textAlign:"center"}}>
                    <div className="col-sm-12 col-md-4" style={{marginBottom:15}}>
                        <Link to={`/camper/campers`}>
                            <button style={{width:"100%",height:100,fontSize:15,fontWeight:"bold"}} className="btn btn-warning" type="button" onClick={this.onCampers}>
                                <i className="fa-3x fas fa-user-circle"></i><span style={{display:"block"}}>Manage Campers</span>
                            </button>
                        </Link>
                    </div>
                    <div className="col-sm-12 col-md-4" style={{marginBottom:15}}>
                        <Link to={'/camper/registrations'}>
                            <button style={{width:"100%",height:100,fontSize:15,fontWeight:"bold"}} className="btn btn-warning" type="button" onClick={this.onDelete}>
                                <i className="fa-3x fas fa-calendar-alt"></i><span style={{display:"block"}}>Manage Registrations</span>
                            </button>
                        </Link>
                    </div>
                    <div className="col-sm-12 col-md-4" style={{marginBottom:15}}>
                        <Link to={'/camper/cards'}>
                            <button style={{width:"100%",height:100,fontSize:15,fontWeight:"bold"}} className="btn btn-warning" type="button" onClick={this.onDelete}>
                                <i className="fa-3x fas fa-credit-card"></i><span style={{display:"block"}}>Manage Payment Info</span>
                            </button>
                        </Link>
                    </div>
                </div> 
            </div>
        )
    }
}

export default Dashboard;
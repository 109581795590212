import React, {Component, Fragment} from 'react';
import styles from '../search/Search.module.css';
import climbing from '../search/images/climbing.jpg';
import outdoor from '../search/images/outdoor.jpg';
import swimming from '../search/images/swimming.jpg';
import Carousel from 'react-bootstrap/Carousel';
import Link from 'react-dom';
import DataService from '../../services/dataService';
import ContentLoader from "react-content-loader";
import Button from "../common/Button";

class HostCampProfile extends Component{
    constructor(props){
        super(props);

        this.handleEdit = this.handleEdit.bind(this);

        this.state={
            loading:true,
            name:"",
            orgName:"",
            address:"",
            city:"",
            state:"",
            zip:"",
            phone:"",
            email:"",
            spots:"",
            cost:"",
            startDate:"",
            endDate:"",
            minAge:"",
            maxAge:"",
            categories:[],
            images:[]
        };
    }

    componentDidMount(){
        const { match: { params } } = this.props;
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        DataService.getCamp(params.id).then(camps=>{
            const camp = camps.data[0];
            console.log(camps.data[0]);
            const startDate = new Date(camp.StartDate).getDate();
            const startMonth = monthNames[new Date(camp.StartDate).getMonth()];
            const endDate = new Date(camp.EndDate).getDate();
            const endMonth = monthNames[new Date(camp.EndDate).getMonth()];
            const fullAddress = `${camp.Address} ${camp.City}, ${camp.State} ${camp.Zip}`
            this.setState({
                name: camp.campname,
                orgName: camp.orgname,
                address: fullAddress,
                phone: camp.phone,
                email: camp.email,
                orgName: camp.orgname,
                startDate: `${startMonth} ${startDate}`,
                endDate: `${endMonth} ${endDate}`,
                minAge: camp.MinAge,
                maxAge: camp.MaxAge,
                spots: camp.SpotsAvailable,
                cost: camp.Cost
            });
        });
        DataService.getCampImages(params.id).then(images=>{
            this.setState({
                images:images.data
            },()=>{
                this.setState({loading:false});
            })
        });
        DataService.getCampCategories(params.id).then(categories=>{
            this.setState({
                categories:categories.data
            })
        });
    }

    handleEdit(e){
        e.preventDefault();
        const { match: { params } } = this.props;
        this.props.history.push(`/host/editcamp/${params.id}`);
    }

    render(){
        if(this.state.loading){
            return(
                <Fragment>
                    <div className="row d-flex justify-content-center" style={{marginTop:20}}>
                        <div className="col-sm-8 col-md-6">
                            <ContentLoader style={{width:'100%'}}>
                                <rect x="0" y="0" rx="5" ry="5" width="100" height="100" />
                                <rect x="120" y="17" rx="4" ry="4" width="700" height="16"  />
                                <rect x="120" y="45" rx="3" ry="3" width="650" height="13" />
                                <rect x="120" y="70" rx="3" ry="3" width="550" height="13" />
                            </ContentLoader>
                        </div>
                    </div>
                </Fragment>
            )
        }
        return(
            <Fragment>
                <div className="container">
                    <div className="row" style={{marginTop:20}}>
                        <div className="col-sm-12 col-md-6">
                            <Carousel>
                                <Carousel.Item>
                                    <img className='d-block w-100' src={this.state.images[0] ? this.state.images[0].URL : climbing} alt='First slide' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className='d-block w-100' src={this.state.images[1] ? this.state.images[1].URL : outdoor} alt='Second slide' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className='d-block w-100' src={this.state.images[2] ? this.state.images[2].URL : swimming} alt='Third slide' />
                                </Carousel.Item>
                            </Carousel>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className={styles['camp-profile-primary-info']}>
                                <div className={styles.campname}>
                                    <span className={styles['camp-profile-header-text']}>
                                    CAMP NAME:
                                    </span>
                                </div>
                                <div className={styles.campnameinfo}>
                                    {this.state.name}
                                </div>
                                <div className={styles.camphost}>
                                    <span className={styles['camp-profile-header-text']}>
                                    CAMP HOST:
                                    </span>
                                </div>
                                <div className={styles.camphostinfo}>
                                    {this.state.orgName}
                                </div>
                                <div className={styles.address}>
                                    <span className={styles['camp-profile-header-text']}>
                                    ADDRESS:
                                    </span>
                                </div>
                                <div className={styles.addressinfo}>
                                    <span>{this.state.address}</span>
                                    {/*<span>{this.state.city}</span>,<span>{this.state.state}</span>
                                    <span>{this.state.zip}</span>*/}
                                </div>
                                <div className={styles.dates}>
                                    <span className={styles['camp-profile-header-text']}>
                                    DATES:
                                    </span>
                                </div>
                                <div className={styles.datesinfo}>
                                    {this.state.categories.findIndex(x => x.ID === 1) != -1 ? 
                                    <span>Year Round</span>
                                    : <span>Camp runs {this.state.startDate} - {this.state.endDate}</span>}
                                </div>
                                <div className={styles.age}>
                                    <span className={styles['camp-profile-header-text']}>AGE:</span>
                                </div>
                                <div className={styles.ageinfo}>
                                    <span>Ages {this.state.minAge} to {this.state.maxAge}</span>
                                </div>
                                <div className={styles.contact}>
                                    <span className={styles['camp-profile-header-text']}>CONTACT:</span>
                                </div>
                                <div className={styles.contactinfo}>
                                    <span>Phone: {this.state.phone}</span><br/>
                                    <span>Email: {this.state.email}</span>
                                </div>
                                <div className={styles.cost}>
                                    <span className={styles['camp-profile-header-text']}>COST:</span>
                                </div>
                                <div className={styles.costinfo}>
                                    <span>${this.state.cost}</span>
                                </div>
                                <div className={styles.spots}>
                                    <span className={styles['camp-profile-header-text']}>CAPACITY:</span>
                                </div>
                                <div className={styles.spotsinfo}>
                                    <span>{this.state.spots} Campers</span>
                                </div>
                                {/*<div className={styles.categories}>
                                    <span className={styles['camp-profile-header-text']}>Categories:</span>
                                </div>
                                <div className={styles.categoryinfo}>
                                    <ul>
                                    {
                                        this.state.categories.map(item => (
                                            <Fragment key={item.ID}>
                                                <li>
                                                    {item.Text}
                                                </li>
                                            </Fragment>
                                        ))
                                    }
                                    </ul>
                                </div>*/}
                                <div className={styles.button} style={{textAlign:"center"}}>
                                    <Button
                                    text="Edit"
                                    type="submit"
                                    onClick={this.handleEdit}
                                    buttonStyle="btn--default"
                                    icon="none"
                                    widthValue="100%"
                                    ></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default HostCampProfile;
import React, {Fragment,Component} from 'react';
import {BrowserRouter, Route, Switch, Link} from 'react-router-dom';
import Navbar from './common/Navbar';
import Landing from './common/Landing';
import Footer from './common/Footer';
import About from './common/About';
import HowItWorks from './common/Works';
import Pricing from './common/Pricing';
import FAQ from './common/FAQ';
import Contact from './common/Contact';
import Terms from './common/Terms';
import PrivacyPolicy from './common/PrivacyPolicy';
import Register from './hosts/Register';
import HostAccount from './hosts/Account';
import HostPaywall from './ecom/CampHostPaywall';
import HostPurchaseSuccess from './ecom/CampHostPurchaseSuccess';
import Login from './hosts/Login';
import CamperLogin from './campers/Login';
import CamperRegister from './campers/Register';
import CamperDashboard from './campers/Dashboard';
import ManageCampers from './campers/ManageCampers';
import AddCamper from './campers/AddCamper';
import PurchaseCamp from './camps/PurchaseCamp';
import HostForgotPassword from './hosts/ForgotPassword';
import Orgs from './hosts/Orgs';
import Camps from './hosts/Camps';
import Search from './search/Search';
import SearchResults from './search/SearchResults';
import PublicCampProfile from './camps/PublicCampProfile';
import PublicOrgProfile from './orgs/PublicOrgProfile';
import HostOrgProfile from './hosts/HostOrgProfile';
import HostCampProfile from './hosts/HostCampProfile';
import EditOrg from "./hosts/EditOrg";
import ReviewEditOrg from './hosts/ReviewEditOrg';
import EditCamp from "./hosts/EditCamp";
import PrivateRoute from './common/PrivateRoute';
import setAuthToken from '../utils/setAuthToken';
import ManageCards from './campers/ManageCards';
import CampHostAdminReview from './admin/CampHostAdminReview';

if (localStorage.jwt) {
    //console.log(`token: ${localStorage.token}`);
    setAuthToken(localStorage.jwt);
}

class App extends Component{
    constructor(props){
        super(props);

    }

    render(){
        return (
            <BrowserRouter>
                <Fragment>
                    <Navbar/>
                    <Switch>
                        <Route path="/" exact component={Landing}/>
                        <Route path="/about" exact component={About}/>
                        <Route path="/how-it-works" exact component={HowItWorks}/>
                        <Route path="/pricing" exact component={Pricing}/>
                        <Route path="/faq" exact component={FAQ}/>
                        <Route path="/contact" exact component={Contact}/>
                        <Route path="/terms" exact component={Terms}/>
                        <Route path="/privacypolicy" exact component={PrivacyPolicy}/>
                        <Route path="/host/login" exact component={Login}/>
                        <Route path="/host/register" exact component={Register}/>
                        <PrivateRoute path="/host/subscribe" exact component={HostPaywall}/>
                        <PrivateRoute path="/host/subscribe/success" exact component={HostPurchaseSuccess}/>
                        <PrivateRoute path="/host/account" exact component={HostAccount}/>
                        <Route path="/host/forgotpassword" exact component={HostForgotPassword}/>
                        <Route path="/search" exact component={Search}/>
                        <Route path="/searchresults" exact component={SearchResults}/>
                        <Route path="/camps/:id" exact component={PublicCampProfile}/>
                        <Route path="/orgs/:id" exact component={PublicOrgProfile}/>
                        <Route path="/camper/login" exact component={CamperLogin}/>
                        <Route path="/camper/register" exact component={CamperRegister}/>
                        <PrivateRoute path="/camps/:id/register" exact component={PurchaseCamp}/>
                        <PrivateRoute path="/camper/campers" exact component={ManageCampers}/>
                        <PrivateRoute path="/camper/cards" exact component={ManageCards}/>
                        <PrivateRoute path="/camper/camper/:id" exact component={AddCamper}/>
                        <PrivateRoute path="/camper/dashboard" exact component={CamperDashboard}/>
                        <PrivateRoute path="/host/editorg/:id" exact component={EditOrg}/>
                        <Route path="/host/revieweditorg/:id" exact component={ReviewEditOrg}/>
                        <PrivateRoute path="/host/editcamp/:id" exact component={EditCamp}/>
                        <PrivateRoute path="/host/org/:id" exact component={HostOrgProfile} />
                        <PrivateRoute path ="/host/camp/:id" exact component={HostCampProfile} />
                        <PrivateRoute path="/host/dashboard" exact component={Orgs}/>
                        <PrivateRoute path="/host/org/:id/camps" exact component={Camps}/>

                        <Route path="/admin/revieworg/:id" exact component={CampHostAdminReview} />
                    </Switch>
                    <Footer />
                </Fragment>
            </BrowserRouter>
        );
    }
}

export default App;
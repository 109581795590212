import React, {Fragment} from 'react';
import styles from './CampHost.module.css';
import HostService from '../../services/hostService';
import ContentLoader from "react-content-loader";
import EcomService from '../../services/ecomService';
import OrgList from './OrgList';
import States from '../common/States';
import {logout} from '../../utils/tokenUtils';
import { Modal, Row, Col } from 'react-bootstrap';
import Button from '../common/Button';
import Cleave from 'cleave.js/react';
import swimming from '../common/images/swimming.jpg';
import qs from 'qs';

class Orgs extends React.Component{
    constructor(props){
        super(props);

        this.toggleModal = this.toggleModal.bind(this);
        this.toggleConsent = this.toggleConsent.bind(this);
        this.onChangeInput = this.onChangeInput.bind(this);
        this.onChangeDropdownInput = this.onChangeDropdownInput.bind(this);
        this.handleSubmitOrg = this.handleSubmitOrg.bind(this);
        this.drawModalForm = this.drawModalForm.bind(this);
        this.drawModalConsent = this.drawModalConsent.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.onBack = this.onBack.bind(this);

        this.state = {
          loading:true,
          isOrgUpdate:false,
          prevPath:"",
          orgs:[],
          showModal:false,
          showConsent:false,
          hasError:false,
          errorMessage:"",
          hasModalError:false,
          modalErrorMessaage:"",
          name:"",
          address:"",
          city:"",
          state:"",
          zip:"",
          phone:"",
          website:"",
          bio:"",
          thumbnail:null,
          contactName:"",
          contactNumber:"", 
          contactEmail:""
        }
    }
    verifyModalForm(){
        if(this.state.state!=""&&this.state.name!=""&&this.state.address!=""&&this.state.city!=""&&this.state.zip!=""&&this.state.phone!=""&&this.state.contactNumber!=""&&this.state.contactName){
            return true;
        }
        return false;
    }
    onChangeInput(e) {
        this.setState({
          [e.target.name]: e.target.value
        });
        console.log(e.target.value);
    }
    onChangeDropdownInput(e){
        this.setState({state: e.target.value});
        console.log(e.target.value);
    }
    handleFileUpload = (e) => {
      console.log("upload");
        console.log(e.target.files);
        this.setState({thumbnail: e.target.files});
    }
    handleSubmitOrg(e){
        e.preventDefault();

        if(!this.verifyModalForm()){
            this.setState({
                hasModalError:true,
                modalErrorMessaage:"Please fill in all required(*) fields"
            });
        }else{
            const formData = new FormData();
            if(this.state.thumbnail){
              formData.append('thumbnail', this.state.thumbnail[0]);
            }
            
            formData.append('name', this.state.name);
            formData.append('address', this.state.address);
            formData.append('city', this.state.city);
            formData.append('state', this.state.state);
            formData.append('zip', this.state.zip);
            formData.append('phone', this.state.phone);
            formData.append('website', this.state.website);
            formData.append('bio', this.state.bio);
            formData.append('contactName', this.state.contactName);
            formData.append('contactNumber', this.state.contactNumber);
            formData.append('contactEmail', this.state.contactEmail);

            HostService.addOrg(formData).then((response)=>{
                    this.setState({
                        name:"",
                        address:"",
                        city:"",
                        state:"",
                        zip:"",
                        phone:"",
                        website:"",
                        bio:"",
                        contactName:"",
                        contactNumber:"",
                        contactEmail:"",
                        thumbnail:null,
                        hasModalError:false,
                        modalErrorMessaage:""
                    });
                    this.setState({showConsent:false});
                    HostService.getOrgs().then((result)=>{
                        console.log(result.data);
                        this.setState({orgs:result.data});
                    },error=>{
                        if(error.response.data.message=="Failed to authenticate token."){
                            logout();
                        }
                        console.log(error.response);
                    });
                },(error)=>{
    
            });
        }
    }
    drawModalForm(){
        return(
          <Modal
            show={this.state.showModal}
            onHide={this.toggleModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                New Organization
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {this.state.hasModalError ?
                    <p className="alert alert-danger">{this.state.modalErrorMessaage}</p>
                    : ""}
                </div>
              <div className="container">
                <Row>
                  <Col className="col-sm-11 col-md-11">
                    <label htmlFor="name">Organization Name*</label>
                    <input
                      type="text"
                      id="name"
                      className="input"
                      required
                      name="name"
                      value={this.state.name}
                      onChange={this.onChangeInput}
                    />
                  </Col>
                </Row>
                <hr/>
                <h4 style={{textDecoration:"underline",marginBottom:"10px"}}>Organization Location</h4>
                <Row>
                  <Col className="col-sm-11 col-md-11">
                    <label htmlFor="address">Address*</label>
                    <input
                      type="text"
                      id="address"
                      className="input"
                      required
                      name="address"
                      value={this.state.address}
                      onChange={this.onChangeInput}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="col-sm-5 col-md-5">
                    <label htmlFor="city">City*</label>
                    <input
                      type="text"
                      id="city"
                      className="input"
                      required
                      name="city"
                      value={this.state.city}
                      onChange={this.onChangeInput}
                    />
                  </Col>
                  <Col className="col-sm-3 col-md-3">
                    <label htmlFor="city">State*</label>
                    <States stateValue={this.state.state} changeFunction={this.onChangeDropdownInput} />
                  </Col>
                  <Col className="col-sm-3 col-md-3">
                    <label htmlFor="zip">Zip*</label>
                    <input
                      type="text"
                      id="zip"
                      className="input"
                      required
                      name="zip"
                      value={this.state.zip}
                      onChange={this.onChangeInput}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className='col-sm-5 col-md-5'>
                    <label htmlFor="phone">Phone*</label>
                    <Cleave
                      options={{ phone: true, phoneRegionCode: 'US', delimiter: '-' }}
                      value={this.state.phone}
                      onChange={this.onChangeInput}
                      className="input"
                      required
                      id="phone"
                      name="phone"
                    />
                  </Col>
                  <Col className='col-sm-6 col-md-6'>
                    <label htmlFor="website">Website</label>
                    <input
                      type="text"
                      id="website"
                      className="input"
                      required
                      name="website"
                      value={this.state.website}
                      onChange={this.onChangeInput}
                    />
                  </Col>
                </Row>
                <hr/>
                <h4 style={{textDecoration:"underline",marginBottom:"10px"}}>Contact Person</h4>
                <hr/>
                <Row>
                  <Col className='col-sm-5 col-md-5'>
                    <label htmlFor="phone">Name*</label>
                    <input
                      type="text"
                      id="contactname"
                      className="input"
                      required
                      name="contactName"
                      value={this.state.contactName}
                      onChange={this.onChangeInput}
                    />
                  </Col>
                  <Col className='col-sm-6 col-md-6'>
                    <label htmlFor="website">Phone*</label>
                    <Cleave
                      options={{ phone: true, phoneRegionCode: 'US', delimiter: '-' }}
                      value={this.state.contactNumber}
                      onChange={this.onChangeInput}
                      className="input"
                      required
                      id="contactNumber"
                      name="contactNumber"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="col-sm-11 col-md-11">
                    <label htmlFor="address">Email*</label>
                    <input
                      type="text"
                      id="contactEmail"
                      className="input"
                      required
                      name="contactEmail"
                      value={this.state.contactEmail}
                      onChange={this.onChangeInput}
                    />
                  </Col>
                </Row>
                <h4 style={{textDecoration:"underline",marginBottom:"10px"}}>Organization Details</h4>
                <Row>
                  <Col className='col-sm-11 col-md-11'>
                    <label htmlFor="bio">Organization Bio</label>
                    <textarea
                      id="bio"
                      className="input"
                      required
                      name="bio"
                      value={this.state.bio}
                      onChange={this.onChangeInput}
                    />
                  </Col>
                </Row>
                <hr/>
                <h4 style={{textDecoration:"underline",marginBottom:"10px"}}>Image</h4>
                <Row>
                    <Col className="col-sm-10 col-md-10">
                        <label htmlFor="thumbnail">Organization Thumbnail Image:</label><br/>
                        <input name="thumbnail" label='upload file' type='file' onChange={this.handleFileUpload} />
                    </Col>
                </Row>
              </div>
              <div>
                    {this.state.hasModalError ?
                    <p className="alert alert-danger">{this.state.modalErrorMessaage}</p>
                    : ""}
                </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                text="Add Organization"
                type="submit"
                onClick={(e) => { this.handleTriggerConsent(e) }}
                buttonStyle="btn--default"
                icon="none"
              ></Button>
            </Modal.Footer>
          </Modal>
        )
    };
    drawModalConsent(){
      return(
        <Modal
          show={this.state.showConsent}
          onHide={this.toggleConsent}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Verification Consent
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <Row>
                <Col className="col-sm-11 col-md-11">
                  <p>
                        User hereby authorizes Summertymes LLC to conduct a background investigation as part of its voluntary screening process. The outcome of the investigation will determine the eligibility to use Summertymes.com. 
By agreeing, User authorizes and consents without reservation to the retrieval of information that may include, but is not limited to personal, criminal and financial data. 
I understand that agreeing to these terms is completely voluntary.<br/>
Please email <a href="mailto:privacy@summertymes.com?subject=Background Check Questions ">privacy@summertymes.com</a> for any questions or concerns. 
                  </p>
                   <br/>
                  <p>Clicking the "Approve" button below gives your consent to be verified by Summertymes.</p>
                </Col>
              </Row>
              <hr/>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              text="Approve"
              type="submit"
              onClick={(e) => { this.handleSubmitOrg(e) }}
              buttonStyle="btn--default"
              icon="none"
            ></Button>
            <Button
              text="Deny"
              type="submit"
              onClick={(e) => { this.handleTriggerConsent(e) }}
              buttonStyle="btn--default"
              icon="none"
            ></Button>
          </Modal.Footer>
        </Modal>
      )
    }
    componentDidMount(){
      //Check query param
      const isOrgUpdate = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).orgupdate;
      if(isOrgUpdate){
        this.setState({isOrgUpdate:isOrgUpdate});
      }
      console.log(isOrgUpdate);
      //Check if subscription is active
      EcomService.getSubscription().then((result)=>{
        const isSubscribed = result.data[0].IsSubscribed;
        if(isSubscribed){
          //no action
          //this.setState({loading: false});
        }else{
          //redirect to subscription page
          window.location.href = "/host/subscribe";
        }
      },error=>{
        if(error.response.data.message=="Failed to authenticate token."){
            logout();
        }
        console.log(error.response);
      });

      HostService.getOrgs().then((result)=>{
          this.setState({orgs:result.data});
          this.setState({loading: false});
      },error=>{
          if(error.response.data.message=="Failed to authenticate token."){
              logout();
          }
          console.log(error.response);
      });
    }
    componentWillReceiveProps(nextProps) {
      if (nextProps.location !== this.props.location) {
        console.log(this.props.location);
        this.setState({ prevPath: this.props.location })
      }
    }
    onBack(){
      this.props.history.goBack();
    }
    handleTriggerConsent(e){
      e.preventDefault();
      if(this.state.showConsent){
        this.setState({showModal:true});
        this.toggleConsent();
      }else{
        if(!this.verifyModalForm()){
          this.setState({
              hasModalError:true,
              modalErrorMessaage:"Please fill in all required(*) fields"
          });
        }else{
          this.setState({showModal:false});
          this.toggleConsent();
        }
      }
      //
    }
    toggleModal(){
        if(this.state.showModal===false){
            this.setState({showModal:true});
        }else{
            this.setState({showModal:false});
        }
        console.log(this.state.showModal);
    }
    toggleConsent(){
      console.log(this.state);
      if(this.state.showConsent===false){
          this.setState({showConsent:true});
      }else{
          this.setState({showConsent:false});
      }
      console.log(this.state.showConsent);
  }
    render(){
      if(this.state.loading){
        return(
            <Fragment>
                <div className="row d-flex justify-content-center" style={{marginTop:20}}>
                    <div className="col-sm-8 col-md-7 justify-content-center">
                    <ContentLoader 
                      style={{width:"100%"}}
                      speed={1}
                      height={460}
                      backgroundColor="#d4d4d4"
                      foregroundColor="#ecebeb"
                    >
                      <rect x="0" y="60" rx="2" ry="2" width="1800" height="400" />
                    </ContentLoader>
                    </div>
                </div>
            </Fragment>
        )
      }
      return (
          <Fragment>
            {this.drawModalForm()}
            {this.drawModalConsent()}
            <div className={styles['header']}>
                <div className="row">
                    <div className='col-sm-5 col-md-5'>
                        {/*<h3 style={{cursor:"pointer"}} className={styles['form-text']} onClick={this.onBack}>
                            <i className="fas fa-chevron-circle-left"></i> Back
                        </h3>*/}
                    </div>
                    <div className='col-sm-2 col-md-2'>
                        <h1 className={styles['form-text']}>MY ORGANIZATIONS</h1>
                    </div>

                    <div className='col-sm-4 col-md-4' onClick={this.toggleModal}>
                        <h1 style={{cursor:'pointer'}} className={styles['form-text']}>+ New Organization</h1>
                    </div>                      
                </div>
            </div>

            <div className={styles['gray-container']}>
            <div>
                        {this.state.isOrgUpdate ?
                        <Fragment><p className="alert alert-success" style={{marginTop:30,textAlign:"center"}}>The Org you updated is now in review.</p><br/></Fragment>
                        : ""}
                    </div>
                {this.state.orgs && this.state.orgs.length !== 0 ? (
                <div className={styles['mycamps']}>
                    <OrgList orgs={this.state.orgs}/>
                </div>
                ) : (
                    <h2 className={styles['nocamps']}>No Organizations Added Yet</h2>
                )}
            </div>
          </Fragment>
      );
    }
}

export default Orgs;

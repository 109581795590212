import React, { useState, Fragment } from 'react'
import { Link } from 'react-router-dom'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe
} from 'react-stripe-elements'
import CamperService from '../../services/camperService';
import './CheckoutForm.scss'

const CheckoutForm = ({ price, campName, campers, stripe, history }) => {

  const handleSubmit = async event => {
    event.preventDefault()

    const { token } = await this.props.stripe.createToken()

    CamperService.purchaseCamp(this.state.price, token).then((result)=>{
        
    },error=>{
        console.log(error.response);
    });

    //setReceiptUrl(order.data.charge.receipt_url)
  }

  if(campers.length==0){
    return(
        <div style={{marginTop:75, textAlign:"center"}}>
            <h1 style={{textAlign:"center", color:"#ff794d"}}>You must add campers before you register for a camp.</h1>
            <Link style={{fontSize:20}} to="/camper/campers">Click here to add campers</Link>
        </div>
    )
  }

  return (
    <div className="checkout-form" style={{marginTop:75}}>
        <h2 style={{textAlign:"center", color:"#ff794d"}}>Register for:{campName}</h2>
        <p>Registration Fee: ${price}</p>
        <div className="container">
            <div className="row">
                <div className='col-sm-12 col-md-6'>
                    <label>Select Camper:</label>
                    <select className="input" style={{border:"2px solid #d4d4d4",fontSize:21,borderRadius:5,marginBottom:20,marginTop:10}}>
                        {campers.map((x,y) => <option key={x.Id}>{x.FirstName}</option>)}
                    </select>
                </div>
                <div className="col-sm-12 col-md-6">
                    <label>Card Number</label>
                    <CardNumberElement className="input"/>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-6">
                    <label>Expiration Date</label>
                    <CardExpiryElement className="input"/>
                </div>
                <div className="col-sm-12 col-md-6">
                    <label>Card Details</label>
                    <CardCVCElement className="input"/>
                </div>
            </div>
            <div className="row">
                <button onClick={handleSubmit} type="submit" className="order-button">
                    Pay
                </button>
            </div>
        </div>
    </div>
  )
}

export default injectStripe(CheckoutForm)
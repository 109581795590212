import React, {Fragment, Component} from 'react'; 
import Button from '../common/Button';
import States from '../common/States';
import Cleave from 'cleave.js/react';
import AuthService from '../../services/authService';
import DataService from '../../services/dataService';
import HostService from '../../services/hostService';
import { Multiselect } from 'multiselect-react-dropdown';
import DatePicker from "react-datepicker";
import Spinner from "../common/Spinner";
import ContentLoader from "react-content-loader";

class EditOrg extends Component{
    constructor(props){
        super(props);

        this.onChangeInput = this.onChangeInput.bind(this);
        this.onUpdateDetails = this.onUpdateDetails.bind(this);
        this.onUpdateThumbnail = this.onUpdateThumbnail.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.onChangeCheck = this.onChangeCheck.bind(this);
        this.multiselectRef = React.createRef();
        this.onSelect = this.onSelect.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onChangeDropdownInput = this.onChangeDropdownInput.bind(this);
        this.onDeleteThumbnail = this.onDeleteThumbnail.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.onUpdateImage = this.onUpdateImage.bind(this);

        this.state = {
            name:"",
            waiting:false,
            imageSaving:false,
            orgId:"",
            campId:"",
            address:"",
            city:"",
            state:"",
            zip:"",
            phone:"",
            email:"",
            minAge: "",
            maxAge: "",
            spots:"",
            cost:"",
            allowsWaitlist:false,
            images:[],
            oldThumbnail:null,
            thumbnail:null,
            thumbnailSaving:false,
            hasThumbnailError:false,
            campImage1:null,
            campImage2:null,
            campImage3:null,
            thumbnailErrorMessage:"",
            hasImageError:false,
            imageErrorMessage:"",
            categories:[],
            campCategories:[],
            selectedValue:[],
            locationDisabled:false,
            dateDisabled:false,
            hasError:false,
            errorMessage:"",
            updateSaved:false,
            successMessage:"",
            pageLoading:true
        }
    }

    componentDidMount(){
        const { match: { params } } = this.props;
        DataService.getCamp(params.id).then(camps=>{
            const camp = camps.data[0];
            console.log(camps.data[0]);
            this.setState({
                name: camp.campname,
                orgId: camp.orgid,
                campId: camp.campid,
                address: camp.Address,
                city: camp.City,
                state: camp.State,
                zip: camp.Zip,
                phone: camp.phone,
                email: camp.email,
                startDate: new Date(camp.StartDate),
                endDate: new Date(camp.EndDate),
                minAge: camp.MinAge,
                maxAge: camp.MaxAge,
                oldThumbnail: camp.Thumbnail,
                spots: camp.SpotsAvailable,
                cost: camp.Cost,
                allowsWaitlist: camp.AllowsWaitlist
            });
        });
        DataService.getCategories().then((result)=>{
            console.log(result.data);
            this.setState({categories:result.data});
        })
        DataService.getCampCategories(params.id).then(categories=>{
            this.setState({
                selectedValue:categories.data
            },()=>{
                let tempCategories = this.state.selectedValue.map(a => a.ID);
                this.setState({campCategories:tempCategories},()=>{
                    if(tempCategories.includes(22)){
                        this.setState({dateDisabled:true});
                    }
                    if(tempCategories.includes(26)){
                        this.setState({
                            locationDisabled:true,
                            state:"",
                            city:"",
                            address:"",
                            zip:""
                        })
                    }
                    this.setState({pageLoading:false});
                });
            })
        });
        DataService.getCampImages(params.id).then(images=>{
            this.setState({
                images:images.data
            },()=>{console.log(this.state.images)});
        });
    }

    verifyDetails(){
        //set up ignored fields
        let ignore = [];
        ignore = ["orgId","campId","email","allowsWaitlist","thumbnail","categories","campCategories","selectedValue",
                  "hasError","errorMessage","updateSaved","successMessage","waiting","imageSaving","locationDisabled",
                  "dateDisabled","thumbnail","oldThumbnail","thumbnailSaving","images","hasThumbnailError","thumbnailErrorMessage",
                  "campImage1","campImage2","campImage3","hasImageError","imageErrorMessage","pageLoading"];
        if(this.state.campCategories.includes(26)){
            //ignore address fields
            ignore.push("address","city","state","zip");
        }
        if(this.state.campCategories.includes(22)){
            //ignore date fields
            ignore.push("startDate","endDate");
        }
        console.log(ignore);
        for (const property in this.state) {
            if(!ignore.includes(property)){
                if(this.state[property]==""||this.state[property]==null){
                    console.log("offender");
                    console.log(property);
                    return false;
                }
            }
        }
        return true;
    }

    onDelete(type){
        if(window.confirm('Are you sure you wish to delete this organization?')){
            this.setState({
                waiting:true
            },()=>{
                HostService.deleteCamp(this.state.campId).then(result=>{
                    console.log(result);
                    this.props.history.push({pathname:`/host/org/${this.state.orgId}/camps`});
                },error=>{
                    console.log(error.response);
                });
            })
        }
    }

    onChangeCheck(e){
        this.setState({[e.target.name]: e.target.checked},()=>{console.log(this.state)});
    }

    onSelect(){
        const sels = this.multiselectRef.current.getSelectedItems();
        this.setState({campCategories:[]},()=>{
            const tempcat = this.state.campCategories;
            for (let i = 0; i < sels.length; i++) {
                tempcat.push(sels[i].ID);
            }
            if(tempcat.includes(26)){
                this.setState({
                    locationDisabled:true,
                    state:"",
                    city:"",
                    address:"",
                    zip:""
                })
            }else if(tempcat.includes(22)){
                this.setState({
                    dateDisabled:true,
                    startDate:"",
                    endDate:""
                })
            }
            else{
                this.setState({
                    locationDisabled:false,
                    dateDisabled:false
                })
            }
            this.setState({campCategories:tempcat},()=>{console.log(this.state)});
        });
    }

    handleStartDateChange(date){
        const fullDate = new Date(date);
        const year = fullDate.getFullYear();
        const month = (fullDate.getMonth()+1) < 10 ? `0${fullDate.getMonth()+1}` : fullDate.getMonth()+1;
        const day = (fullDate.getDate()+1) < 10 ? `0${fullDate.getDate()+1}` : fullDate.getDate()+1;
        const formattedDate = `${year}-${month}-${day}`;
        this.setState({startDate:new Date(formattedDate)});
    }
    
    handleEndDateChange(date){
        const fullDate = new Date(date);
        const year = fullDate.getFullYear();
        const month = (fullDate.getMonth()+1) < 10 ? `0${fullDate.getMonth()+1}` : fullDate.getMonth()+1;
        const day = (fullDate.getDate()+1) < 10 ? `0${fullDate.getDate()+1}` : fullDate.getDate()+1;
        const formattedDate = `${year}-${month}-${day}`;
        this.setState({endDate:new Date(formattedDate)});
    }

    onChangeInput(e){
        this.setState({[e.target.name]: e.target.value});
    }

    onChangeDropdownInput(e){
        this.setState({state: e.target.value});
        console.log(e.target.value);
    }

    onUpdateDetails(){
        this.setState({
            hasError:false,
            errorMessage:"",
            successMessage:"",
            updateSaved:false
        });

        if(!this.verifyDetails()){
            this.setState({
                hasError:true,
                errorMessage: "Please fill in all required(*) fields"
            });
        }else{
            if(this.state.campCategories.length==0){
                this.setState({
                    hasError:true,
                    errorMessage: "Must select at least one category"
                });
            }else if(parseInt(this.state.minAge) > parseInt(this.state.maxAge)){     
                this.setState({
                    hasError:true,
                    errorMessage:"Minimum age cannot be greater than maximum age"
                });
            }
            else{
                let startDate = null;
                let endDate = null;
                let address = null;
                let city = null;
                let state = null;
                let zip = null
                if(!this.state.campCategories.includes(22)){
                    startDate = this.state.startDate;
                    endDate = this.state.endDate;
                }
                if(!this.state.campCategories.includes(26)){
                    address = this.state.address;
                    city = this.state.city;
                    state = this.state.state;
                    zip = this.state.zip;
                }
                const camp = {
                    name: this.state.name,
                    orgId: this.state.orgId,
                    campId: this.state.campId,
                    address: address,
                    city: city,
                    state: state,
                    zip: zip,
                    phone: this.state.phone,
                    email: this.state.email,
                    startDate: startDate,
                    endDate: endDate,
                    minAge: this.state.minAge,
                    maxAge: this.state.maxAge,
                    spots: this.state.spots,
                    cost: this.state.cost,
                    allowsWaitlist: this.state.allowsWaitlist
                }
                HostService.updateCamp(camp).then((result)=>{
                    for(let catId in this.state.campCategories){
                        (async()=>{
                            await HostService.addCampCategory(this.state.campCategories[catId],this.state.campId,this.state.orgId).then((res)=>{
                                this.setState({
                                    updateSaved:true,
                                    successMessage: "Account details updated!"
                                });
                            },error=>{
                                console.log(error);
                            })
                        })();
                    }
                },(error)=>{
                    console.log(error.response);
                });
                console.log(this.state);
            }
        }
    }

    handleFileUpload = (e) => {
        //console.log(e.target.files);
        this.setState({[e.target.name]: e.target.files});
    }

    onUpdateThumbnail(){
        this.setState({
            hasThumbnailError:false,
            thumbnailErrorMessage:""
        });

        if(this.state.thumbnail){
            const { match: { params } } = this.props;
            const id = params.id;
            const formData = new FormData();
            formData.append('thumbnail', this.state.thumbnail[0]);
            this.setState({
                thumbnailSaving:true
            })
            HostService.updateCampThumbnail(formData, id).then((response)=>{
                window.location.reload(false);
            },error=>{
                console.log(error.response);
            });
        }else{
            this.setState({
                hasThumbnailError:true,
                thumbnailErrorMessage:"Must upload an image in order to update thumbnail"
            });
        }
    }

    onUploadImage(name){
        /*await HostService.addCampImage(imageURL,this.state.campId,this.state.orgId).then((res)=>{
        
        },error=>{
            console.log(error);
        })*/
        this.setState({
            hasImageError:false,
            imageErrorMessage:""
        });

        if(this.state[name]){
            const file = this.state[name][0];
            const formData = new FormData();
            formData.append('thumbnail', file);
            console.log(file);
            this.setState({
                imageSaving:true
            })
            HostService.uploadCampImage(formData, this.state.campId).then((response)=>{
                window.location.reload(false);
            },error=>{
                console.log(error.response);
            });
        }else{
            this.setState({
                hasImageError:true,
                imageErrorMessage:"Must upload an image first"
            });
        }
    }

    onUpdateImage(id, name){
        this.setState({
            hasImageError:false,
            imageErrorMessage:""
        });

        if(this.state[name]){
            const file = this.state[name][0];
            const formData = new FormData();
            formData.append('thumbnail', file);
            console.log(file);
            this.setState({
                imageSaving:true
            })
            HostService.updateCampImage(formData, id).then((response)=>{
                window.location.reload(false);
            },error=>{
                console.log(error.response);
            });
        }else{
            this.setState({
                hasImageError:true,
                imageErrorMessage:"Must upload an image first"
            });
        }
    }

    onDeleteImage(id){
        if(window.confirm("Are you sure you wish to delete this camp image?")){
            HostService.deleteCampImage(id).then(result=>{
                window.location.reload(false);
            },error=>{
                console.log(error.response);
            });
        }
    }

    onDeleteThumbnail(){
        if(window.confirm("Are you sure you wish to delete this camp's thumbnail?")){
            HostService.deleteCampThumbnail(this.state.campId).then(result=>{
                console.log(result);
                window.location.reload(false);
                //this.props.history.push({pathname:`/host/org/${this.state.orgId}/camps`});
            },error=>{
                console.log(error.response);
            });
        }
    }

    render(){
        if(this.state.imageSaving || this.state.thumbnailSaving || this.state.waiting){
            return(
                <Spinner/>
            )
        }
        if(this.state.pageLoading){
            return(
                <Fragment>
                    <div className="row d-flex justify-content-center" style={{marginTop:20}}>
                        <div className="col-sm-8 col-md-6">
                        <ContentLoader 
                            rtl
                            speed={3}
                            width={400}
                            height={460}
                            viewBox="0 0 400 460"
                            backgroundColor="#c2c2c2"
                            foregroundColor="#ecebeb"
                        >
                            <circle cx="31" cy="31" r="15" /> 
                            <rect x="58" y="18" rx="2" ry="2" width="140" height="10" /> 
                            <rect x="58" y="34" rx="2" ry="2" width="140" height="10" /> 
                            <rect x="0" y="60" rx="2" ry="2" width="400" height="400" />
                        </ContentLoader>
                        </div>
                    </div>
                </Fragment>
            )
        }
        return (
            <Fragment>
                {this.state.waiting ? (
                    <Spinner/>
                ):""}
                <div className="container" style={{marginTop:30}}>
                    <div className="row">
                        <div className="col-sm-6">
                            <h4 style={{textDecoration:"underline",marginBottom:"10px"}}>Camp Details</h4> 
                        </div>
                        <div className="col-sm-6" style={{textAlign:"right"}}>
                            <input style={{width:180}} className="btn btn-danger" type="button" value="Delete Camp" onClick={this.onDelete}></input>
                        </div>                           
                    </div>
                    <hr/>
                    <div className="row">
                            <div className="col-sm-12">
                                <label htmlFor="name">Name*</label>
                                <input
                                    type="text"
                                    id="name"
                                    className="input"
                                    required
                                    name="name"
                                    value={this.state.name}
                                    onChange={this.onChangeInput}
                                />
                            </div>

                            <div className="col-sm-12">
                                <label htmlFor="categories">Categories*</label>
                                <Multiselect
                                options={this.state.categories} // Options to display in the dropdown
                                placeholder="Select Categories"
                                ref={this.multiselectRef}
                                selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                onSelect={this.onSelect} // Function will trigger on select event
                                onRemove={this.onSelect} // Function will trigger on remove event
                                displayValue="Text" // Property name to display in the dropdown options
                                />
                            </div>
                    </div>
                    <br/>
                    <h4 style={{textDecoration:"underline",marginBottom:"10px"}}>Camp Location</h4>
                    <span style={this.state.locationDisabled ? {display:"block",paddingBottom:10,fontWeight:"bold"} : {display:"none"}}>*Address, City, State, & Zip disabled for virtual camps*</span>
                    <hr/>
                    <div className="row">
                        <div className="col-sm-12">
                                <label htmlFor="firstName">Address*</label>
                                <input
                                    disabled={this.state.locationDisabled}
                                    type="text"
                                    id="address"
                                    className="input"
                                    required
                                    name="address"
                                    value={this.state.address}
                                    onChange={this.onChangeInput}
                                />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <label htmlFor="city">City</label>
                            <input
                                disabled={this.state.locationDisabled}
                                type="text"
                                id="city"
                                className="input"
                                required
                                name="city"
                                value={this.state.city}
                                onChange={this.onChangeInput}
                            />
                        </div>
                        <div className="col-sm-3">
                            <label htmlFor="state">State</label>
                            <States stateValue={this.state.state} disabled={this.state.locationDisabled} changeFunction={this.onChangeDropdownInput} />
                        </div>
                        <div className="col-sm-3">
                            <label htmlFor="zip">Zip</label>
                            <input
                                disabled={this.state.locationDisabled}
                                type="text"
                                id="zip"
                                className="input"
                                required
                                name="zip"
                                value={this.state.zip}
                                onChange={this.onChangeInput}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <label htmlFor="phone">Phone</label>
                            <Cleave
                            options={{ phone: true, phoneRegionCode: 'US', delimiter: '-' }}
                            value={this.state.phone}
                            onChange={this.onChangeInput}
                            className="input"
                            required
                            id="phone"
                            name="phone"
                            />
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="email">Email</label>
                            <input
                                type="text"
                                id="email"
                                className="input"
                                required
                                name="email"
                                value={this.state.email}
                                onChange={this.onChangeInput}
                            />
                        </div>
                    </div>
                    <br/>
                    <h4 style={{textDecoration:"underline",marginBottom:"10px"}}>Additional Details</h4>
                    <span style={this.state.dateDisabled ? {display:"block",paddingBottom:10,fontWeight:"bold"} : {display:"none"}}>*Start and End Dates disabled for year-round camps*</span>
                    <hr/>
                    <div className="row">
                        <div className="col-sm-6">
                            <label htmlFor="startDate">Start Date</label>
                            <DatePicker
                                disabled={this.state.dateDisabled}
                                selected={this.state.startDate}
                                onChange={this.handleStartDateChange}
                                name="startDate"
                                className="input"
                                dateFormat="yyyy-MM-dd"
                            />
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="endDate">End Date</label>
                            <DatePicker
                                disabled={this.state.dateDisabled}
                                selected={this.state.endDate}
                                onChange={this.handleEndDateChange}
                                name="endDate"
                                className="input"
                                dateFormat="yyyy-MM-dd"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <label htmlFor="cost">($)Camp Cost</label>
                            <input
                                type="text"
                                id="cost"
                                className="input"
                                required
                                name="cost"
                                value={this.state.cost}
                                onChange={this.onChangeInput}
                            />
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="spots">Spots Available</label>
                            <input
                                type="text"
                                id="spots"
                                className="input"
                                required
                                name="spots"
                                value={this.state.spots}
                                onChange={this.onChangeInput}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <label htmlFor="minAge">Minimum Age</label>
                            <input
                                type="text"
                                id="minAge"
                                className="input"
                                required
                                name="minAge"
                                value={this.state.minAge}
                                onChange={this.onChangeInput}
                            />
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="maxAge">Max Age</label>
                            <input
                                type="text"
                                id="maxAge"
                                className="input"
                                required
                                name="maxAge"
                                value={this.state.maxAge}
                                onChange={this.onChangeInput}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <label htmlFor="allowsWaitlist">Allows Waitlist</label>
                            <input
                            type="checkbox"
                            id="allowsWaitlist"
                            className="input"
                            required
                            name="allowsWaitlist"
                            checked={this.state.allowsWaitlist}
                            onChange={this.onChangeCheck}
                            style={{width:30}}
                            />
                        </div>
                    </div>
                    <div className="row" style={{marginBottom:15}}>
                        <div className="mx-auto text-center p-3">
                            <Button
                                disabled={this.state.imageSaving||this.state.thumbnailSaving}
                                text="Update Details"
                                type="submit"
                                onClick={this.onUpdateDetails}
                                buttonStyle="btn--default"
                                icon="none"
                            ></Button>
                        </div>
                    </div>
                    <div>
                        {this.state.hasError ?
                        <p className="alert alert-danger">{this.state.errorMessage}</p>
                        : ""}
                    </div>
                    <div>
                        {this.state.updateSaved ?
                        <p className="alert alert-success" style={{marginTop:30}}>{this.state.successMessage}</p>
                        : ""}
                    </div>
                    <br/>
                    <h4 style={{textDecoration:"underline",marginBottom:"10px"}}>Thumbnail Image</h4>
                    <hr/>
                    <div>
                        {this.state.hasThumbnailError ?
                        <p style={{marginBottom:10}} className="alert alert-danger">{this.state.thumbnailErrorMessage}</p>
                        : ""}
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-4 col-lg-3">
                            Current Image:
                            {this.state.oldThumbnail != null ?
                            <Fragment><br/><img style={{width:200,height:150,borderRadius:10,marginBottom:15}} src={this.state.oldThumbnail}/><br/></Fragment>
                            : <span> No Image Uploaded</span>}

                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-9">
                            <Button
                                disabled={this.state.imageSaving||this.state.thumbnailSaving}
                                text="Update Thumbnail"
                                type="submit"
                                onClick={this.onUpdateThumbnail}
                                buttonStyle="btn--default"
                                icon="none"
                            ></Button>
                            <br/><br/>
                            {this.state.oldThumbnail? 
                            <input disabled={this.state.imageSaving||this.state.thumbnailSaving} style={{width:180,borderRadius:10}} className="btn btn-danger" type="button" value="Delete Thumbnail" onClick={this.onDeleteThumbnail}></input>
                            :""}
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        {!this.state.thumbnailSaving ? 
                        <div className="col-sm-6">
                            <label htmlFor="thumbnail">Choose New Thumbnail:</label><br/>
                            <input name="thumbnail" label='upload file' type='file' onChange={this.handleFileUpload} />
                        </div>
                        : <div className="col-sm-6"><span style={{fontWeight:"bold"}}>Saving New Thumbnail.....</span></div>
                        }
                    </div>
                    <br/>
                    <h4 style={{textDecoration:"underline",marginBottom:"10px"}}>Camp Images</h4>
                    <hr/>
                    <div>
                        {this.state.hasImageError ?
                        <p style={{marginBottom:10}} className="alert alert-danger">{this.state.imageErrorMessage}</p>
                        : ""}
                    </div>
                    {this.state.imageSaving ?
                    <div className="row">
                        <div className="col-sm-12">
                            <span style={{fontWeight:"bold",textAlign:"center",display:"block",fontSize:18}}>Saving New Image.....</span>
                        </div>
                    </div>
                    :
                    <Fragment>
                    <div className="row">
                        <div className="col-sm-6 col-md-4 col-lg-3">
                            Camp Image #1:
                            {this.state.images[0] ?
                            <Fragment><br/><img style={{width:200,height:150,borderRadius:10,marginBottom:15}} src={this.state.images[0].URL}/><br/></Fragment>
                            : <Fragment><span> No Image</span><br/></Fragment>}
                            <br/>
                            {!this.state.imageSaving ? 
                                <Fragment>
                                <label htmlFor="campImage1">Choose New Image:</label><br/>
                                <input name="campImage1" label='upload file' type='file' onChange={this.handleFileUpload} />
                                </Fragment>
                            :""}
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-9">
                            {this.state.images[0] ?
                            <Button
                                disabled={this.state.imageSaving||this.state.thumbnailSaving}
                                text="Update Image"
                                type="submit"
                                onClick={() => this.onUpdateImage(this.state.images[0].ID, "campImage1")}
                                buttonStyle="btn--default"
                                icon="none"
                            ></Button>
                            :<Button
                                disabled={this.state.imageSaving||this.state.thumbnailSaving}
                                text="Upload Image"
                                type="submit"
                                onClick={() => this.onUploadImage("campImage1")}
                                buttonStyle="btn--default"
                                icon="none"
                            ></Button>
                            }
                            <br/><br/>
                            {this.state.images[0] ? 
                                <input disabled={this.state.imageSaving||this.state.thumbnailSaving} style={{width:180,borderRadius:10}} className="btn btn-danger" type="button" value="Delete Image" onClick={() => this.onDeleteImage(this.state.images[0].ID)}></input>
                            :""}
                        </div>
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-sm-6 col-md-4 col-lg-3">
                            Camp Image #2:
                            {this.state.images[1] ?
                            <Fragment><br/><img style={{width:200,height:150,borderRadius:10,marginBottom:15}} src={this.state.images[1].URL}/><br/></Fragment>
                            : <Fragment><span> No Image</span><br/></Fragment>}
                            <br/>
                            {!this.state.imageSaving ? 
                                <Fragment>
                                <label htmlFor="campImage2">Choose New Image:</label><br/>
                                <input name="campImage2" label='upload file' type='file' onChange={this.handleFileUpload} />
                                </Fragment>
                            :""}
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-9">
                            {this.state.images[1] ?
                            <Button
                            disabled={this.state.imageSaving||this.state.thumbnailSaving}
                                text="Update Image"
                                type="submit"
                                onClick={() => this.onUpdateImage(this.state.images[1].ID, "campImage2")}
                                buttonStyle="btn--default"
                                icon="none"
                            ></Button>
                            :<Button
                            disabled={this.state.imageSaving||this.state.thumbnailSaving}
                                text="Upload Image"
                                type="submit"
                                onClick={() => this.onUploadImage("campImage2")}
                                buttonStyle="btn--default"
                                icon="none"
                            ></Button>
                            }
                            <br/><br/>
                            {this.state.images[1] ? 
                                <input disabled={this.state.imageSaving||this.state.thumbnailSaving} style={{width:180,borderRadius:10}} className="btn btn-danger" type="button" value="Delete Image" onClick={() => this.onDeleteImage(this.state.images[1].ID)}></input>
                            :""}
                        </div>
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-sm-6 col-md-4 col-lg-3">
                            Camp Image #3:
                            {this.state.images[2] ?
                            <Fragment><br/><img style={{width:200,height:150,borderRadius:10,marginBottom:15}} src={this.state.images[2].URL}/><br/></Fragment>
                            : <Fragment><span> No Image</span><br/></Fragment>}
                            <br/>
                            {!this.state.imageSaving ? 
                                <Fragment>
                                <label htmlFor="campImage3">Choose New Image:</label><br/>
                                <input name="campImage3" label='upload file' type='file' onChange={this.handleFileUpload} />
                                </Fragment>
                            :""}
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-9">
                            {this.state.images[2] ?
                            <Button
                                disabled={this.state.imageSaving||this.state.thumbnailSaving}
                                text="Update Image"
                                type="submit"
                                onClick={() => this.onUpdateImage(this.state.images[2].ID, "campImage3")}
                                buttonStyle="btn--default"
                                icon="none"
                            ></Button>
                            :<Button
                                disabled={this.state.imageSaving||this.state.thumbnailSaving}
                                text="Upload Image"
                                type="submit"
                                onClick={() => this.onUploadImage("campImage3")}
                                buttonStyle="btn--default"
                                icon="none"
                            ></Button>
                            }
                            <br/><br/>
                            {this.state.images[2] ? 
                                <input disabled={this.state.imageSaving||this.state.thumbnailSaving} style={{width:180,borderRadius:10}} className="btn btn-danger" type="button" value="Delete Image" onClick={() => this.onDeleteImage(this.state.images[2].ID)}></input>
                            :""}
                        </div>
                    </div></Fragment>}
                </div>
            </Fragment>
        );
    }
}

export default EditOrg;
import React, { Fragment } from 'react';
import styles from './Landing.module.css';
import { Link } from 'react-router-dom';
import Button from './Button';

const Contact = () => {
  return (
    <Fragment>
      <header className={styles.hero} style={{minHeight:400}}></header>

      <div className={styles['form-container']}>
        <h1 className={styles['header-text']}>CONTACT US</h1>
        <br/><br/>
        <p style={{textAlign:'center'}}>
                      
            <a href="mailto:support@summertymes.com?subject=Summertymes Support">Contact Support</a><br/><br/>          
            <a href="mailto:info@summertymes.com?subject=Summertymes Info">Request Info</a><br/><br/>
            <a href="mailto:privacy@summertymes.com?subject=Privacy Policy">Inquire about Privacy Policy</a><br/><br/>
        </p>
      </div>
    </Fragment>
  );
};

export default Contact;

import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';

const API_URL =
process.env.NODE_ENV === 'production'
  ? 'https://summertymes-api.herokuapp.com'
  : 'http://localhost:3000';

const config = {
    headers: {
        'Content-Type': 'application/json'
    }
};

class CamperService{

    async addCamperKid(firstName, lastName, gender, birthday, medical, shirtSize){
        const response = await axios.post(`${API_URL}/camper/campers`, {
            firstName,
            lastName,
            gender,
            shirtSize,
            medical,
            birthday
          }, config);
          return response;
    }

    async getCampers(){
        if (localStorage.token) {
            setAuthToken(localStorage.token);
        }

        const response = await axios.get(`${API_URL}/camper/campers`);
        return response;
    }

    async purchaseCamp(price, token){
        const response = await axios.post('http://localhost:7000/api/stripe/charge', {
          amount: price.toString().replace('.', ''),
          source: token.id,
          receipt_email: 'customer@example.com'
        }, config);
        return response;
    }
}

export default new CamperService();
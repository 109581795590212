import React, { Fragment } from 'react';
import styles from './Landing.module.css';
import { Link } from 'react-router-dom';
import Button from './Button';

const About = () => {
  return (
    <Fragment>
      <header className={styles.hero} style={{minHeight:400}}></header>

      <div style={{textAlign:"center"}} className={styles['form-container']}>
        <h1 className={styles['header-text']}>Summertymes</h1>
          
        <p>
          Summertymes is a website where you explore, register, and sync up with your friends for activities such as summer camps, events, outdoor adventures and more.
          It provides easy tools for planning, booking and coordinating with other parents, friends or parties.
        </p>
        <h1 className={styles['header-text']}>ABOUT US // MISSION STATEMENT</h1>
        <p>
          Make it easy for people to find and share fun and adventure. 
        </p>
        <h1 className={styles['header-text']}>
          WHAT MAKES SUMMERTYMES THE BEST/EASIEST TO USE CAMP HOSTING SITE?
        </h1>
        <p>
         As parents, we were amazed by the complexity of planning our first summer without school. We received numerous emails and texts sometimes with excel documents and more details than we could manage. When we finally figured out the ideal plan, we were shocked to have missed our opportunity with booked camps and ended up with what was left. 
         Either camps struggle to fill spots or parents struggle to know when registration opens and we want to bring the whole process together. 
         We are always juggling so much, so we wanted to create a tool that organizes the details and allows you to be a star for your kids.
         Plus, life is more fun with the people you love and Summertymes makes it easy to coordinate with friends and family, so your kids can be with their friends while they make special memories.
        </p>
        <h1 className={styles['header-text']}>Our Story</h1>
        <p>
          We had planned to launch Summertymes on March 13, 2020, but the world shut down instead. We held onto the idea until we felt it was time. We are so grateful to see the world ready for life again. Now more than ever we appreciate the gift of gathering. We hope to make life easier for everyone. Originally, when we launched Summertymes, we were living in Southern California where summer is year round. Today, we live up in the Pacific Northwest where we enjoy nature and the outdoors year round.
          The idea for Summertymes originated as an idea to make summers special, but now we realize that adventure is a year round thing and we want the whole year to feel like summertime.
        </p>
        <div>
          <Link to="/search" className={styles['link-text']}>
            <h2>START SEARCHING FOR CAMPS HERE</h2>
          </Link>
        </div>
        <div className="row">
          <div className="mx-auto text-center p-3">
            <Link to="/host/login">
              <Button
                text="Log In"
                type="submit"
                onClick={() => {}}
                buttonStyle="btn--large"
                icon="none"
              ></Button>
            </Link>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default About;

import React, { Fragment } from 'react';
import styles from './Landing.module.css';

const Pricing = () => {
  return (
    <Fragment>
      <header className={styles.hero} style={{minHeight:400}}></header>

      <div className={styles['form-container']}>
        <h1 className={styles['header-text']}>Pricing</h1>
      </div>
    </Fragment>
  );
};

export default Pricing;
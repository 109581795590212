import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';

const API_URL =
process.env.NODE_ENV === 'production'
  ? 'https://summertymes-api.herokuapp.com'
  : 'http://localhost:3000';

const config = {
    headers: {
        'Content-Type': 'application/json'
    }
};

class HostService{
    async getOrgs() {
        if (localStorage.token) {
            setAuthToken(localStorage.token);
        }

        const response = await axios.get(`${API_URL}/host/orgs`);
        return response;
    }

    async getHost(id){
        const response = await axios.get(`${API_URL}/host/${id}`);
    }

    async enableSubscription(sessionId){
        if(localStorage.token){
            setAuthToken(localStorage.token);
        }

        const payload = {
            isSubscribed:true, 
            sessionId:sessionId
        }

        const response = await axios.put(`${API_URL}/api/host/subscription`, payload, config);
        return response;
    }

    async updateHost(user){
        const payload = {
            firstName: user.firstName, 
            lastName: user.lastName,
            phone: user.phone, 
            email: user.email
        }
        const response = await axios.put(`${API_URL}/host`, payload, config);
        return response;
    }

    async getOrg(orgId){
        const response = await axios.get(`${API_URL}/host/orgs/${orgId}`);
        return response;
    }

    async addOrg(formData) {
        const response = await axios.post(`${API_URL}/host/orgs`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
              }
        });
        return response;
    }

    async updateOrg(org){
        const response = await axios.put(`${API_URL}/host/orgs/${org.id}`, org, config);
        return response
    }

    async reviewUpdateOrg(org){
        const response = await axios.put(`${API_URL}/host/orgs/${org.id}/reviewupdate`, org, config);
        return response
    }

    async updateOrgImage(formData, id){
        const response = await axios.put(`${API_URL}/host/org/${id}/image`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
              }
        });
        return response;
    }

    async updateCampThumbnail(formData, id){
        const response = await axios.put(`${API_URL}/host/camp/${id}/thumbnail`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
              }
        });
        return response;
    }

    async deleteOrg(id){
        const response = await axios.delete(`${API_URL}/host/org/${id}`);
        return response;
    }

    async getCamps(orgId){
        const response = await axios.get(`${API_URL}/host/org/${orgId}/camps`);
        return response;
    }

    async addCamp(formData, orgId){
        const response = await axios.post(`${API_URL}/host/org/${orgId}/camps`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
              }
        });
        return response;
    }

    async updateCamp(camp){
        const response = await axios.put(`${API_URL}/host/camp/${camp.campId}`, camp, config);
        return response;
    }

    async deleteCamp(id){
        const response = await axios.delete(`${API_URL}/host/camp/${id}`);
        return response;
    }

    async deleteCampThumbnail(id){
        const response = await axios.delete(`${API_URL}/host/camp/${id}/thumbnail`);
        return response;
    }

    async deleteCampImage(id){
        const response = await axios.delete(`${API_URL}/host/camp/image/${id}`);
        return response;
    }

    async uploadCampImage(formData, id){
        const response = await axios.post(`${API_URL}/host/camp/${id}/images`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response;
    }

    async updateCampImage(formData, id){
        const response = await axios.put(`${API_URL}/host/camp/images/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response;
    }

    async checkForDuplicateCamp(name, phone){
        const payload = {name,phone};
        const response = await axios.post(`${API_URL}/api/camps/duplicate`, payload);
        return response;
    }

    async addCampImage(image,campId,orgId){
        const payload = {image,campId};
        const response = await axios.post(`${API_URL}/host/org/${orgId}/camps/${campId}/images`,payload);
        return response;
    }

    async addCampCategory(categoryId, campId, orgId){
        const payload={categoryId,campId};
        const response = await axios.post(`${API_URL}/host/org/${orgId}/camps/${campId}/categories`,payload);
        return response;
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('token'));;
    }
}

export default new HostService();
import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';

import styles from './Landing.module.css';

import swimmingkids from './images/swimming.jpg';
import smilingkids from './images/smilingkids.png';
import calendar from './images/calendar.png';
import tent from './images/tent.png';
import coordinate from './images/coordinate.png';

const Landing = () => {
    return (
        <Fragment>
        <div className={styles['landing-container']}>
          <header className={styles.masthead}>
            {/**Top left hand corner */}
            <div className="container h-100 overhead">
              <div className="row h-100 align-items-center">
                <h2>GIVE YOUR KIDS THE BEST TIME OF THEIR LIVES</h2>
                <p className={styles['hero-text']}>
                  Sign up to easily find awesome camps or programs near you!
                </p>
                <div className="text-center onTop">
                  <a href="camper/register" className={styles['hero-button']}>
                    JOIN THE FUN
                  </a>
                </div>
              </div>
            </div>
            {/**buttom right hand corner */}
            <div className="container h-100 lower-right">
              <div className="row h-100 align-items-center">
                <h2>ARE YOU A CAMP?</h2>
                <p className={styles['hero-text']}>
                  Claim your listing and get started to fill your programs.
                </p>
                <div className="text-center">
                  <Link to="/host/login" className={styles['hero-button']}>
                    GET STARTED
                  </Link>
                </div>
              </div>
            </div>
          </header>
  
          <div className={styles['mobile-screen-cover']}>
            <div className={styles['mobile-screen-cover-image']}></div>
  
            <div className="row">
              <div
                className={`container text-center p-4 col-sm-12 col-md-6 ${styles['camper-tablet']}`}
              >
                <h3>Give your kids the best tyme of their lives</h3>
                <p>Sign up to easily find camps or schools near you</p>
                <a href="/camper/register" className={styles['hero-button']}>
                  GET STARTED
                </a>
              </div>
              <div
                className={`container text-center p-4 col-sm-12 col-md-6 ${styles['camp-host-tablet']}`}
              >
                <h3>Are you a camp?</h3>
                <p>
                  Claim your listing and get started using our amazing marketing &
                  registration tools to fill your programs.
                </p>
                <Link to="/host/login" className={styles['hero-button']}>
                  JOIN NOW
                </Link>
              </div>
            </div>
          </div>
  
          <section
            className={`features-icons bg-light text-center ${styles['features-icons']}`}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-4">
                  <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3 mt-1">
                    <div className="features-icons-icon d-flex">
                      <img
                        src={calendar}
                        alt="calendar"
                        className={styles.icon}
                      />
                    </div>
                    <h3 className="text-uppercase">Schedule classes easily</h3>
                    <p className="lead mb-0">
                      Our site offers easy to book activities for your kids
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                    <div className="features-icons-icon d-flex">
                      <img src={tent} alt="tent" className={styles.icon} />
                    </div>
                    <h3 className="text-uppercase">Tons of choices</h3>
                    <p className="lead mb-0">
                      Search for hundreds of programs & schools
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="features-icons-item mx-auto mb-0 mb-lg-3">
                    <div className="features-icons-icon d-flex">
                      <img
                        src={coordinate}
                        alt="coordinate"
                        className={styles.icon}
                      />
                    </div>
                    <h3 className="text-uppercase">Coordinate</h3>
                    <p className="lead mb-0">
                      Share schedules with other parents to ensure your kids are
                      registered alongside their friends
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
  
          <section className={styles.showcase}>
            <div className="container-fluid p-0">
              <div className="row no-gutters">
                <div
                  className={`col-lg-6 order-lg-2 text-white ${styles['showcase-img']}`}
                  style={{ backgroundImage: `url(${smilingkids})` }}
                ></div>
                <div className={`col-lg-6 order-lg-1 ${styles['showcase-text']}`}>
                  <h1 className={styles['box-text']}>Join the community</h1>
                </div>
              </div>
              <div className="row no-gutters">
                <div
                  className={`col-lg-6 text-white ${styles['showcase-img']}`}
                  style={{
                    backgroundImage: `url(${swimmingkids})`
                  }}
                ></div>
                <div className={`col-lg-6 ${styles['showcase-text']}`}>
                  <h1 className={styles['box-text']}>You deserve a break!</h1>
                  <h4 className={styles['box-sub-text']}>
                    We believe mapping out your kid's activities should be simple
                  </h4>
                </div>
              </div>
            </div>
          </section>
  
          <section className={`${styles['call-to-action']} text-white text-center`}>
            <div className={styles.overlay}></div>
            <div className="container">
              <div className="row">
                <div className="col-xl-9 mx-auto">
                  <h2 className="mb-4">Ready to get started? Sign up now!</h2>
                </div>
                <div className="col-md-10 col-lg-8 col-xl-7 mx-auto">
                  <div id="mc_embed_signup">
                    <form action="https://summertymes.us4.list-manage.com/subscribe/post?u=35110e30e6eff9f5692dba2de&amp;id=a7298cbf30" method="post"
                      id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank"
                      noValidate>
                      <div id="mc_embed_signup_scroll">
                        <h2>Join Our Newsletter</h2>
                        {/*<div className="mc-field-group">
                          <input type="email" placeholder="Enter your email address..." defaultValue="" name="EMAIL" className="form-control form-control-lg" id="mce-EMAIL"/>
                        </div>

                        <div className="mc-field-group">
                          <input type="text" placeholder="First Name" name="LNAME" id="mce-LNAME" className="form-control form-control-lg"/>
                        </div>

                        <div className="mc-field-group">
                          <input type="text" placeholder="Last Name" name="FNAME" id="mce-FNAME" className="form-control form-control-lg"/>
                        </div>

                        <div className="mc-field-group">
                          <input type="text" placeholder="Zip Code" name="MMERGE3" id="mce-MMERGE3" className="form-control form-control-lg"/>
                        </div>

                        <div className="mc-field-group input-group">
                          <strong>I am a  <span className="asterisk">*</span></strong>
                          <ul>
                            <li style={{textAlign:"left"}}>
                              <input type="radio" value="Camper" name="MMERGE4" id="mce-MMERGE4-0"/>&nbsp;<label htmlFor="mce-MMERGE4-0">Camper</label>
                            </li>
                            <li>
                              <input type="radio" value="Camp Host" name="MMERGE4" id="mce-MMERGE4-1"/>&nbsp; <label htmlFor="mce-MMERGE4-1">Camp Host</label>
                            </li>
                          </ul>
                          </div>*/}
                        
                        <div id="mce-responses" className="clear">
                          <div className="response" id="mce-error-response" style={{display:"none"}}></div>
                          <div className="response" id="mce-success-response" style={{display:"none"}}></div>
                        </div> 
                        <div style={{position:"absolute",left:-5000}} aria-hidden="true"><input type="text"name="b_35110e30e6eff9f5692dba2de_a7298cbf30" tabIndex="-1" defaultValue=""/></div>
                        <div className="clear">
                          <button id="mc-embedded-subscribe" type="submit" className={styles['signup-button']}>
                            Subscribe
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Fragment>
    );
}

export default Landing;

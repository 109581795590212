import React, {Component, Fragment} from 'react';
import styles from './CampHost.module.css';
import CamperList from './CamperList'
import CamperService from '../../services/camperService';
import {Link} from 'react-router-dom';
import {logout} from '../../utils/tokenUtils';
import Button from '../common/Button';
import { Modal, Row, Col } from 'react-bootstrap';
import DatePicker from "react-datepicker";

class ManageCampers extends Component{
    constructor(props){
        super(props);
        this.onBack = this.onBack.bind(this);
        this.drawModalForm = this.drawModalForm.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleBirthdayChange = this.handleBirthdayChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onChangeInput = this.onChangeInput.bind(this);

        this.state = {
            campers:[],
            showModal:false,
            firstName:"",
            lastName:"",
            birthday:"",
            medical:"",
            shirtSize:"small",
            gender:"male",
            hasModalError:false,
            modalErrorMessaage:""
        };
    }

    onBack(){
        this.props.history.goBack();
    }

    verifyModalForm(){
        let ignore = ["modalErrorMessaage","hasModalError","campers","medical"];
        for (const property in this.state) {
            if(!ignore.includes(property)){
                //console.log(`${property}: ${this.state[property]}`);
                if(this.state[property]==""||this.state[property]==null){
                    console.log("offender");
                    console.log(property);
                    return false;
                }
            }
        }
        return true;
    }

    handleSubmit(e){
        e.preventDefault();

        this.setState({
            hasModalError:false,
            modalErrorMessaage:""
        });

        if(!this.verifyModalForm()){
            this.setState({
                hasModalError:true,
                modalErrorMessaage:"Please fill in all required(*) fields"
            });
        }else{
            CamperService.addCamperKid(this.state.firstName, this.state.lastName, this.state.gender, this.state.birthday, this.state.medical, this.state.shirtSize).then((response)=>{
                this.setState({
                    firstName:"",
                    lastName:"",
                    birthday:"",
                    medical:"",
                    shirtSize:"small",
                    gender:"male",
                    hasModalError:false,
                    modalErrorMessaage:""
                });
                this.toggleModal();
                CamperService.getCampers().then((result)=>{
                    console.log(result.data);
                    this.setState({campers:result.data});
                },error=>{
                    if(error.response.data.message=="Failed to authenticate token."){
                        logout();
                    }
                    console.log(error.response);
                });
                },(error)=>{
                    console.log(error.response);
            });
        }
    }

    onChangeInput(e) {
        this.setState({
          [e.target.name]: e.target.value
        });
        console.log(e.target.value);
    }

    handleBirthdayChange(date){
        const fullDate = new Date(date);
        const year = fullDate.getFullYear();
        const month = (fullDate.getMonth()+1) < 10 ? `0${fullDate.getMonth()+1}` : fullDate.getMonth()+1;
        const day = (fullDate.getDate()+1) < 10 ? `0${fullDate.getDate()+1}` : fullDate.getDate()+1;
        const formattedDate = `${year}-${month}-${day}`;
        this.setState({birthday:new Date(formattedDate)});
    }

    toggleModal(){
        if(this.state.showModal===false){
            this.setState({showModal:true});
        }else{
            this.setState({showModal:false});
        }
        console.log(this.state.showModal);
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value }, ()=>{console.log(this.state)});
    }

    drawModalForm(){
        return(
          <Modal
            show={this.state.showModal}
            onHide={this.toggleModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                New Camper
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <Row>
                  <Col className="col-md-12 col-md-6">
                    <label htmlFor="firstName">First Name*</label>
                    <input
                      type="text"
                      id="firstName"
                      className="input"
                      required
                      name="firstName"
                      value={this.state.firstName}
                      onChange={this.onChangeInput}
                    />
                  </Col>
                  <Col className="col-md-12 col-md-6">
                    <label htmlFor="lastName">Last Name*</label>
                    <input
                      type="text"
                      id="lastName"
                      className="input"
                      required
                      name="lastName"
                      value={this.state.lastName}
                      onChange={this.onChangeInput}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-12 col-lg-4">
                    <label htmlFor="gender">Gender*</label>
                    <select value={this.state.gender} name="gender" id="gender" className="input" style={{marginBottom:20}} onChange={this.handleChange}>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                    </select>
                  </Col>
                  <Col className="col-md-12 col-lg-4">
                    <label htmlFor="shirtSize">T-Shirt Size*</label>
                    <select value={this.state.shirtSize} name="shirtSize" id="shirtSize" className="input" style={{marginBottom:20}} onChange={this.handleChange}>
                        <option value="small">Small</option>
                        <option value="medium">Medium</option>
                        <option value="large">Large</option>
                    </select>
                  </Col>
                  <Col className="col-md-12 col-lg-4">
                    <label htmlFor="birthday">Birthday*</label>
                    <DatePicker
                        selected={this.state.birthday}
                        onChange={this.handleBirthdayChange}
                        name="birthday"
                        className="input"
                        dateFormat="yyyy-MM-dd"
                        style={{marginBottom:20}}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className='col-sm-12 col-md-12'>
                    <label htmlFor="medical">Allergies/Medical Info</label>
                    <textarea
                      id="medical"
                      className="input"
                      required
                      name="medical"
                      value={this.state.medical}
                      onChange={this.onChangeInput}
                    />
                  </Col>
                </Row>
              </div>
              <div>
                    {this.state.hasModalError ?
                    <p className="alert alert-danger">{this.state.modalErrorMessaage}</p>
                    : ""}
                </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                text="Add Camper"
                type="submit"
                onClick={(e) => { this.handleSubmit(e) }}
                buttonStyle="btn--default"
                icon="none"
              ></Button>
            </Modal.Footer>
          </Modal>
        )
    };

    componentDidMount(){
        CamperService.getCampers().then((result)=>{
            this.setState({campers:result.data});
            console.log(result.data);
        },error=>{
            if(error.response.data.message=="Failed to authenticate token."){
                logout();
            }
            console.log(error.response);
        });
        /*this.setState({
            campers:[
                {
                    Name:"John Doe",
                    Id: 1
                },
                {
                    Name:"Jane Doe",
                    Id: 2
                },
                {
                    Name:"Mike Jones",
                    Id:3
                }
            ]
        })*/
    }

    render(){
        return(
            <Fragment>
                {this.drawModalForm()}
                <div className={styles['header']}>
                    <div className="row">
                        <div className='col-sm-5 col-md-5'>
                            {/*<h3 style={{cursor:"pointer"}} className={styles['form-text']} onClick={this.onBack}>
                                <i className="fas fa-chevron-circle-left"></i> Back
                            </h3>*/}
                        </div>
                        <div className='col-sm-2 col-md-2'>
                            <h1 className={styles['form-text']}>MY CAMPERS</h1>
                        </div>
                        <div className='col-sm-4 col-md-4' onClick={this.toggleModal}>
                            <h1 style={{cursor:'pointer'}} className={styles['form-text']}>+ New Camper</h1>
                        </div>                      
                    </div>
                </div>

                <div className={styles['gray-container']}>
                    {this.state.campers && this.state.campers.length !== 0 ? (
                    <div className={styles['mycamps']} style={{paddingBottom:35}}>
                        <CamperList campers={this.state.campers}/>
                    </div>
                    ) : (
                        <h2 className={styles['nocamps']}>No Campers Added Yet</h2>
                    )}
                </div>
            </Fragment>
        )
    }
}

export default ManageCampers;
import React, { Fragment } from 'react';
import styles from './Landing.module.css';
import { Link } from 'react-router-dom';
import Button from './Button';

const Terms = () => {
  return (
    <Fragment>
      <header className={styles.hero} style={{minHeight:400}}></header>

      <div className={styles['form-container']}>
        <h1 className={styles['header-text']}>Terms of Use</h1>
      </div>
    </Fragment>
  );
};

export default Terms;
import React,{Component, Fragment} from 'react';
import styles from './Search.module.css';
import Button from '../common/Button';
import { Container, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import { Multiselect } from 'multiselect-react-dropdown';
import {Link} from 'react-router-dom';

import SearchService from '../../services/searchService';
import DataService from '../../services/dataService';

class SearchResults extends Component{
    constructor(props){
        super(props);

        this.multiselectRef = React.createRef();
        this.onSearchQueryChange = this.onSearchQueryChange.bind(this);
        this.onFiltersChange = this.onFiltersChange.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.searchReset = this.searchReset.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state={
            categories:[],
            campCategories:[],
            selectedValue:[],
            query:this.props.location.state.query,
            searchResults:[],
            monthNames:["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"]
        };
    }

    onSearchQueryChange(e){
        const val = e.target.value;
        //this.setState({[query.term]:e.target.value});
        this.setState(prevState => ({
            query: {                   // object that we want to update
                ...prevState.query,    // keep all other key-value pairs
                term: val       // update the value of specific key
            }
        }));
    }

    searchReset(e){
        e.preventDefault();

        this.setState({
            selectedValue:[],
            campCategories:[],
            query:{
                type:"camp",
                term:"",
                filters:{
                    zip:"",
                    age:"",
                    month:"",
                    cost:""
                },
                categories:[]
            }
        },()=>{console.log(this.state)});

        this.multiselectRef.current.resetSelectedValues();
    }

    onSubmit(){
        SearchService.search(this.state.query).then((results)=>{
            const searchResults = results.data;
            console.log(searchResults);
            this.setState({searchResults:searchResults});
        });
    }

    onFiltersChange(e){
        //this.setState({[query.filters[e.target.name]]:e.target.value});
        //console.log(this.state);
        const name = e.target.name;
        const val = e.target.value;
        this.setState(prevState => ({
            query: {
              ...prevState.query,           // copy all other key-value pairs of food object
              filters: {                     // specific object of food object
                ...prevState.query.filters,   // copy all pizza key-value pairs
                [name]: val          // update value of specific key
              }
            }
        }));
    }

    onSelect(){
        const sels = this.multiselectRef.current.getSelectedItems();
        this.setState({campCategories:[]},()=>{
            const tempcat = this.state.campCategories;
            for (let i = 0; i < sels.length; i++) {
                tempcat.push(sels[i].ID);
            }
            this.setState({campCategories:tempcat});
            this.setState(prevState => ({
                query: {                   // object that we want to update
                    ...prevState.query,    // keep all other key-value pairs
                    categories: tempcat       // update the value of specific key
                }
            }),()=>{console.log(this.state)});
        });
    }

    renderResults(){
        console.log(this.state.searchResults);
        return this.state.searchResults.map(res=>(
            <div className="row d-flex justify-content-center" key={res.ID} style={{marginBottom:20}}>
                <div className="col-sm-3 col-md-2 col-lg-2" style={{marginRight:10}}>
                    <img width="200" height="150" src={res.Thumbnail} style={{marginBottom:10}}/>
                    <Link to={`/camps/${res.ID}`}>
                        <Button
                            text="LEARN MORE"
                            type="button"
                            buttonStyle="btn--default"
                            widthValue="200px"
                        ></Button>
                    </Link>
                </div>
                <div className="col-sm-8 col-md-6 col-lg-6">
                    <div className={styles['camp-profile-primary-info']}>
                        <div className={styles.campname}>
                            <span className={styles['camp-profile-header-text']}>
                            CAMP NAME:
                            </span>
                        </div>
                        <div className={styles.campnameinfo}>
                            {res.Name}
                        </div>
                        <div className={styles.camphost}>
                            <span className={styles['camp-profile-header-text']}>
                            LOCATION:
                            </span>
                        </div>
                        <div className={styles.camphostinfo}>
                            <span>{res.City}</span>,<span>{res.State}</span>
                        </div>
                        <div className={styles.address}>
                            <span className={styles['camp-profile-header-text']}>
                            DATES:
                            </span>
                        </div>
                        <div className={styles.addressinfo}>
                        {res.StartDate == null ? 
                            <span>Year Round</span>
                            : <span>Camp runs {this.state.monthNames[new Date(res.StartDate).getMonth()]} {new Date(res.StartDate).getDate()}  - {this.state.monthNames[new Date(res.EndDate).getMonth()]} {new Date(res.EndDate).getDate()} </span>}                                    
                        </div>
                        <div className={styles.dates}>
                            <span className={styles['camp-profile-header-text']}>
                            Summary:
                            </span>
                        </div>
                        <div className={styles.datesinfo}>
                            Summary Text Summary Text Summary Text Summ
                        </div>
                    </div>
                </div>
                {/*<Link to={`/camps/${res.ID}`}>
                <CampHostCampCard
                    key={res.ID}
                    image={res.Thumbnail}
                    alt="thumbnail"
                    text={res.Name}
                />
                </Link>*/}
            </div>  
        ));
    }

    componentDidMount(){
        DataService.getCategories().then((res)=>{
            this.setState({categories:res.data},()=>{
                let categories = this.state.categories.filter((cat)=>{
                    if(this.state.query.categories.includes(cat.ID)){
                        return true;
                    }
                });
                this.setState({selectedValue:categories});
                SearchService.search(this.state.query).then((results)=>{
                    const searchResults = results.data;
                    console.log(searchResults);
                    this.setState({searchResults:searchResults});
                });
            });
        });
    }

    render(){
        return(
            <Fragment>
                <div className={styles['header-text']}>
                    <h1>Search Results</h1>
                </div>
                <div className="row mt-3 justify-content-center" style={{marginTop:10}}>
                    <Container>
                        <div className="row mt-3 justify-content-center">
                            <div className="col-sm-9 col-md-7 col-lg-7">
                                <input
                                type="search"
                                placeholder="Keyword"
                                className="input"
                                value={this.state.query.term}
                                onChange={this.onSearchQueryChange}
                                name="searchQuery"
                                />
                            </div>
                            <div className="col-sm-3 col-md-2 col-lg-2">
                                <input
                                type="text"
                                className="input"
                                value={this.state.query.filters.zip}
                                onChange={this.onFiltersChange}
                                style={{marginBottom:10}}
                                name="zip"
                                placeholder="Zip Code"
                                />
                            </div>
                        </div>
                    </Container>
                </div>
                <div className="row mt-3 justify-content-center">
                    <p style={{textAlign:"center",fontSize:"1.5em"}}>
                        Filters
                    </p>
                </div>
                <div className="row mt-3 justify-content-center">
                    <div className="col-sm-4 col-md-4 col-lg-3">
                        <div style={{marginBottom:10}}>
                        <Multiselect
                            options={this.state.categories} // Options to display in the dropdown
                            placeholder="Select Categories"
                            ref={this.multiselectRef}
                            selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                            onSelect={this.onSelect} // Function will trigger on select event
                            onRemove={this.onSelect} // Function will trigger on remove event
                            displayValue="Text" // Property name to display in the dropdown options
                        />
                        </div>
                    </div>
                    <div className="col-sm-4 col-md-4 col-lg-3">
                        <input
                            type="number"
                            className="input"
                            value={this.state.query.filters.age}
                            onChange={this.onFiltersChange}
                            name="age"
                            placeholder="Age"
                            style={{marginBottom:10}}
                        />
                        <input
                            type="number"
                            className="input"
                            value={this.state.query.filters.cost}
                            onChange={this.onFiltersChange}
                            name="cost"
                            placeholder="Cost"
                            style={{marginBottom:10}}
                        />
                        <select name="month" onChange={this.onFiltersChange} value={this.state.query.filters.month} className="input">
                            <option va="0">Month</option>
                            <option value="1">January</option>
                            <option value="2">February</option>
                            <option value="3">March</option>
                            <option value="4">April</option>
                            <option value="5">May</option>
                            <option value="6">June</option>
                            <option value="7">July</option>
                            <option value="8">August</option>
                            <option value="9">September</option>
                            <option value="10">October</option>
                            <option value="11">Novembner</option>
                            <option value="12">December</option>
                        </select>
                    </div>
                </div>

                <div className="row mt-3 d-flex justify-content-center">
                    <div className="mr-2">
                        <Button
                        text="Search"
                        type="submit"
                        onClick={this.onSubmit}
                        buttonStyle="btn--default"
                        icon="search"
                        ></Button>
                    </div>
                    <div className="ml-2">
                        <Button
                        text="Reset"
                        type="button"
                        onClick={this.searchReset}
                        buttonStyle="btn--default"
                        icon="search"
                        ></Button>
                    </div>
                </div>

                <div className={styles['gray-container']}>
                    <Container>
                        <br />
                            {this.state.searchResults.length==0 ? (
                                <h2 style={{ textAlign: 'center' }}>No Results Found</h2>
                            ):this.renderResults()}
                        <br />
                    </Container>
                </div>
            </Fragment>
        )
    }
}

export default SearchResults;
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';

import logo from './images/SummerTymes-logo.png';
import facebook from './images/facebook.png';
import instagram from './images/instagram.png';

const Footer = () => {
  return (
    <footer className={styles['footer-container']}>
      <div className={styles['grid-item-one']}></div>
      <div className={styles['grid-item-two']}>
        <a
          href="https://www.facebook.com/funsummertymes"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={facebook}
            alt="facebooklogo"
            className={styles['social-icon']}
          />
        </a>
        <a
          href="https://www.instagram.com/funsummertymes/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={instagram}
            alt="instagramlogo"
            className={styles['social-icon']}
          />
        </a>
      </div>
      <div className={styles['grid-item-three']}></div>
      <div className={styles['grid-item-four']}>
        <ul className={styles['menu']}>
          <li>
            <Link to="/about" className={styles['navigation-link']}>
              About
            </Link>
          </li>
          <li>
            <Link to="/contact" className={styles['navigation-link']}>
              Contact
            </Link>
          </li>
          <li>
            <a className={styles['navigation-link']} href="#">
            <Link to="/terms" className={styles['navigation-link']}>
              Terms of Use
            </Link>
            </a>
          </li>
          <li>
            <a className={styles['navigation-link']} href="#">
            <Link to="/privacypolicy" className={styles['navigation-link']}>
              Privacy Policy
            </Link>
            </a>
          </li>
        </ul>
      </div>
      <div className={styles['grid-item-five']}>
        <Link to="/">
          <img src={logo} alt="logo" className={styles.logo} />
        </Link>
      </div>
      <div className={styles['grid-item-six']}>
        <ul className={styles['menu']}>
          <li className={styles['navigation-link']}>
            &copy; SummerTymes 2022. All Rights Reserved.
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;

import React from 'react';
import { Link } from 'react-router-dom';
import CamperListCard from './CamperCard';
import swimming from '../search/images/swimming.jpg';

const CamperList = (props) =>{
    return props.campers.map(camper => {
        return (
          <Link to={`/camper/child/${camper.ID}`} key={camper.ID}>
            <CamperListCard
              text={camper.FirstName}
              key={camper.ID}
            />
          </Link>
        );
      });
}

export default CamperList;
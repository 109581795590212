import React, { Component, Fragment } from 'react';
import styles from './Landing.module.css';
import { Link } from 'react-router-dom';
import Button from './Button';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import parentVideo from '../../assets/Summertymes_Camper.mp4';
import hostVideo from '../../assets/Summertymes_CampHost.mp4';
import { Modal, Row, Col } from 'react-bootstrap';

class Works extends Component{
  constructor(props){
    super(props);
    this.drawHostModal = this.drawHostModal.bind(this);
    this.toggleHostModal = this.toggleHostModal.bind(this);
    this.drawParentModal = this.drawParentModal.bind(this);
    this.toggleParentModal = this.toggleParentModal.bind(this);

    this.state = {
      showParent:false,
      showHost:false
    }
  }

  toggleHostModal(){
    if(this.state.showHost===false){
        this.setState({showHost:true});
    }else{
        this.setState({showHost:false});
    }
    console.log(this.state.showHost);
  }

  toggleParentModal(){
    if(this.state.showParent===false){
        this.setState({showParent:true});
    }else{
        this.setState({showParent:false});
    }
    console.log(this.state.showParent);
  }

  drawHostModal(){
    return(
      <Modal
        show={this.state.showHost}
        onHide={this.toggleHostModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Host Experience
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <Row>
              <Col className="col-md-12 col-md-6">
                <video autoPlay src={hostVideo} width="750" height="500" controls></video>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer> 

        </Modal.Footer>
      </Modal>
    )
  };

  drawParentModal(){
    return(
      <Modal
        show={this.state.showParent}
        onHide={this.toggleParentModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Parent Experience
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <Row>
              <Col className="col-md-12 col-md-6">
                <video autoPlay src={parentVideo} width="750" height="500" controls></video>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer> 

        </Modal.Footer>
      </Modal>
    )
  };

  render(){
    return(
      <Fragment>
        {this.drawHostModal()}
        {this.drawParentModal()}
        <header className={styles.hero} style={{minHeight:400}}></header>
        <div className={styles['form-container']}>
          <h1 className={styles['header-text']}>How it Works</h1>
          <p style={{textDecoration:"underline", fontSize:"18px", textAlign:"center", padding:"7px"}}>
            Are you a:
          </p>
          <div className="row mt-12 d-flex justify-content-center">
                <div className="mr-2">
                    <Button
                    text="Camp"
                    type="submit"
                    onClick={this.toggleHostModal}
                    buttonStyle="btn--default"
                    ></Button>
                </div>
                <div className="ml-2">
                    <Button
                    text="Camper"
                    type="button"
                    onClick={this.toggleParentModal}
                    buttonStyle="btn--default"
                    ></Button>
                </div>
            </div>
        </div>
      </Fragment>
    )
  }
}

export default Works;
